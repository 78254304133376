import React, { useEffect, useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {UserContext} from '../context/context';
import {
  obtenerTerapias,
  bajarTerapia
} from '../../utils/serverRequest';
import TerapiaCard from '../terapiaCard/TerapiaCard';
import styles from './dashboardTerapia.module.css';
import DashboardTitle from '../dashboardTitle/dashboardTitle';
import Spinner from '../Globales/spinner/spinner';
import Alert from '../Globales/alertPropia/alertPropia';
import EmptyState from '../Globales/emptyState/emptyState';
import FormButton from '../Globales/formButton/FormButton';
import Buscador from '../Globales/buscador/buscador';
import ModalPropio from '../Globales/modal/modalPropio';

const DashboardTerapia = props => {
  const [terapiaManipulada, setTerapiaManipulada] = useState({
    titulo: '',
    descripcion: ''
  });
  const [terapiaBorrar, setTerapiaBorrar] = useState({});
  const [terapiasIniciales, setTerapiasIniciales] = useState([]);
  const [valorBusqueda, setValorBusqueda] = useState('');
  const [isOpenAlertEliminar, setAlertEliminar] = useState(false);
  const [isOpenAlertSuccess, setAlertSucces] = useState(false);
  const [isOpenAlertCreacion, setAlertCreacion] = useState(false);
  const [isOpenAlertFail, setAlertFail] = useState(false);
  const [isLoading, setSpinner] = useState(true);
  const [isSubmittingDelete, setSubmitDelete] = useState(false);
  const [isOpenModal, setModal] = useState(false);
  const [idTerapiaSeleccionada, setIdTerapiaSeleccionada] = useState('');

  let history = useHistory();

  // Context
  const [user, setUser] = useContext(UserContext);
  const rolUsuario = user.rol;

  useEffect(() => {
    let buscarTerapias = async () => {
      let data = await obtenerTerapias();
      if (data.ok) {
        setTerapiasIniciales(data.terapias);
        setSpinner(false);
      } else {
        setSpinner(false);
        alert('Hubo un error interno del servidor');
        console.log(data);
      }
    };
    buscarTerapias();
    if (props.location.state) {
      let locationState = props.location.state;
      switch (locationState.tipo) {
        case 'creacion':
          setTerapiaManipulada({
            titulo: locationState.titulo,
            descripcion: locationState.descripcion
          });
          setAlertCreacion(true);
          setTimeout(() => {
            setAlertCreacion(false);
          }, 2000);
          history.replace({ state: null });
          break;
        case 'modificacion':
          setTerapiaManipulada({
            titulo: locationState.titulo,
            descripcion: locationState.descripcion
          });
          setAlertSucces(true);
          setTimeout(() => {
            setAlertSucces(false);
          }, 2000);
          history.replace({ state: null });
          break;
        default:
          console.log('dentro de case default del switch');
      }
    } else {
      setAlertSucces(false);
    }
  }, []);

  const handleModal = terapiaId => {
    let terapiaABorrar = terapiasIniciales.filter(terapias => {
      return terapias._id === terapiaId;
    });
    setTerapiaBorrar(terapiaABorrar[0]);
    setModal(true);
    setIdTerapiaSeleccionada(terapiaId);
  };

  const deleteTerapia = async terapiaId => {
    setSubmitDelete(true);
    let data = await bajarTerapia(terapiaId);
    setModal(false);
    if (data.ok) {
      setSubmitDelete(false);
      setTerapiasIniciales(
        terapiasIniciales.filter(terapias => {
          return terapias._id !== terapiaId;
        })
      );
      setAlertEliminar(true);
      setTimeout(() => {
        setAlertEliminar(false);
      }, 2000);
    } else {
      setSubmitDelete(false);
      setModal(false);
      setAlertFail(true);
      setTimeout(() => {
        setAlertFail(false);
      }, 2000);
    }
  };

  const terapiasFiltradas = terapiasIniciales.filter(
    terapia =>
      terapia.titulo.toLowerCase().indexOf(valorBusqueda.toLowerCase()) !== -1
  );

  const actualizarValorBusqueda = e => {
    setValorBusqueda(e.target.value);
  };

  return (
    <div className='dashboardContainer'>
      <DashboardTitle title='Terapias' />
      {isOpenAlertCreacion && (
        <Alert variant='success' show={isOpenAlertCreacion}>
          <strong>Creación exitosa.</strong> Se ha creado la terapia{' '}
          {terapiaManipulada.titulo} de forma correcta
        </Alert>
      )}

      {isOpenAlertSuccess && (
        <Alert variant='success' show={isOpenAlertSuccess}>
          <strong>Modificación exitosa.</strong> Se ha modificado la terapia{' '}
          {terapiaManipulada.titulo} de forma correcta
        </Alert>
      )}

      {isOpenAlertEliminar && (
        <Alert variant='success' show={isOpenAlertEliminar}>
          <strong>Eliminación exitosa.</strong> Se ha eliminado{' '}
          {terapiasFiltradas.titulo} de forma correcta
        </Alert>
      )}

      {isOpenAlertFail && (
        <Alert variant='danger' show={isOpenAlertFail}>
          <strong>Eliminación fallida.</strong> No se ha podido eliminar la
          terapia {terapiasFiltradas.titulo}
        </Alert>
      )}

      {terapiasIniciales.length > 0 && (
        <div className={styles.searchContainer + ' row'}>
          <div className={'col-md-7 col-sm-12 ' + styles.searchBoxCon}>
            <Buscador
              placeholder='Buscar terapias por nombre...'
              valor={valorBusqueda}
              handler={actualizarValorBusqueda}
            />
          </div>
          {rolUsuario !== 'Terapeuta' && (
            <div className={'col-lg-3 col-md-4 col-sm-12 ' + styles.btnCon}>
              <Link to='/panel/terapias/creacion'>
                <FormButton
                  text='CREAR TERAPIA'
                  buttonType='button'
                  buttonStyle='primario'
                  buttonSize='medium'
                  atrName='agregar'
                />
              </Link>
            </div>
          )}
        </div>
      )}

      <div className='row'>
        {terapiasFiltradas.map((terapia, key) => (
          <div
            className={'col-lg-4 col-md-6 col-sm-6 col-xs-12 ' + styles.cards}
            key={key}
          >
            <TerapiaCard
              id={terapia._id}
              titulo={terapia.titulo}
              descripcion={terapia.descripcion}
              terapeutas={terapia.terapeutas}
              colorHex={terapia.colorHex}
              clicked={handleModal}
            />
          </div>
        ))}
      </div>

      {isLoading ? (
        <Spinner />
      ) : terapiasIniciales.length === 0 ? (
        <>
          {rolUsuario !== 'Terapeuta' ? (
            <EmptyState
              ruta='/images/undraw.svg'
              titulo='No hay terapias creadas'
              leyenda='Si querés crear una nueva terapia, hacé click en el siguiente botón.'
              leyenda2='Recuerda que al crear una terapia, se generará un foro de debate con los terapeutas asociados.'
            >
              <div
                className={
                  'col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 ' +
                  styles.buttonEmptyState
                }
              >
                <Link to='/panel/terapias/creacion'>
                  <FormButton
                    text='CREAR TERAPIA'
                    buttonType='button'
                    buttonStyle='primario'
                    buttonSize='medium'
                    atrName='agregar'
                  />
                </Link>
              </div>
            </EmptyState>
          ) : (
            <EmptyState
              ruta='/images/undraw.svg'
              titulo='Aún no tienes asignada una terapia'
            />
          )}
        </>
      ) : terapiasFiltradas.length === 0 ? (
        <div style={{ marginTop: '98px' }}>
          <EmptyState
            ruta='/images/vacio.svg'
            subtitulo={`No se encontraron terapias con "${valorBusqueda}"`}
          />
        </div>
      ) : (
        <> </>
      )}
      {isOpenModal && (
        <ModalPropio
          title='Eliminar Terapia'
          content={`Está seguro que quiere eliminar la terapia ${terapiaBorrar.titulo}? Se podrían perder datos guardados`}
          isOpen={isOpenModal}
          closeModal={setModal}
          okHandler={() => deleteTerapia(idTerapiaSeleccionada)}
          isSubmitting={isSubmittingDelete}
          danger={true}
          botonPrimario='Eliminar'
          botonSecundario='Cancelar'
        />
      )}
    </div>
  );
};

export default DashboardTerapia;
