import React from 'react';
import styles from './feedbackForm.module.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Form from 'react-bootstrap/Form'

const FeedbackForm = (props) => {
    const renderTooltip = (text) =>  {
        return(
        <Tooltip  classname = {styles.tooltip} id="button-tooltip">
            {text}
        </Tooltip>
      )};
    return(
        <div style={styles.feedbackForm }>
            <div className={styles.feedbackBox}>
                <img alt="" className={styles.novolabs} src="\images\logonovolabs.png" /> 
            </div>
            <div className = {styles.buttonsContainer} style = {{marginTop:'16px'}}>
            
                <div className={styles.feedbackOptions}> 
                    <OverlayTrigger placement="bottom" delay={{show:50, hide:100}} overlay = {renderTooltip ('Algo para mejorar')}>
                        <button onClick={() => props.handleClick('Upgrade')} style={{border: props.tipo === 'Upgrade' ? '1px solid #ff6b6b' : ''}} ><img alt="" src ="\images\feedback-1.png"/></button>
                    </OverlayTrigger>  
                </div>

                <div className = {styles.feedbackOptions}>
                    <OverlayTrigger placement="bottom" delay={{show:250, hide:400}} overlay = {renderTooltip ('Una nueva idea')}>
                        <button onClick = {() => props.handleClick('Idea')} style={{border: props.tipo === 'Idea' ? '1px solid #ff6b6b' : ''}}> <img alt="" src ="\images\feedback-2.png"/></button> 
                    </OverlayTrigger>   
                </div>

                <div className = {styles.feedbackOptions}>
                    <OverlayTrigger placement="bottom" delay={{show:250, hide:400}} overlay = {renderTooltip ('Reportar un error')}>                            
                        <button onClick = {() => props.handleClick('Error')} style={{border: props.tipo === 'Error' ? '1px solid #ff6b6b' : ''}}> <img alt="" src ="\images\feedback-4.png"/></button>
                    </OverlayTrigger>
                </div> 
                
            </div>  
            
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Control
                as="textarea"
                rows="5"
                placeholder="Llená el campo con lo que vos quieras :)"
                name="contenido" 
                value={props.contenido}  
                onChange={props.handleChange}
                />
            </Form.Group>        
    </div>
    )
}

export default FeedbackForm;