import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { UserContext } from "../../context/context";
import GroupedPosts from "../groupedPosts/GroupedPosts";
import { borrarPost, crearPostTerapia, crearPostPaciente, obtenerPostsTerapia, obtenerPostsPaciente, uploadPostFiles } from "../../../utils/serverRequest";
import { Alert } from "react-bootstrap";
import Spinner from "../spinner/spinner";
import ModalPropio from "../modal/modalPropio";
import { Animated } from "react-animated-css";
import _ from "underscore";
import ModalEditor from "../modalEditor/modalEditor";
import UserEditor from "../userEditor/userEditor";

const PostContainer = (props) => {
  // Context
  const [user, setUser] = useContext(UserContext);
  const rolUsuario = user.rol;
  const emailUsuario = user.email;

  // State
  const [posts, setPosts] = useState([]);
  const [dataPost, setDataPost] = useState({
    creador: user.id,
    contenido: "",
    plainText: "",
    rawContent: "",
    mentions: [],
    terapia: props.idTerapia || "",
    paciente: props.idPaciente || "",
    archivos: [],
  });
  const [isLoading, setSpinner] = useState(true);
  const [isLoadingMorePosts, setSpinnerMorePosts] = useState(false);
  const [isLoadingFilterByTerapia, setSpinnerFilterByTerapia] = useState(false);
  const [isOpenAlertEliminar, setAlertEliminar] = useState(false);
  const [isOpenAlertFail, setAlertFail] = useState(false);
  const [isSubmittingPost, setSubmitPost] = useState(false);
  const [isSubmittingDeletePost, setSubmitDeletePost] = useState(false);
  const [isOpenModalEliminarPost, setModalEliminarPost] = useState(false);
  const [postSeleccionadoId, setPostSeleccionadoId] = useState("");
  const [areLoadingFiles, setAreLoadingFiles] = useState(false);
  const [isOpenAlertEliminarComentario, setAlertEliminarComentario] = useState(false);
  const [isOpenAlertFailComentario, setAlertFailComentario] = useState(false);
  const [actualPage, setActualPage] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [terapiaSeleccionada, setTerapiaSeleccionada] = useState("");
  const [nuevoPostId, setNuevoPostId] = useState("");
  const [queryParams, setQueryParams] = useState({
    creadores: [],
    fechaMin: "",
    fechaMax: "",
  });
  const [spinnerQueryParams, setspinnerQueryParams] = useState(false);
  const [modalPostEditor, setModalPostEditor] = useState(false);

  var postMaker;

  // Infinite Scroll
  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isLoadingMorePosts) {
        return;
      }
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && actualPage < totalPaginas) {
          setActualPage(actualPage + 1);
          console.log(entries[0]);
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [totalPaginas, isLoadingMorePosts]
  );

  // useEffect para cargar la data inicial
  useEffect(() => {
    setspinnerQueryParams(true);
    setActualPage(1);
    let getPosts;
    if (props.ruta === "terapia") {
      getPosts = async () => {
        let data = await obtenerPostsTerapia(props.idTerapia, 1, queryParams);
        if (data.ok) {
          setPosts(data.posts);
          setTotalPaginas(data.totalPaginas);
        } else {
          alert("Error al obtener los posts");
        }
        setspinnerQueryParams(false);
        setSpinner(false);
      };
    } else {
      getPosts = async () => {
        let data = await obtenerPostsPaciente(props.idPaciente, 1, terapiaSeleccionada, queryParams);
        if (data.ok) {
          setPosts(data.posts);
          setTotalPaginas(data.totalPaginas);
        } else {
          alert("Error al obtener los posts");
        }
        setspinnerQueryParams(false);
        setSpinner(false);
      };
    }
    getPosts();
  }, [terapiaSeleccionada, queryParams]);

  // useEffect para cargar mas posts (paginado). Se ejecuta cuando el estado actualPage cambia.
  useEffect(() => {
    if (actualPage > 1) {
      let getMorePosts;
      if (props.ruta === "terapia") {
        setSpinnerMorePosts(true);
        getMorePosts = async () => {
          let data = await obtenerPostsTerapia(props.idTerapia, actualPage, queryParams);
          if (data.ok) {
            let newPosts = [...posts, ...data.posts];
            setPosts(newPosts);
          } else {
            alert("Error al obtener más posts");
          }
          setSpinnerMorePosts(false);
        };
      } else {
        setSpinnerMorePosts(true);
        getMorePosts = async () => {
          let data = await obtenerPostsPaciente(props.idPaciente, actualPage, terapiaSeleccionada, queryParams);
          if (data.ok) {
            let newPosts = [...posts, ...data.posts];
            setPosts(newPosts);
          } else {
            alert("Error al obtener más posts");
          }
          setSpinnerMorePosts(false);
        };
      }
      getMorePosts();
    }
  }, [actualPage]);

  //  POSTS

  const handlePostTerapia = async (postData, formData, cleanEditor) => {
    setSubmitPost(true);
    let nuevoPost = {
      ...dataPost,
      contenido: postData.contenido,
      mentions: postData.mentions,
    };
    let data = await crearPostTerapia(nuevoPost);

    if (data.ok && formData) {
      setNuevoPostId(data.post._id);
      setAreLoadingFiles(true);
      setPosts([data.post, ...posts]);
      setSubmitPost(false);
      setModalPostEditor(false);

      let filesData = await uploadPostFiles(formData, data.post._id);

      if (filesData.ok) {
        //TODO: 2 setPosts ????????????
        setPosts([...posts].filter((post) => post._id !== filesData.post._id));
        setPosts([filesData.post, ...posts]);
        setAreLoadingFiles(false);
        cleanEditor();
        setDataPost({
          creador: user.id,
          contenido: "",
          rawContent: "",
          mentions: [],
          terapia: props.idTerapia || "",
          paciente: props.idPaciente || "",
          archivos: [],
        });
      } else {
        setAreLoadingFiles(false);
        alert("Error al cargar los archivos");
      }
      setNuevoPostId("");
    } else if (data.ok) {
      setSubmitPost(false);
      setPosts([data.post, ...posts]);
      setModalPostEditor(false);
      cleanEditor();
      setDataPost({
        creador: user.id,
        contenido: "",
        rawContent: "",
        mentions: [],
        terapia: props.idTerapia || "",
        paciente: props.idPaciente || "",
        archivos: [],
      });
    } else {
      setSubmitPost(false);
      alert("Error al crear un nuevo post");
    }
  };

  const handlePostPaciente = async (postData, formData, cleanEditor) => {
    setSubmitPost(true);
    let nuevoPost = {
      ...dataPost,
      contenido: postData.contenido,
      mentions: postData.mentions,
      terapia: postData.terapia,
    };

    let data = await crearPostPaciente(nuevoPost);

    if (data.ok && formData) {
      setNuevoPostId(data.post._id);
      setAreLoadingFiles(true);
      setPosts([data.post, ...posts]);
      setModalPostEditor(false);

      let filesData = await uploadPostFiles(formData, data.post._id);

      if (filesData.ok) {
        //TODO: 2 setPosts ???????????? ==> porque se muestra el post creado mientras se guadan los archivos
        setPosts([...posts].filter((post) => post._id !== filesData.post._id));
        setPosts([filesData.post, ...posts]);
        setAreLoadingFiles(false);
        cleanEditor();
        setDataPost({
          creador: user.id,
          contenido: "",
          rawContent: "",
          mentions: [],
          terapia: props.idTerapia || "",
          paciente: props.idPaciente || "",
          archivos: [],
        });
      } else {
        setAreLoadingFiles(false);
        alert("Error al cargar los archivos");
      }
      setSubmitPost(false);
      setNuevoPostId("");
    } else if (data.ok) {
      setSubmitPost(false);
      setPosts([data.post, ...posts]);
      setModalPostEditor(false);
      cleanEditor();
      setDataPost({
        creador: user.id,
        contenido: "",
        rawContent: "",
        mentions: [],
        terapia: props.idTerapia || "",
        paciente: props.idPaciente || "",
        archivos: [],
      });
    } else {
      setSubmitPost(false);
      alert("Error al crear un nuevo post");
    }
    if (terapiaSeleccionada !== "") {
      setTerapiaSeleccionada(nuevoPost.terapia);
    }
  };

  const handleModalEliminarPost = (postId) => {
    setModalEliminarPost(true);
    setPostSeleccionadoId(postId);
  };

  const deletePost = async (postId) => {
    setSubmitDeletePost(true);
    let data = await borrarPost(postId);
    if (data.ok) {
      setModalEliminarPost(false);
      setPosts(
        posts.filter((post) => {
          return post._id !== postId;
        })
      );
      setAlertEliminar(true);
      setTimeout(() => {
        setAlertEliminar(false);
      }, 3000);
    } else {
      setModalEliminarPost(false);
      setAlertFail(true);
      setTimeout(() => {
        setAlertFail(false);
      }, 3000);
    }
    setSubmitDeletePost(false);
  };

  const handleChange = (postText, mentions) => {
    setDataPost({
      ...dataPost,
      contenido: postText,
      mentions,
    });
  };

  const addFiles = (e) => {
    e.preventDefault();
    let nuevosArchivos = _.uniq([...dataPost.archivos, ...e.target.files], (file) => file.name);

    setDataPost({
      ...dataPost,
      archivos: nuevosArchivos,
    });
    e.target.value = "";
  };

  const removeFiles = () => {
    setDataPost({
      ...dataPost,
      archivos: [],
    });
  };

  const handleFiltrar = (creadores = [], fechaMin = "", fechaMax = "") => {
    let nuevosQueryParams;

    nuevosQueryParams = {
      creadores,
      fechaMin,
      fechaMax,
    };

    setQueryParams(nuevosQueryParams);
  };

  if (props.ruta === "terapia") {
    postMaker = (
      <ModalEditor
        feed={props.feed}
        dataPost={dataPost}
        setDataPost={setDataPost}
        addFiles={addFiles}
        removeFiles={removeFiles}
        title="Crear post"
        nombre={props.nombre}
        idTerapia={props.idTerapia}
        handleSubmitPost={handlePostTerapia}
        isSubmitting={isSubmittingPost}
        possibleMentions={props.possibleMentions}
        modalPostEditor={modalPostEditor}
        closeModal={() => setModalPostEditor(false)}
      />
    );
  } else if (
    (rolUsuario === "Superadmin" || emailUsuario === "giselabisetto@yahoo.com.ar" || rolUsuario === "Admin") &&
    props.terapiasActivas.length > 0
  ) {
    postMaker = (
      <ModalEditor
        feed={props.feed}
        dataPost={dataPost}
        setDataPost={setDataPost}
        handleChange={handleChange}
        addFiles={addFiles}
        removeFiles={removeFiles}
        title="Crear post"
        nombre={props.nombre}
        terapiasTerapeutaEscritura={props.terapiasActivas}
        idTerapia={props.idTerapia}
        handleSubmitPost={handlePostPaciente}
        idPaciente={props.idPaciente}
        isSubmitting={isSubmittingPost}
        possibleMentions={props.terapeutasAsignados}
        modalPostEditor={modalPostEditor}
        closeModal={() => setModalPostEditor(false)}
      />
    );
  } else if (rolUsuario === "Terapeuta" && props.terapiasTerapeutaEscritura.length !== 0) {
    postMaker = (
      <ModalEditor
        feed={props.feed}
        dataPost={dataPost}
        setDataPost={setDataPost}
        handleChange={handleChange}
        addFiles={addFiles}
        removeFiles={removeFiles}
        title="Crear post"
        terapiasTerapeutaEscritura={props.terapiasTerapeutaEscritura}
        idTerapia={props.idTerapia}
        nombre={props.nombre}
        handleSubmitPost={handlePostPaciente}
        idPaciente={props.idPaciente}
        isSubmitting={isSubmittingPost}
        possibleMentions={props.terapeutasAsignados}
        modalPostEditor={modalPostEditor}
        closeModal={() => setModalPostEditor(false)}
      />
    );
  }

  return (
    <div>
      {isOpenAlertEliminar && (
        <Alert variant="success" show={isOpenAlertEliminar}>
          <strong>Eliminación exitosa.</strong> Se ha eliminado el post de forma correcta.
        </Alert>
      )}

      {isOpenAlertFail && (
        <Alert variant="danger" show={isOpenAlertFail}>
          <strong>Eliminación fallida.</strong> No se ha podido eliminar el post. Intente nuevamente.
        </Alert>
      )}

      {isOpenAlertEliminarComentario && (
        <Alert variant="success" show={isOpenAlertEliminarComentario}>
          <strong>Eliminación exitosa.</strong> Se ha eliminado el comentario de forma correcta.
        </Alert>
      )}

      {isOpenAlertFailComentario && (
        <Alert variant="danger" show={isOpenAlertFailComentario}>
          <strong>Eliminación fallida.</strong> No se ha podido eliminar el comentario. Intente nuevamente.
        </Alert>
      )}

      {/* {modalPostEditor && postMaker} */}
      {postMaker}

      {props.ruta === "terapia" ? (
        <div>
          <Animated animationIn="fadeIn">
            <UserEditor
              avatarSource={user.avatar}
              avatarSize="l"
              placeholder={dataPost.plainText && dataPost.plainText !== "\n" ? dataPost.plainText : `Ingresá acá un post sobre ${props.nombre}`}
              onClick={() => setModalPostEditor(true)}
            />
          </Animated>
          {isLoading ? (
            <Spinner />
          ) : (
            <GroupedPosts
              feed={props.feed}
              colorHex={props.colorHex}
              displayFilters={props.displayFilters}
              terapia={props.terapia}
              idTerapia={props.idTerapia}
              isLoading={isLoading}
              data={posts}
              handleModalEliminarPost={handleModalEliminarPost}
              areLoadingFiles={areLoadingFiles}
              nuevoPostId={nuevoPostId}
              ref={lastElementRef}
              possibleMentions={props.possibleMentions}
              queryParams={queryParams}
              handleFiltrar={handleFiltrar}
              creadoresDePosts={props.creadoresDePosts}
              spinnerQueryParams={spinnerQueryParams}
            />
          )}
        </div>
      ) : (
        <div>
          {(rolUsuario === "Superadmin" || emailUsuario === "giselabisetto@yahoo.com.ar" || rolUsuario === "Admin") && props.terapiasActivas.length > 0 ? (
            <Animated animationIn="fadeIn">
              <UserEditor
                avatarSource={user.avatar}
                avatarSize="l"
                placeholder={dataPost.plainText && dataPost.plainText !== "\n" ? dataPost.plainText : `Ingresá acá un post sobre ${props.nombre}`}
                onClick={() => setModalPostEditor(true)}
              />
            </Animated>
          ) : (
            <>
              {rolUsuario === "Terapeuta" && props.terapiasTerapeutaEscritura.length !== 0 && (
                <Animated animationIn="fadeIn">
                  <UserEditor
                    avatarSource={user.avatar}
                    avatarSize="l"
                    placeholder={dataPost.plainText && dataPost.plainText !== "\n" ? dataPost.plainText : `Ingresá acá un post sobre ${props.nombre}`}
                    onClick={() => setModalPostEditor(true)}
                  />
                </Animated>
              )}
            </>
          )}
          {isLoading ? (
            <Spinner />
          ) : (
            <GroupedPosts
              feed={props.feed}
              data={posts}
              terapiasActivas={props.terapiasActivas}
              displayFilters={props.displayFilters}
              idPaciente={props.idPaciente}
              tseleccionada={props.tseleccionada}
              setTerapiaSeleccionada={setTerapiaSeleccionada}
              terapiaSeleccionada={terapiaSeleccionada}
              eliminarPost={deletePost}
              handleModalEliminarPost={handleModalEliminarPost}
              setIsLoadingFilterTerapia={setSpinnerFilterByTerapia}
              isLoadingFilterByTerapia={isLoadingFilterByTerapia}
              areLoadingFiles={areLoadingFiles}
              nuevoPostId={nuevoPostId}
              ref={lastElementRef}
              possibleMentions={props.terapeutasAsignados}
              queryParams={queryParams}
              handleFiltrar={handleFiltrar}
              creadoresDePosts={props.creadoresDePosts}
              spinnerQueryParams={spinnerQueryParams}
            />
          )}
        </div>
      )}
      {isLoadingMorePosts && (
        <div>
          <Spinner spinnerType="extrasmall" />
        </div>
      )}
      {isOpenModalEliminarPost && (
        <ModalPropio
          title="Eliminar post"
          content={"¿Está seguro que quiere eliminar el post? También se borraran los comentarios asociados al mismo."}
          isOpen={isOpenModalEliminarPost}
          danger={true}
          closeModal={setModalEliminarPost}
          okHandler={() => deletePost(postSeleccionadoId)}
          isSubmitting={isSubmittingDeletePost}
          botonPrimario="Eliminar"
          botonSecundario="Cancelar"
        />
      )}
    </div>
  );
};
export default PostContainer;
