//Packages & Utils
import React, { useState, useRef, useCallback, useEffect } from "react";
import styles from "./postComment.module.css";
import draftStyles from "../draftEditors/common.module.css";
import { IoMdTrash } from "react-icons/io";
import { updateComment, crearComentarioReaccion, eliminarComentarioReaccion } from "../../../utils/serverRequest";

// Components
import Avatar from "../avatar/avatar.jsx";
import { EditorState, convertFromRaw } from "draft-js";
import Editor from "draft-js-plugins-editor";
import createMentionPlugin from "draft-js-mention-plugin";
import "draft-js/dist/Draft.css";
import "draft-js-mention-plugin/lib/plugin.css";
import Modal from "../modalWithoutFooter/modalWithoutFooter";
import ViewsList from "../viewsList/viewsList";
import ComentarioReaccionButton from "../comentarioReaccionButton/comentarioReaccionButton";
import ReactionsTab from "../reactionTabs/reactionTabs";
import ReaccionesDadas from "../reaccionesDadas/reaccionesDadas";
import ReactQuill from "react-quill";

const mentionPlugin = createMentionPlugin();
const plugins = [mentionPlugin];
const textMaxHeight = 128; // Tiene que ser igual que en ../draftEditors/common.module.css

const PostComment = (props) => {
  const [editorState, setEditorState] = useState(props.rawContent ? EditorState.createWithContent(convertFromRaw(props.rawContent)) : {});
  const [isViewed, setIsViewed] = useState(props.isViewed);
  const [modalViewsOpen, setModalViewsOpen] = useState(false);
  const [expanded, setexpanded] = useState(true);
  const [spinnerReaccion, setSpinnerReaccion] = useState(false);
  const [dioReaccion, setDioReaccion] = useState(false);
  const [reaccionDada, setReaccionDada] = useState("");
  const [cantidadReacciones, setCantidadReacciones] = useState(0);
  const [comentarioReaccion, setComentarioReaccion] = useState({});
  const [reaccionesDadas, setReaccionesDadas] = useState([]);
  const [modalReactionsOpen, setModalReactionsOpen] = useState(false);

  const commentText = useRef();
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    setDioReaccion(props.dioReaccion);
    setReaccionDada(props.reaccionDada);
    setCantidadReacciones(props.cantidadReacciones);
    setReaccionesDadas(props.reaccionesDadas);
    setComentarioReaccion(props.comentarioReaccion);

    if (commentText.current.clientHeight > textMaxHeight) {
      setexpanded(false);
    }
  }, []);

  // Views Tracker
  const observer = useRef();
  var viewRef = useCallback((node) => {
    if (props.views && props.isViewed) {
      return;
    }

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(async (entries) => {
      if (entries[0].isIntersecting && !isViewed && user.nombreCompleto !== "Superadmin") {
        setIsViewed(true);
        let res = await updateComment(props.idComentario, { $push: { views: { viewer: user, readAt: new Date() } } });
        if (!res.data.ok) {
          console.log("Error");
          setIsViewed(false);
        }
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  });

  const handleViewsModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setModalViewsOpen(!modalViewsOpen);
  };

  const expandHandler = (e) => {
    e.preventDefault(setexpanded(true));
  };

  const handleNuevaReaccion = async (reaccion) => {
    setSpinnerReaccion(true);
    let reaccionesBackUp = [...reaccionesDadas];
    setDioReaccion(true);
    setReaccionDada(reaccion);
    setCantidadReacciones((cantidadReacciones) => cantidadReacciones + 1);
    let data = await crearComentarioReaccion(props.idComentario, reaccion);
    addReaccionFront(data.reaccion);
    setComentarioReaccion(data.reaccion);

    if (!data.ok) {
      setDioReaccion(false);
      setReaccionDada("");
      setCantidadReacciones((cantidadReacciones) => cantidadReacciones - 1);
      setReaccionesDadas(reaccionesBackUp);
      setComentarioReaccion("");
      alert("Error al guardar la reacción");
    }
    setSpinnerReaccion(false);
  };

  const addReaccionFront = (reaccion) => {
    let newReacciones;
    if (reaccionesDadas.length === 0) {
      newReacciones = [[reaccion.reaccion.emojiCode, [reaccion]]];
      setReaccionesDadas(newReacciones);
    } else {
      if (reaccionesDadas.some((re) => re[0] === reaccion.reaccion.emojiCode)) {
        newReacciones = reaccionesDadas.map((r) => {
          if (r[0] === reaccion.reaccion.emojiCode) {
            return [r[0], [...r[1], reaccion]];
          } else {
            return r;
          }
        });
        setReaccionesDadas(newReacciones);
      } else {
        newReacciones = [...reaccionesDadas, [reaccion.reaccion.emojiCode, [reaccion]]];
        setReaccionesDadas(newReacciones);
      }
    }
  };

  const removeReaccion = async (e, comentarioReaccion) => {
    e.preventDefault();
    setSpinnerReaccion(true);
    let reaccionesBackUp = [...reaccionesDadas];
    setDioReaccion(false);
    removeReaccionFront(comentarioReaccion);
    setCantidadReacciones((cantidadReacciones) => cantidadReacciones - 1);
    setComentarioReaccion("");
    let data = await eliminarComentarioReaccion(comentarioReaccion._id, props.idComentario);
    if (!data.ok) {
      setDioReaccion(true);
      setReaccionesDadas(reaccionesBackUp);
      setCantidadReacciones((cantidadReacciones) => cantidadReacciones + 1);
      alert("Error al sacar la reacción");
    }
    setSpinnerReaccion(false);
  };

  // TODO: Ver la estructura de lo que mando desde el server para entender esto (Mando reacciones agrupadas por emojiCode)
  const removeReaccionFront = (comentarioReaccion) => {
    let newReacciones = reaccionesDadas.map((pr) => {
      if (pr[0] === comentarioReaccion.reaccion.emojiCode) {
        return pr[1].length === 1 ? [] : [pr[0], pr[1].filter((pr) => pr._id !== comentarioReaccion._id)];
      } else {
        return pr;
      }
    });

    setReaccionesDadas(newReacciones.filter((r) => r.length > 0));
  };

  const handleReactionsModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setModalReactionsOpen(!modalReactionsOpen);
  };

  return (
    <div className={styles.firstRowClass + " row"}>
      <div className={"col-md-1 d-none d-md-block " + styles.colAvatar}>
        <Avatar rutaAvatar={props.avatarTerapeuta} size="s" />
      </div>

      <div className={styles.inputContainerClass + " col-12 col-md-11"}>
        <div className={styles.containerPersonalData}>
          <div ref={viewRef} style={{ justifyContent: "space-between", display: "flex" }}>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <div className="d-sm-inline-block d-md-none mr-3" style={{ width: "32px" }}>
                <Avatar rutaAvatar={props.avatarTerapeuta} size="s" />
              </div>

              <div className="d-sm-inline-block">
                <p className={styles.nombrePacienteClass}>{props.nombreTerapeuta}</p>
                <small className={styles.smallAntiguedad}>{props.antiguedadComentario}</small>
              </div>
            </div>
            {(props.email === "dra.donadio@gmail.com" || props.idUsuario === props.idCreador) && (
              <button className={styles.customButton}>
                <IoMdTrash
                  color="#7f7f7f"
                  size={20}
                  className={styles.customSvg + " " + styles.customSvgDelete}
                  onClick={() => props.modalBorrarComentario(props.idComentario)}
                />
              </button>
            )}
          </div>
        </div>
        <div ref={commentText} className={expanded ? "" : `${draftStyles.paginatedText}`}>
          {props.rawContent ? (
            <Editor
              blockStyleFn={() => `${draftStyles.commentStyles}`}
              readOnly
              editorState={editorState}
              onChange={(newState) => setEditorState(newState)}
              plugins={plugins}
            />
          ) : (
            <ReactQuill
              readOnly
              value={props.textoComentario}
              modules={{ toolbar: false }}
              style={{ margin: "-12px -15px" }}
              className="selectableText"
            />
          )}
        </div>

        {!expanded && (
          <a className="mr-auto" style={{ fontSize: "12px", cursor: "pointer" }} onClick={(e) => expandHandler(e)}>
            Ver más
          </a>
        )}
        <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
          {cantidadReacciones > 0 && (
            <ReaccionesDadas
              className="mr-auto"
              cantidadReacciones={cantidadReacciones}
              reacciones={reaccionesDadas}
              handleReactionModal={handleReactionsModal}
            />
          )}
          {props.views && props.views.length > 0 && user.email === "dra.donadio@gmail.com" && (
            <a className="ml-auto text-right" style={{ fontSize: "14px" }} href="#" onClick={handleViewsModal}>
              {props.views.length === 1 ? "1 visto" : `${props.views.length} vistos`}
            </a>
          )}
        </div>

        <ComentarioReaccionButton
          reaccionesPosibles={props.reaccionesPosibles}
          handleNuevaReaccion={handleNuevaReaccion}
          dioReaccion={dioReaccion}
          reaccionDada={reaccionDada}
          comentarioReaccion={comentarioReaccion}
          removeReaccion={removeReaccion}
          defaultReaction={props.defaultReaction}
          isLoading={false}
          cantidadReacciones={cantidadReacciones}
        />
      </div>
      {modalViewsOpen && (
        <Modal title="Vistos" isOpen={modalViewsOpen} closeModal={handleViewsModal}>
          <ViewsList views={props.views} />
        </Modal>
      )}

      {modalReactionsOpen && (
        <Modal title="Reacciones" reactions={reaccionesDadas} isOpen={modalReactionsOpen} closeModal={handleReactionsModal}>
          <ReactionsTab reactions={reaccionesDadas} />
        </Modal>
      )}
    </div>
  );
};

export default PostComment;
