import React from "react";
import PropTypes from "prop-types";

const InfoSectionTitle = (props) => {
  return (
    <div className="d-flex justify-content-start mb-3">
      <h5 className="text-left">{props.value}</h5>
    </div>
  );
};

InfoSectionTitle.propTypes = {
  value: PropTypes.string,
};

export default InfoSectionTitle;
