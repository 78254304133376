import React, { useState } from 'react';
import OptionLabel from '../optionLabel/optionLabel';
import { MdClose } from 'react-icons/md';
import styles from './dropdownPuroTerapeuta.module.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const DropdownPuroTerapeuta = props => {
  const [terapeutaSeleccionado, setTerapeutaSeleccionado] = useState('');

  const handleTerapeutaSeleccionado = e => {
    e.preventDefault();
    setTerapeutaSeleccionado(e.target.value);
  };

  const handleAgregar = e => {
    if (!terapeutaSeleccionado) {
        alert('Deberá seleccionar un terapeuta');
    } else {
        props.addItems(terapeutaSeleccionado);
        setTerapeutaSeleccionado('');
    }
  };

  const handleRemover = terapeutaId => {
    props.removeItem(terapeutaId);
  };

  return (
    <Row>
      <Col xs={12} md={8}>
        <div className='form-group form-group-lg'>
          <label className='control-label label-label' style={{ float: 'left' }} htmlFor='list'>
            Creador
          </label>
          <select
            className=' select form-control'
            id='list'
            value={terapeutaSeleccionado}
            onChange={handleTerapeutaSeleccionado}
          >
            <option selected value=''></option>
            {props.list.length > 0 ? (
              props.list.map((terapeuta, index) => (
                <OptionLabel
                  key={index}
                  text={terapeuta.nombreCompleto}
                  value={terapeuta._id}
                />
              ))
            ) : (
              <></>
            )}
          </select>
        </div>
      </Col>

      <Col xs={12} md={4} className={`mb-4 mb-0 ${styles.containerButton}`}>
        <button
          type='button'
          className={
            'btn btn-outline-primary btn-block ' + styles.btnGeneralStyle
          }
          onClick={handleAgregar}
        >
          {props.buttonTitle}
        </button>
      </Col>

      <Col xs={12}>
        <ul className={styles.selectedList}>
          {props.terapeutasSeleccionados.length > 0 ? (
            props.terapeutasSeleccionados.map((terapeuta, key) => (
              <li key={key} className={styles.selectedItem}>
                <MdClose
                  className={styles.deleteSelectedItem}
                  color='red'
                  onClick={() => handleRemover(terapeuta._id)}
                />
                <p className={styles.terapeutaName}>
                  {terapeuta.nombreCompleto}
                </p>
              </li>
            ))
          ) : (
            <li>{props.emptyState}</li>
          )}
        </ul>
      </Col>
    </Row>
  );
};

export default DropdownPuroTerapeuta;
