import React from 'react';
import FormStep from '../Globales/formStep/formStep';
import DropdownTerapia from '../Globales/dropdownTerapia/dropdownTerapia';

const Step3 = props => {
  return (
    <FormStep stepsHandler={props.aumentarDecrementarPaso}>
      <DropdownTerapia
        titulo='Terapias'
        buttonTitle='AGREGAR TERAPIA'
        list={props.terapiasIniciales}
        addItems={props.agregarTerapias}
        removeItem={props.removerTerapias}
        selectItem={props.selectItem}
        selectedItems={props.selectedItems}
        emptyState='Aún no se han asignado terapias'
        terapiaActual={props.terapiaActual}
      />
    </FormStep>
  );
};

export default Step3;
