import React from "react";
import { FaFileExcel, FaFileCsv, FaFilePdf, FaFilePowerpoint, FaFileArchive, FaFileWord, FaFile, FaFileImage } from "react-icons/fa";

const FilePreview = (props) => {
  let tag = (
    <a href={props.presignedUrl} target="_blank" rel="noopener noreferrer" style={{ margin: ".5rem 1rem", verticalAlign: "middle", fontWeight: "400" }}>
      {props.originalLink.substring(props.originalLink.lastIndexOf("/") + 1)}
    </a>
  );

  let extension = props.originalLink.substring(props.originalLink.lastIndexOf(".") + 1).toLowerCase();

  let extensionIcon;
  switch (extension) {
    case "pdf":
      extensionIcon = <FaFilePdf color="red" />;
      break;
    case "xls":
      extensionIcon = <FaFileExcel color="green" />;
      break;
    case "xlsx":
      extensionIcon = <FaFileExcel color="green" />;
      break;
    case "csv":
      extensionIcon = <FaFileCsv color="green" />;
      break;
    case "ppt":
      extensionIcon = <FaFilePowerpoint color="red" />;
      break;
    case "word":
      extensionIcon = <FaFileWord color="blue" />;
      break;
    case "docx":
      extensionIcon = <FaFileWord color="blue" />;
      break;
    case "zip":
      extensionIcon = <FaFileArchive color="grey" />;
      break;
    case "rar":
      extensionIcon = <FaFileArchive color="grey" />;
      break;
    case "jpg":
      extensionIcon = <FaFileImage color="grey" />;
      break;
    case "jpeg":
      extensionIcon = <FaFileImage color="grey" />;
      break;
    case "png":
      extensionIcon = <FaFileImage color="grey" />;
      break;
    default:
      extensionIcon = <FaFile color="grey" />;
      break;
  }

  return (
    <li style={{ display: "flex", justifyContent: "flex-start", verticalAlign: "middle", alignItems: "center" }}>
      {extensionIcon}
      {tag}
    </li>
  );
};

export default FilePreview;
