//Packages & Utils
import React from 'react';
import styles from './boxInfoFeedTerapia.module.css';

const BoxInfoFeedTerapia = props => {
  let aForoStyle = '';
  let aInfoStyle = '';
  if (props.paginaSeleccionada === 'foro') {
    aForoStyle = styles.itemSelected;
    aInfoStyle = '';
  } else {
    aForoStyle = '';
    aInfoStyle = styles.itemSelected;
  }
  return (
    <div className={styles.box}>
      <div className={styles.contenedorNombreAvatar}>
        <div className={styles.contenedorNombre + ' col-12'}>
          <h4>{props.tituloTerapia}</h4>
          <p style={{ textAlign: 'left' }}>
            {props.descripcionTerapia
              ? props.descripcionTerapia
              : '(Sin descripción)'}
          </p>
        </div>
      </div>
      <div className={styles.contenedorBotones + ' container'}>
        <ul className={styles.linkList}>
          <li className={styles.linkItem}>
            <a
              className={aForoStyle}
              id='foro'
              onClick={() => props.clicked('foro')}
            >
              Foro
            </a>
          </li>
          <li className={styles.linkItem}>
            <a
              id='more_info'
              className={aInfoStyle}
              onClick={() => props.clicked('more_info')}
            >
              Información de la terapia
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BoxInfoFeedTerapia;
