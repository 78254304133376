import React, { useState } from "react";
import QuilEditor from "../quilEditor/quilEditor";
import ModalForEditor from "../modalForEditor/modalForEditor";
import _ from "underscore";
import { Prompt } from "react-router";

const ModalCommentEditor = (props) => {
  const [keyboardOpened, setkeyboardOpened] = useState(false);

  const onMobile = () => {
    return window.innerWidth <= 768;
  };

  return (
    <ModalForEditor
      title={props.title}
      isOpen={props.modalPostEditor}
      danger={false}
      closeModal={props.closeModal}
      okHandler={props.handleSubmitComment}
      isSubmitting={props.isSubmitting}
      botonPrimario="Publicar"
      botonSecundario="Cancelar"
      centered={true}
      disabledButton={props.isEmpty()}
      keyboardOpened={keyboardOpened}
      onMobile={onMobile}
    >
      <Prompt when={props.shouldBlockNavigation} message="Hay cambios sin guardar, se perderán al salir" />
      <QuilEditor
        value={props.dataComentario.contenido}
        handleChange={props.handleChange}
        placeholder={props.placeholder || "Ingrese aquí el texto"}
        possibleMentions={props.possibleMentions}
        setKeyboardOpened={setkeyboardOpened}
        keyboardOpened={keyboardOpened}
        clearEditor={props.clearEditor}
        disabledButton={props.isEmpty()}
      />
    </ModalForEditor>
  );
};

export default ModalCommentEditor;
