import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import styles from "./login.module.css";
import * as yup from "yup";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import FormContainer from "../Globales/formContainer/FormContainer";
import FormInput from "../Globales/formInput/FormInput";
import FormButton from "../Globales/formButton/FormButton";
import InputMessage from "../Globales/inputMessage/InputMessage";
import { forgotPassword } from "../../utils/serverRequest";
import { UserContext } from "../context/context";
import ProcessMessage from "./processMessage";
import ConfirmationMailPassword from "./ConfirmationMailPassword/ConfirmationMailPassword";

const ForgotPassword = (props) => {
  const [serverError, setServerError] = useState(false);
  const [serverOk, setserverOk] = useState(false);
  const [email, setemail] = useState("");
  const [isSubmitting, setisSubmitting] = useState(false);
  const [user, setUser] = useContext(UserContext);
  let history = useHistory();

  const handleChange = (e) => {
    setemail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisSubmitting(true);
    const res = await forgotPassword(email);

    if (res.status === 200) {
      setserverOk(true);
    } else {
      setServerError(true);
    }
    setisSubmitting(false);
  };

  const isValid = (value) => {
    return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
      value
    );
  };
  return (
    <div className="col-lg-6 col-md-6 col-sm-9 col-xs-12 container">
      {!isSubmitting && serverOk ? (
        <ConfirmationMailPassword />
      ) : (
        <FormContainer title="Recuperar contraseña" clicked={handleSubmit}>
          <FormInput
            labelText="Correo electrónico"
            labelHtmlFor="email"
            atrName="email"
            atrId="email"
            atrType="text"
            atrValue={email}
            changed={handleChange}
            isRequired={true}
            hasErrors={email && !isValid(email)}
          >
            {email && !isValid(email) && <InputMessage message="Ingrese un email correcto" atrId="email" hasErrors={email && !isValid(email)} />}
          </FormInput>
          {serverError && <InputMessage message={"Hubo un error en el servidor"} atrId="hint_sverror" hasErrors={serverError ? true : false} />}

          <div className={styles.buttonPosition + " col-xl-4 col-lg-6 col-12"} style={{ marginTop: "32px" }}>
            <FormButton text="SOLICITAR" buttonType="submit" buttonStyle="primario" atrName="ingresar" atrDisabled={isSubmitting} clicked={handleSubmit} />
          </div>

          <div className="form-group">
            <p className={styles.refText}>
              ¿Ya tenés cuenta?{" "}
              <Link to="/ingresar">
                <a href="#">Ingresá acá</a>
              </Link>
            </p>
          </div>
        </FormContainer>
      )}
    </div>
  );
};

ForgotPassword.propTypes = {};

export default ForgotPassword;
