import React from 'react';
import styles from './modalWithoutFooter.module.css';
import { Modal, Button } from 'react-bootstrap';

const modalWithoutFooter = props => {
  return (
    // <div className={styles.modalPropio}>
      <Modal centered show={props.isOpen} onHide={props.closeModal}>
      <div style={{height: '6px', borderTopLeftRadius: '3px', borderTopRightRadius: '3px', background: `linear-gradient(to right, rgba(255,255,255,1) -25%, #167bff 38%, #167bff 100%)`}}></div>
        <Modal.Header closeButton >
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body className={styles.modalBody}>
          <p>{props.content}</p>
          {props.children}
        </Modal.Body>
      </Modal>
    // </div>
  );
};

export default modalWithoutFooter;
