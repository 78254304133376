import React, { useState, useEffect } from "react";
import styles from "./creacionTerapias.module.css";
import { useHistory } from "react-router-dom";
import { obtenerTerapeutasPreview, crearTerapia } from "../../utils/serverRequest";
import Dropdown from "../Globales/dropdown/dropdown";
import FormContainer from "../Globales/formContainer/FormContainer";
import FormButton from "../Globales/formButton/FormButton";
import FormInput from "../Globales/formInput/FormInput";
import InputMessage from "../Globales/inputMessage/InputMessage";
import FormTextArea from "../Globales/formTextArea/FormTextArea";
import DashboardTitle from "../dashboardTitle/dashboardTitle";
import * as yup from "yup";
import { useFormik } from "formik";
import { MdClose } from "react-icons/md";
import BackButton from "../Globales/backButton/BackButton";
import ColorPicker from "../Globales/colorPicker/colorPicker";
import { Animated } from "react-animated-css";

let validationSchemaTerapias = yup.object().shape({
  titulo: yup.string().required("Este campo es requerido"),
});

const CreacionTerapias = () => {
  let history = useHistory();
  const [serverError, setServerError] = useState(false);
  const [serverErrorDescription, setServerErrorDescription] = useState("");
  const [formCreacionTerapias, setCreacionTerapias] = useState({
    titulo: "",
    descripcion: "",
    terapeutas: [],
    previewTerapeutas: [],
    terapeutaSeleccionado: {},
    colorHex: "#ffffff",
  });
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [colores, setColores] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { handleChange, handleSubmit, values, errors, isSubmitting } = useFormik({
    initialValues: {
      titulo: "",
    },
    validationSchema: validationSchemaTerapias,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: async () => {
      let nuevaTerapia = {
        titulo: values.titulo,
        descripcion: formCreacionTerapias.descripcion,
        terapeutas: formCreacionTerapias.terapeutas,
        colorHex: formCreacionTerapias.colorHex,
      };
      let res = await crearTerapia(nuevaTerapia);
      switch (res.status) {
        case 200:
          history.push({
            pathname: "/panel/terapias",
            state: {
              alertStatus: true,
              titulo: values.titulo,
              descripcion: formCreacionTerapias.descripcion,
              tipo: "creacion",
            },
          });
          break;
        case 400:
          setServerError(true);
          setServerErrorDescription(res.data.err);
          break;
        case 500:
          setServerError(true);
          setServerErrorDescription("Hubo un error inesperado del servidor");
          break;
        default:
          console.log(res);
          break;
      }
    },
  });

  useEffect(() => {
    let traerTerapeutas = async () => {
      let data = await obtenerTerapeutasPreview();
      if (data.ok) {
        setCreacionTerapias({
          ...formCreacionTerapias,
          previewTerapeutas: data.terapeutas,
        });
        setColores(data.colores);
        setDataLoaded(true);
      } else {
        alert("Hubo un error interno del servidor");
      }
    };
    traerTerapeutas();
  }, []);

  const handlerChangePropio = (event) => {
    event.preventDefault();
    setCreacionTerapias({
      ...formCreacionTerapias,
      [event.target.name]: event.target.value,
    });
  };

  const agregarTerapeuta = (event) => {
    event.preventDefault();
    if (formCreacionTerapias.terapeutaSeleccionado === "") {
      return alert("No ha seleccionado terapeuta");
    } else {
      let nuevosTerapeutas = [
        ...formCreacionTerapias.terapeutas,
        ...formCreacionTerapias.previewTerapeutas.filter((terapeuta) => terapeuta._id === formCreacionTerapias.terapeutaSeleccionado),
      ];
      let nuevoPreview = [...formCreacionTerapias.previewTerapeutas.filter((terapeuta) => terapeuta._id !== formCreacionTerapias.terapeutaSeleccionado)];
      setCreacionTerapias({
        ...formCreacionTerapias,
        terapeutas: nuevosTerapeutas,
        previewTerapeutas: nuevoPreview,
        terapeutaSeleccionado: "",
      });
    }
  };

  const eliminarTerapeuta = (event, id) => {
    event.preventDefault();
    let newOptions = [
      ...formCreacionTerapias.terapeutas.filter((terapeuta) => {
        return terapeuta._id !== id;
      }),
    ];
    let newPreview = [
      ...formCreacionTerapias.previewTerapeutas,
      ...formCreacionTerapias.terapeutas.filter((terapeuta) => {
        return terapeuta._id === id;
      }),
    ];
    setCreacionTerapias({
      ...formCreacionTerapias,
      terapeutas: newOptions,
      previewTerapeutas: newPreview,
    });
  };

  //funcion para el backButton
  const goBack = () => {
    history.goBack();
  };

  const handleChangeColor = (color, event) => {
    setShowColorPicker(false);
    setCreacionTerapias({
      ...formCreacionTerapias,
      colorHex: color.hex,
    });
  };

  return (
    <div className="dashboardContainer">
      <Animated animationIn="fadeIn">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <BackButton onClick={goBack} />
          <DashboardTitle title="Agregar terapia" />
        </div>
      </Animated>
      <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12 container">
        <Animated animationIn="fadeIn">
          <FormContainer title="Datos de la terapia" clicked={handleSubmit}>
            <FormInput
              labelText="Título (*)"
              labelHtmlFor="titulo"
              atrName="titulo"
              atrId="titulo"
              atrType="text"
              atrValue={values.titulo}
              changed={handleChange}
              hasErrors={errors.titulo ? true : false}
              isRequired={true}
            >
              {errors.titulo ? <InputMessage message={errors.titulo} atrId="hint_tituloTerapia" hasErrors={errors.titulo ? true : false} /> : null}
            </FormInput>
            <FormTextArea
              labelHtmlFor="descripcion"
              labelText="Descripción"
              atrMaxLength="100"
              atrId="descripcion"
              atrName="descripcion"
              atrType="text"
              atrValue={formCreacionTerapias.descripcion}
              changed={handlerChangePropio}
            />

            <ColorPicker
              handleChange={handleChangeColor}
              colores={colores}
              backgroundColor={formCreacionTerapias.colorHex}
              show={showColorPicker}
              setShow={setShowColorPicker}
            />

            <Dropdown
              labelText="Terapeutas"
              labelHtmlFor="terapeutas"
              atrValue={formCreacionTerapias.terapeutaSeleccionado}
              changed={handlerChangePropio}
              data={formCreacionTerapias.previewTerapeutas}
              dataLoaded={dataLoaded}
            />

            <div className={styles.buttonPosition + " col-xl-4 col-lg-6 col-12"} style={{ marginTop: "32px" }}>
              <FormButton text="AGREGAR TERAPEUTA" buttonType="button" buttonStyle="secundario" atrName="agregarTerapeuta" clicked={agregarTerapeuta} />
            </div>

            <div className={"col-12 " + styles.containerSelectedList}>
              <ul className={styles.selectedList + " col-6"}>
                {formCreacionTerapias.terapeutas.length > 0 ? (
                  formCreacionTerapias.terapeutas.map((terapeuta, key) => (
                    <li className={styles.selectedItem}>
                      <MdClose className={styles.deleteSelectedItem} color="red" onClick={(event) => eliminarTerapeuta(event, terapeuta._id)} />
                      <p className={styles.terapeutaName} value={terapeuta}>
                        {terapeuta.nombreCompleto}
                      </p>
                    </li>
                  ))
                ) : (
                  <li style={{ whiteSpace: "nowrap" }}>Aún no se han asignado terapias</li>
                )}
              </ul>
            </div>

            {serverError && <InputMessage message={serverErrorDescription} atrId="hint_sverror" hasErrors={serverError ? true : false} />}
            <div className={styles.buttonPosition + " col-xl-4 col-lg-6 col-12"} style={{ marginTop: "32px" }}>
              <FormButton
                text="CREAR TERAPIA"
                buttonType="submit"
                buttonStyle="primario"
                atrName="crearTerapia"
                atrDisabled={isSubmitting}
                isSubmitting={isSubmitting}
                spinnerType="white"
              />
            </div>
          </FormContainer>
        </Animated>
      </div>
    </div>
  );
};

export default CreacionTerapias;
