import React from 'react';
import FormStep from '../Globales/formStep/formStep';
import DropdownTerapeuta from '../Globales/dropdownTerapeuta/dropdownTerapeuta';
import _ from 'underscore';

// Comentarios sobre Dropdodwn

// Necesito pasarle la terapia como ID, para poder hacer el linkeo en el estado del formulario con el
// terapeuta (Y que no se agregue 2 veces si el terapeuta llega a estar en mas de una terapia).
// También paso complex para determinar el handler, ya que en este caso el addItems necesíta recibir 1 parámetro
// (el ID de la terapia correspondiente, por lo mencionado anteriormente)

const Step4 = props => {
  const titleStyle = {
    textAlign: 'left',
    marginBottom: '16px'
  };

  return (
    <FormStep
      stepsHandler={props.aumentarDecrementarPaso}
      last={true}
      isSubmitting={props.isSubmitting}
      textSubmitButton={props.textSubmitButton}
    >
      {props.terapiasSeleccionadas.length === 0 ? (
        <p style={{ textAlign: 'left' }}>
          Como no has asignado ninguna terapia a este paciente, no podrás
          asignar terapeutas.
        </p>
      ) : (
        <>
          {props.terapiasSeleccionadas.map((terapia, key) => (
            <div key={key}>
              {key > 0 && <div style={{ marginTop: '32px' }}></div>}
              <h5 style={titleStyle}>
                Terapia #{key + 1}: {terapia.titulo}
              </h5>
              {terapia.terapeutas.length > 0 ? (
                <DropdownTerapeuta
                  key={key}
                  titulo={terapia.titulo}
                  util={terapia._id}
                  complex={true}
                  buttonTitle='AGREGAR TERAPEUTA'
                  list={terapia.terapeutas.filter(
                    terapeuta =>
                      !_.contains(
                        props.selectedItems
                          .filter(acceso => acceso.terapia === terapia._id)
                          .map(acceso => acceso.terapeuta._id),
                        terapeuta._id
                      )
                  )}
                  addItems={props.agregarTerapeutas}
                  selectItem={props.selectItem}
                  selectedItems={props.selectedItems.filter(
                    tt => tt.terapia === terapia._id
                  )}
                  removeItem={props.removerAcceso}
                  emptyState={`Aún no se ha seleccionado ningún terapeuta a ${terapia.titulo}`}
                />
              ) : (
                <p style={{ textAlign: 'left' }}>
                  Esta terapia no tiene terapeutas asignados.
                </p>
              )}
            </div>
          ))}
        </>
      )}
    </FormStep>
  );
};
export default Step4;
