import React, { useState, useRef } from 'react';
import styles from './reaccionesButton.module.css';
import Overlay from 'react-bootstrap/Overlay';
import { Twemoji } from 'react-emoji-render';
import ReaccionesPosibles from '../reaccionesPosibles/reaccionesPosibles';

const ReaccionesButton = props => {
  const [openTooltipTrigger, setOpenTooltipTrigger] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const target = useRef(null);

  const handleNewReaction = (reaction) => {
    setOpenTooltip(false)
    setOpenTooltipTrigger(false)
    props.handleNuevaReaccion(reaction)
  }

  let popoverItem = (
    <div
      placement='top'
      className={styles.tooltipContainer}
      onMouseOver={() => setOpenTooltip(true)}
      onMouseEnter={() => setOpenTooltip(true)}
      onMouseLeave={() => setOpenTooltip(false)}
    >
      <ReaccionesPosibles
        reacciones={props.reaccionesPosibles}
        handleClick={handleNewReaction}
        dioReaccion={props.dioReaccion}
      />
    </div>
  );

  let classReaccionesButtonContainer;
  if (props.dioReaccion) {
    classReaccionesButtonContainer = styles.reaccionesButtonContainerConReaccion;
  } else {
    classReaccionesButtonContainer = styles.reaccionesButtonContainerSinReaccion;
  }

  return (
    <div className={`col-md-2 col-sm-6 col-12 ${classReaccionesButtonContainer}`}>
      <Overlay target={target.current} show={openTooltip || openTooltipTrigger}>
        {popoverItem}
      </Overlay>
      <div
        className={`${styles.reaccionesButton}`}
        ref={target}
        onMouseEnter={() => setOpenTooltipTrigger(true)}
        onMouseLeave={() => setOpenTooltipTrigger(false)}
        onTouchEnd={() => !props.dioReaccion ? setOpenTooltipTrigger(true) : ''}
        onClick={
          props.isLoading ? () => '' :
          props.dioReaccion && props.postReaccion
            ? () => props.removeReaccion(props.postReaccion)
            : () => handleNewReaction(props.defaultReaction)
        }
      >
        <Twemoji onlyEmojiClassName={styles.emojiButton} text={props.dioReaccion ? props.reaccionDada.emojiCode || ':+1:' : ':+1:'}/>
        <p className={styles.noselect}>
          {props.dioReaccion ? props.reaccionDada.titulo || 'Me gusta' : 'Me gusta'}
        </p>
      </div>
    </div>
  );
};

export default ReaccionesButton;
