import React, {useState, useEffect} from 'react'
import {UserContext} from './context';


const UserProvider = props => {
    const [user, setUser] = useState({})

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('user'))
        if(userData){
            setUser(userData)
        }
    }, [])

    return (
        <UserContext.Provider value={[user, setUser]}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserProvider;

