import React from 'react'
import styles from './moreImagesVertical.module.css'


const MoreImagesVertical = (props) => {

    return (
        <div className={styles.moreImagesContainer}>
            {`+${props.cantidad.toString()}` || '+8'}
        </div>
    )
}

export default MoreImagesVertical