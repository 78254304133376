import React, {useRef} from 'react'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import {GithubPicker} from 'react-color'
import Button from 'react-bootstrap/Button'
import './colorPicker.module.css'


const ColorPicker = (props) => {

    const target = useRef(null);
    let popover = <Popover show={props.show} onMouseLeave={()=> props.setShow(!props.show)} >

        <GithubPicker
        color={props.backgroundColor}
        colors={props.colores}
          triangle='hide'
          onChangeComplete={props.handleChange}
          />

    </Popover>

    return (
        <div className="d-flex justify-content-start align-items-center my-5">
            <Button className="mr-5" onClick={() => props.setShow(!props.show)}>Selccionar Color</Button>
            <div id="containerColor" ref={target} style={{height: '32px', width: '32px', backgroundColor: props.backgroundColor || 'black'}}></div>
            <Overlay target={target.current} show={props.show} placement='right'>
                {popover}
            </Overlay>
        </div>
    )
}

export default ColorPicker