//Packages & Utils
import React from "react";
import styles from "./feedMoreInformation.module.css";
import PostFilePreview from "../postInfo/postFilePreview";
import { mapObject } from "underscore";
import InfoSectionContainer from "./infoSectionContainer/infoSectionContainer";
import InfoItem from "./infoItem/infoItem";
import ActiveTherapiesItem from "./activeTherapiesItem/activeTherapiesItem";
import AccesosItem from "./accesosItem/accesosItem";
import InfoSectionTitle from "./infoSectionTitle/infoSectionTitle";
import FilesItem from "./filesItem/filesItem";

const FeedMoreInformation = (props) => {
  let accesosTerapeutas = [];

  mapObject(props.data.accesos, (terapia, index) => {
    accesosTerapeutas.push({ terapia: index });
    let terapeutas = terapia.map((terapeuta, index) => terapeuta);

    accesosTerapeutas[accesosTerapeutas.length - 1] = {
      ...accesosTerapeutas[accesosTerapeutas.length - 1],
      terapeutas: terapeutas,
    };
  });

  return (
    <div className={styles.box}>
      <div className={styles.marginBox}>
        <div className="container-fluid p-0">
          <InfoSectionContainer>
            <InfoSectionTitle value="Datos personales" />
            <InfoItem title="Nombre completo" value={props.data.nombreCompleto} />
            <InfoItem title="Nacionalidad" value={props.data.nacionalidad} />
            <InfoItem title="Género" value={props.data.genero} />
            <InfoItem title="Documento" value={props.data.dni} />
            <InfoItem title="Está escolarizado?" value={props.data.estaEscolarizado} />
            <InfoItem title="Información escolar" value={props.data.informacionEscolar} />
            <ActiveTherapiesItem terapiasActivas={props.data.terapiasActivas} />
          </InfoSectionContainer>

          <InfoSectionContainer>
            <InfoSectionTitle value="Datos de contacto" />
            <InfoItem title="Domicilio" value={props.data.domicilio} />
            <InfoItem title="Localidad" value={props.data.localidad} />
            <InfoItem title="Código postal" value={props.data.codigoPostal} />
            <InfoItem title="Teléfono (1)" value={props.data.telefono1} />
            <InfoItem title="Teléfono (2)" value={props.data.telefono2} />
          </InfoSectionContainer>

          <InfoSectionContainer>
            <InfoSectionTitle value="Datos de obra social" />
            <InfoItem title="Obra social" value={props.data.obraSocial} />
            <InfoItem title="Número de la tarjeta" value={props.data.numeroTarjeta} />
            <InfoItem title="Nombre de la tarjeta" value={props.data.nombreTitluar} />
            <InfoItem title="Fecha de vencimiento" value={props.data.fechaVencimiento} />
            <InfoItem title="Código de seguridad" value={props.data.codigoSeguridad} />
          </InfoSectionContainer>

          <InfoSectionContainer>
            <InfoSectionTitle value="Terapeutas con acceso" />
            <AccesosItem accesosTerapeutas={accesosTerapeutas} terapiasActivas={props.data.terapiasActivas} />
          </InfoSectionContainer>

          {props.data.responsables.length === 1 ? (
            <InfoSectionContainer>
              <InfoSectionTitle value="Datos del responsable" />
              <InfoItem title="Nombre completo" value={props.data.responsables[0].nombreCompleto} />
              <InfoItem title="Documento" value={props.data.responsables[0].documentoResponsable} />
              <InfoItem title="Email" value={props.data.responsables[0].email} />
              <InfoItem
                title="Teléfono"
                value={props.data.responsables[0].telefono1 ? props.data.responsables[0].telefono1 : "No hay un telefono registrado"}
              />
            </InfoSectionContainer>
          ) : (
            props.data.responsables.map((responsable, index) => (
              <InfoSectionContainer key={index}>
                <InfoSectionTitle value={`Datos del responsable ${index + 1}`} />
                <InfoItem title="Nombre completo" value={responsable.nombreCompleto} />
                <InfoItem title="Documento" value={responsable.documentoResponsable} />
                <InfoItem title="Email" value={responsable.email} />
                <InfoItem title="Teléfono" value={responsable.telefono1 ? responsable.telefono1 : "No hay un telefono registrado"} />
              </InfoSectionContainer>
            ))
          )}

          <InfoSectionContainer>
            <InfoSectionTitle value="Archivos del paciente" />
            <FilesItem archivos={props.data.archivos} />
          </InfoSectionContainer>
        </div>
      </div>
    </div>
  );
};

export default FeedMoreInformation;
