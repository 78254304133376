import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import styles from './backButton.module.css';
const BackButton = (props) => {
    return(
        <button className = {styles.customButton} onClick = {props.onClick}>
            <FaArrowLeft 
                color='#7f7f7f'
                size={24}
                className={styles.customSvg}

            />
        </button>
    )
};

export default BackButton; 