import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import styles from "./postMakerFiles.module.css";
import { MdInsertDriveFile, MdClose } from "react-icons/md";

const mostrarMensajeArchivos = (cantidad) => {
  if (cantidad === 1) {
    return "Has seleccionado 1 archivo";
  } else if (cantidad > 1) {
    return `Has seleccionado ${cantidad} archivos`;
  }
};

const PostMakerFiles = (props) => {
  const tooltipFiles = () => {
    let nombresArchivos = props.archivos.map((a) => a.name);
    return (
      <Tooltip id="filesTooltip">
        <ul style={{ paddingLeft: "16px" }}>
          {nombresArchivos.map((n) => (
            <li style={{ color: "#fff", textAlign: "left" }}>
              <strong>{n}</strong>
            </li>
          ))}
        </ul>
      </Tooltip>
    );
  };

  return (
    <div className={styles.filesContainer}>
      <label htmlFor="Files" className={styles.labelForFiles}>
        <MdInsertDriveFile color="#7F7F7F" size={25} style={{ cursor: "pointer" }} />
      </label>
      <input id="Files" type="file" tabIndex="0" multiple={true} onChange={props.addFiles} />
      {props.filesQty > 0 && (
        <>
          <MdClose color="red" style={{ marginLeft: "8px", cursor: "pointer" }} onClick={props.removeFiles} />
          <OverlayTrigger transition={false} placement="top" delay={{ show: "25", hide: "25" }} overlay={tooltipFiles()}>
            <p className={styles.textFiles}>{mostrarMensajeArchivos(props.filesQty)}</p>
          </OverlayTrigger>
        </>
      )}
    </div>
  );
};

export default PostMakerFiles;
