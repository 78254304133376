import React, { useState, useContext } from "react";
import styles from "./login.module.css";
import * as yup from "yup";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import FormContainer from "../Globales/formContainer/FormContainer";
import FormInput from "../Globales/formInput/FormInput";
import FormButton from "../Globales/formButton/FormButton";
import InputMessage from "../Globales/inputMessage/InputMessage";
import { loginUsuario } from "../../utils/serverRequest";
import { UserContext } from "../context/context";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SupportButton from "../Globales/supportButton/SupportButton";

let validationSchemaLogin = yup.object().shape({
  email: yup.string().email("Ingrese un correo electrónico válido").required("Este campo es requerido"),
  password: yup.string().required("Este campo es requerido"),
});

const Login = () => {
  const [serverError, setServerError] = useState(false);
  const [serverErrorDescription, setServerErrorDescription] = useState();
  const [user, setUser] = useContext(UserContext);
  let history = useHistory();
  const { handleChange, handleSubmit, values, errors, isSubmitting } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchemaLogin,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: async (values) => {
      let res = await loginUsuario(values);
      switch (res.status) {
        case 200:
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          setUser(JSON.parse(localStorage.getItem("user")));
          history.push("/panel/pacientes");
          break;
        case 400:
          setServerError(true);
          setServerErrorDescription(res.data.err);
          break;
        case 500:
          setServerError(true);
          console.log(res.data.err);
          setServerErrorDescription("Hubo un error inesperado del servidor");
          break;
        default:
          console.log(res);
          break;
      }
    },
  });

  return (
    <>
      <div className="col-lg-6 col-md-6 col-sm-9 col-xs-12 container">
        <FormContainer title="Iniciar sesión" clicked={handleSubmit}>
          <FormInput
            labelText="Correo electrónico"
            labelHtmlFor="email"
            atrName="email"
            atrId="email"
            atrType="text"
            atrValue={values.email}
            changed={handleChange}
            isRequired={true}
            hasErrors={errors.email ? true : false}
          >
            {errors.email ? <InputMessage message={errors.email} atrId="hint_email" hasErrors={errors.email ? true : false} /> : null}
          </FormInput>
          <FormInput
            labelText="Contraseña"
            labelHtmlFor="password"
            atrName="password"
            atrId="password"
            atrType="password"
            atrMinLength="8"
            atrValue={values.password}
            changed={handleChange}
            isRequired={true}
            hasErrors={errors.password ? true : false}
          >
            {errors.password ? <InputMessage message={errors.password} atrId="hint_password" hasErrors={errors.password ? true : false} /> : null}
            {serverError && <InputMessage message={serverErrorDescription} atrId="hint_sverror" hasErrors={serverError ? true : false} />}
          </FormInput>
          <Link to="/olvide-mi-contraseña" style={{ marginTop: 16, fontSize: 14 }}>
            Olvidé mi contraseña
          </Link>
          <div className={styles.buttonPosition + " col-xl-4 col-lg-6 col-12"} style={{ marginTop: "32px" }}>
            <FormButton text="INGRESAR" buttonType="submit" buttonStyle="primario" atrName="ingresar" atrDisabled={isSubmitting} />
          </div>
          <Row className="align-items-center">
            <Col md={7}>
              <p className={styles.refText}>
                ¿No tenés cuenta?{" "}
                <Link to="/registrarse">
                  <a href="#">Registrate acá</a>
                </Link>
              </p>
            </Col>
            <Col md={5}>
              <SupportButton text="¿Necesitas ayuda?" atrName="soporte" linkSoporte="https://wa.me/+34686281368" />
            </Col>
          </Row>
        </FormContainer>
      </div>
    </>
  );
};

export default Login;
