import React, { useRef } from 'react'
import styles from './notificationDropdown.module.css'
import { MdNotificationsNone } from 'react-icons/md'
import Dropdown from 'react-bootstrap/Dropdown'
import Notificacion from '../notification/notification'
import Spinner from '../Globales/spinner/spinner'
import useRootClose from 'react-overlays/useRootClose'
import EmptyState from '../Globales/emptyState/emptyState'

const NotificationDropdown = (props) => {
  const ref = useRef();
  const handleRootClose = () => props.setDropdownOpened(false);

  useRootClose(ref, handleRootClose, {
    disabled: !props.dropdownOpened,
  });

  const isAnyUnread = () => {
    return props.notifications.some(n => !n.read)
  }

  return (
    <Dropdown show={props.dropdownOpened}>
      <Dropdown.Toggle variant='none' id="dropdown-basic">
        <div id={styles.notificationContainer}>
          <MdNotificationsNone className="mr-2" id={styles.notificationItem} onClick={() => props.setDropdownOpened(!props.dropdownOpened)} size="24px" />
          {isAnyUnread() && <div id={styles.notificationBadge} style={{ height: '8px', width: '8px', borderRadius: '50%', backgroundColor: '#007BFF' }}></div>}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className={`${styles.notifDropdown} px-2`} ref={ref}>
        <div className="d-flex mb-2 mt-1 px-2 mr-auto justify-content-between align-items-center">
          <h4 className="m-0">Notificaciones</h4>
          {props.notifications.length > 0 && isAnyUnread() && <a href="#" style={{ fontSize: '14px' }} onClick={(e) => props.markAllAsRead(e)}>Marcar todas como leídas</a>}
        </div>
        <Dropdown.Divider />
        {props.notifications && props.notifications.length > 0 ? props.notifications.map((n, key) => {
          if (key === props.notifications.length - 1) {
            return <Dropdown.Item key={key} as={() => <Notificacion data={n} ref={props.lastElementRef} handleClickNotification={() => props.handleClickNotification(n)} />} />
          }
          else {
            return <Dropdown.Item key={key} as={() => <Notificacion data={n} handleClickNotification={() => props.handleClickNotification(n)} />} />
          }
        }) :
          <div style={{display: 'flex', justifyContent: 'center', height: '90%', alignItems: 'center'}}>
            <EmptyState subtitulo="Todavía no tienes notificaciones" ruta="/images/vacio.svg"/>
          </div>
        }
        {props.isLoading && <Spinner spinnerType='small' />}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default NotificationDropdown