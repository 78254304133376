import React from "react";
import "./App.css";
import { checkToken } from "../../../utils/utils";

// Componentes
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import PublicRoute from "../PublicRoute/PublicRoute";
import Login from "../../login/Login.jsx";
import SignUp from "../../signUp/SignUp.jsx";
import ForgotPassword from "../../forgotPassword/forgotPassword";
import ChangePassword from "../../forgotPassword/changePassword";
import EditarTerapias from "../../editarTerapias/editarTerapias";
import ConfirmacionSignUp from "../../Globales/ConfirmationSignUp/ConfirmationSignUp";
import DashboardPaciente from "../../dashboardPaciente/DashboardPacientesFront";
import DashboardTerapueta from "../../dashboardTerapeuta/dashboardTerapeuta.jsx";
import DashboardTerapia from "../../dashboardTerapia/dashboardTerapia.jsx";
import DashboardAprobaciones from "../../dashboardAprobaciones/dashboardAprobaciones.jsx";
import FeedPaciente from "../../feedPaciente/FeedPaciente";
import FeedTerapia from "../../feedTerapia/FeedTerapia";
import abmTerapeuta from "../../abmTerapeuta/abmTerapeuta";
import CreacionTerapias from "../../creacionTerapias/creacionTerapias";
import UserProvider from "../../context/userProvider";
import NotificationsProvider from "../../context/notificationsProvider";
import CreacionPacientes from "../../creacionPaciente/creacionPaciente";
import ModificacionPaciente from "../../modificacionPaciente/modificacionPaciente";
import PostPagePaciente from "../../postPagePaciente/PostPagePaciente";
import PostPageTerapia from "../../postPageTerapia/PostPageTerapia";
import NotificationsPage from "../../notificationsPage/notificationsPage";
import DashboardAdministradores from "../../dashboardAdministradores/dashboardAdministradores";

// Router
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

let history = createBrowserHistory();

//
const App = () => {
  return (
    <UserProvider>
      <NotificationsProvider>
        <BrowserRouter history={history}>
          <Switch>
            {/* Rutas Publicas */}
            <PublicRoute component={Login} path="/ingresar" exact />
            <PublicRoute component={ForgotPassword} path="/olvide-mi-contraseña" />
            <PublicRoute component={ChangePassword} path="/cambiar-contraseña" />
            <PublicRoute component={SignUp} path="/registrarse" exact />
            <PublicRoute component={ConfirmacionSignUp} path="/registrarse/confirmacion" exact />
            {/* Rutas Privadas */}

            <PrivateRoute component={DashboardAdministradores} path="/panel/administradores" exact />
            <PrivateRoute component={DashboardPaciente} path="/panel/pacientes" exact />
            <PrivateRoute component={DashboardTerapia} path="/panel/terapias" exact />
            <PrivateRoute component={DashboardTerapueta} path="/panel/terapeutas" exact />
            <PrivateRoute component={DashboardAprobaciones} path="/panel/aprobaciones" exact />
            <PrivateRoute component={abmTerapeuta} path="/panel/terapeutas/creacion" exact />
            <PrivateRoute component={CreacionTerapias} path="/panel/terapias/creacion" exact />
            <PrivateRoute component={EditarTerapias} path="/panel/terapias/:id" exact />
            <PrivateRoute component={CreacionPacientes} path="/panel/pacientes/creacion" exact />
            <PrivateRoute component={ModificacionPaciente} path="/panel/pacientes/modificacion" exact />

            <PrivateRoute component={FeedPaciente} path="/panel/pacientes/perfil/:id" exact />

            <PrivateRoute component={FeedTerapia} path="/panel/terapias/perfil/:id" exact />
            <PrivateRoute component={PostPagePaciente} path="/panel/pacientes/perfil/:pacienteId/post/:postId" exact />
            <PrivateRoute component={PostPageTerapia} path="/panel/terapias/perfil/:terapiaId/post/:postId" exact />
            <PrivateRoute component={NotificationsPage} path="/panel/notifications" exact />
            {checkToken() ? <Redirect from="/" to="/panel/pacientes" /> : <Redirect from="/" to="/ingresar" />}
          </Switch>
        </BrowserRouter>
      </NotificationsProvider>
    </UserProvider>
  );
};

export default App;
