//Packages, Utils & Server Requests
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/context";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { aprobarSolicitudAcceso, desaprobarSolicitudAcceso, getAdminList, updateUser } from "../../utils/serverRequest";
import styles from "./dashboardAdministradores.module.css";

// Components
import DashboardTitle from "../dashboardTitle/dashboardTitle";
import Grilla from "../Globales/grilla/grilla.jsx";
import Avatar from "../Globales/avatar/avatar.jsx";
import Paginacion from "../Globales/paginacion/paginacion.jsx";
import Spinner from "../Globales/spinner/spinner.jsx";
import ModalPropio from "../Globales/modal/modalPropio";
import Alert from "../Globales/alertPropia/alertPropia";
import EmptyState from "../Globales/emptyState/emptyState.jsx";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Buscador from "../Globales/buscador/buscador";

const DashboardAdministradores = () => {
  // State
  const [administradores, setAdministradores] = useState([]);
  const [valorBusqueda, setValorBusqueda] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [isLoading, setSpinner] = useState(true);
  const [disableUserModal, setdisableModalUser] = useState(false);
  const [enableUserModal, setenableModalUser] = useState(false);
  const [selectedUser, setselectedUser] = useState("");
  const [successAlert, setsuccessAlert] = useState({ show: false, message: "" });
  const [errorAlert, seterrorAlert] = useState({ show: false, message: "" });
  const [isSubmitting, setSubmit] = useState(false);

  // Context
  const [user, setUser] = useContext(UserContext);
  const rolUsuario = user.rol;

  // Service call
  useEffect(() => {
    const getAdmins = async () => {
      let res = await getAdminList();
      if (res.status === 200) {
        setSpinner(false);
        setAdministradores(res.data.admins);
      } else if (res.status === 401) {
        setSpinner(false);
        alert("No estás autorizado para ver ésta página");
      } else {
        setSpinner(false);
        alert("Error al importar los administradores");
      }
    };
    getAdmins();
  }, []);
  const cantidadPaginas = Math.ceil(administradores.length / 12);

  // Filas de usuario
  let adminRows = administradores
    .filter((admin) => admin.nombreCompleto.toLowerCase().indexOf(valorBusqueda.toLowerCase()) !== -1)
    .slice((paginaActual - 1) * 12, paginaActual * 12)
    .map((admin, key) => (
      <tr key={key} style={{ verticalAlign: "middle" }}>
        <th scope="row">
          <Avatar rutaAvatar={admin.avatar} />
        </th>
        <td>{admin.nombreCompleto}</td>
        <td>{admin.email}</td>
        <td>{admin.dni}</td>
        <td>{admin.rol}</td>
        {user.rol === "Superadmin" && <td>{admin.bajaLogica ? "Deshabilitado" : "Habilitado"}</td>}
        {user.rol === "Superadmin" && (
          <td>
            {!admin.bajaLogica ? (
              <OverlayTrigger placement="top" overlay={<Tooltip>Dar de baja</Tooltip>}>
                <button className={styles.customButton}>
                  <MdCancel
                    color="#a3d4ff"
                    size={25}
                    className={`${styles.customSvg} ${styles.customSvgDisapprove}`}
                    onClick={() => handleDisableUser(admin)}
                  />
                </button>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger placement="top" overlay={<Tooltip>Habilitar</Tooltip>}>
                <button className={styles.customButton}>
                  <MdCheckCircle
                    color="#a3d4ff"
                    size={25}
                    className={`${styles.customSvg} ${styles.customSvgApprove}`}
                    onClick={() => handleEnableUser(admin)}
                  />
                </button>
              </OverlayTrigger>
            )}
          </td>
        )}
      </tr>
    ));

  let arrayFilas = adminRows;

  // Funciones para APROBAR una solicitud - USUARIO

  const handleDisableUser = (user) => {
    setselectedUser(user);
    setdisableModalUser(true);
  };

  const handleEnableUser = (user) => {
    setselectedUser(user);
    setenableModalUser(true);
  };

  const disableUser = async () => {
    setSubmit(true);

    const res = await updateUser({ bajaLogica: true }, selectedUser._id);

    if (res.status === 200) {
      setdisableModalUser(false);
      setsuccessAlert({
        show: true,
        message: "Se ha dado de baja al usuario correctamente",
      });

      setTimeout(() => setsuccessAlert({ show: false, message: "" }), 3000);
      const updatedAdministradores = administradores.map((adm) => {
        if (adm._id === selectedUser._id) {
          return {
            ...adm,
            bajaLogica: res.data.user.bajaLogica,
          };
        } else {
          return adm;
        }
      });

      setAdministradores(updatedAdministradores);
      setselectedUser("");
    } else {
      seterrorAlert({ show: true, message: "Error al dar de baja el usuario" });
      setTimeout(() => seterrorAlert({ show: false, message: "" }), 3000);
    }
    setSubmit(false);
  };

  const enableUser = async () => {
    setSubmit(true);
    const res = await updateUser({ bajaLogica: false }, selectedUser._id);

    if (res.status === 200) {
      setenableModalUser(false);
      setsuccessAlert({
        show: true,
        message: "Se ha habilitado correctamente al usuario",
      });
      setTimeout(() => setsuccessAlert({ show: false, message: "" }), 3000);
      const updatedAdministradores = administradores.map((adm) => {
        if (adm._id === selectedUser._id) {
          return {
            ...adm,
            bajaLogica: res.data.user.bajaLogica,
          };
        } else {
          return adm;
        }
      });

      setAdministradores(updatedAdministradores);
      setselectedUser("");
    } else {
      seterrorAlert({ show: true, message: "Error al habilitar el usuario" });
      setTimeout(() => seterrorAlert({ show: false, message: "" }), 3000);
    }
    setSubmit(false);
  };

  const actualizarValorBusqueda = (e) => {
    setValorBusqueda(e.target.value);
    setPaginaActual(1);
  };

  // Render

  return (
    <div className="dashboardContainer">
      <DashboardTitle title="Administradores" />

      {successAlert.show && (
        <Alert variant="success" show={successAlert.show}>
          <strong>Operación exitosa.</strong> {successAlert.message}
        </Alert>
      )}

      {errorAlert.show && (
        <Alert variant="danger" show={errorAlert.show}>
          <strong>Operación fallida.</strong> {errorAlert.message}
        </Alert>
      )}

      {isLoading ? (
        <Spinner />
      ) : administradores.length === 0 ? (
        <EmptyState ruta="/images/undraw.svg" titulo="No hay administradores." />
      ) : (
        <>
          <Row className={styles.searchContainer}>
            <Col xs={12} md={7} className={styles.searchBoxCon}>
              <Buscador placeholder="Buscar administradores por nombre o apellido..." handler={actualizarValorBusqueda} />
            </Col>
          </Row>

          <Grilla
            titulos={
              user.rol === "Superadmin"
                ? ["Avatar", "Nombre completo", "Correo electrónico", "Documento", "Rol", "Estado", "Acciones"]
                : ["Avatar", "Nombre completo", "Correo electrónico", "Documento", "Rol"]
            }
            filas={arrayFilas}
          />
          {adminRows.length !== 0 && <Paginacion cantidad={cantidadPaginas} paginaActual={paginaActual} handler={setPaginaActual} />}
        </>
      )}

      {disableUserModal && (
        <ModalPropio
          title="Deshabilitar usuario"
          content={`¿Está seguro que quiere dar de baja a ${selectedUser.nombreCompleto}?`}
          isOpen={disableUserModal}
          closeModal={setdisableModalUser}
          okHandler={disableUser}
          isSubmitting={isSubmitting}
          botonPrimario="Deshabilitar"
          botonSecundario="Cancelar"
        />
      )}

      {enableUserModal && (
        <ModalPropio
          title="Habilitar usuario"
          content={`¿Está seguro que quiere habilitar a ${selectedUser.nombreCompleto}?`}
          isOpen={enableUserModal}
          closeModal={setenableModalUser}
          okHandler={enableUser}
          isSubmitting={isSubmitting}
          botonPrimario="Habilitar"
          botonSecundario="Cancelar"
        />
      )}
    </div>
  );
};

export default DashboardAdministradores;
