import React, {useState, useEffect} from 'react'
import styles from './reaccionesDadas.module.css'
import {Twemoji} from 'react-emoji-render';
import _ from 'underscore'
import CustomOverlay from './customOverlay'

// Props.reacciones es un objeto que tiene una key por tipo de reacción o Emoji,
// y cada key es un array con el objeto reacción

 // [ [':smile:', [ObjetoReaccion]], [':+1:', [ObjetoReaccion]]]


 
 const ReaccionesDadas = (props) => {
     
     
     const handleClick = (e) => {
         e.preventDefault()
         props.handleReactionModal()
     }
 
 
    return (
        <div className={`${props.className}`}>
            <div className="d-none d-sm-inline-block mr-2 position-relative">
            {props.reacciones.map((grupoReaccion, index) => (
            <CustomOverlay key={index} customKey={index} autores={grupoReaccion[1].map((g, key) => <p key={key}>{g.user.nombreCompleto}</p>)}>
                <div key={index} className={styles.reaccionContainer}>
                    <Twemoji text={grupoReaccion[0]} onlyEmojiClassName={styles.reaccionEmoji}/>
                </div>
            </CustomOverlay>
                ))}
            </div>
                <a href="#" onClick={props.handleReactionModal} style={{fontSize: '14px', marginLeft: '-4px'}}>{props.cantidadReacciones === 1 ? '1 Reacción' : `${props.cantidadReacciones} reacciones`}</a>
        </div>
        )

}

export default ReaccionesDadas