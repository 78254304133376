import React, { useState, useEffect } from 'react';
import styles from '../feedMoreInformation/feedMoreInformation.module.css';
import Spinner from '../spinner/spinner'
import { obtenerInfoTerapia } from '../../../utils/serverRequest';
import Avatar from '../avatar/avatar.jsx';

const TerapiaMoreInformation = props => {
    const [isLoading, setSpinner] = useState(false)
    const [dataTerapia, setDataTerapia] = useState({
        terapeutasActivos: props.data.terapeutasActivos,
        pacientesActivos: props.data.pacientesActivos
    })
    // useEffect(() => {
    //     let infoTerapia = async () => {
    //         setSpinner(true);
    //         let data = await obtenerInfoTerapia('5e665b78061ce11a244e3cc2')
    //         if (data.ok){
    //             setSpinner(false);
    //             setDataTerapia({
    //                 terapeutasActivos: data.terapia.terapeutas,
    //                 pacientesActivos: data.terapia.pacientes
    //             })
    //         }
    //     };
    //         infoTerapia();
    //       }, []);
        //manejar el tema de cuando un terapeuta/paciente viene sin avatar de perfil
    return(
        <div className={styles.box}>
        {isLoading ? <Spinner /> : 
        <div className = {styles.marginBox}>
            <div className="d-block mb-5">
                    <h5 className={`${styles.subTitulo} mb-4 text-align-left`}>Terapeutas con esta terapia asignada</h5>
                    {dataTerapia.terapeutasActivos.map((terapeuta, key)=>(
                    <div key={key} className='row d-flex' style={{alignItems: 'center', padding:'1%'}}>
                        <div className="mr-3"><Avatar rutaAvatar = {terapeuta.avatar ? terapeuta.avatar : 's\images\avatarVacio.jfif'} /></div>
                        <p className="m-0"> {terapeuta.nombreCompleto}</p>
                    </div>
                    ))}
                </div>
                <div className="d-block">
                    <h5 className={`${styles.subTitulo} mb-4 text-align-left`}> Pacientes con esta terapia asignada</h5>
                    {dataTerapia.pacientesActivos.map((paciente,key)=>(
                    <div key={key} className='row d-flex' style={{alignItems: 'center', padding:'1%'}}>
                        <div className="mr-3"><Avatar rutaAvatar = {paciente.avatar ? paciente.avatar : 's\images\avatarVacio.jfif'} /></div>
                        <p className="m-0"> {paciente.nombreCompleto}</p>
                    </div>
                    ))} 
                </div>
        </div>
        }
        </div>
    )
};

export default TerapiaMoreInformation;