import React, { useEffect, useState, useRef, useCallback, useContext } from 'react'
import Notification from '../notification/notification'
import io from 'socket.io-client'
import { useHistory } from 'react-router-dom';
import { getNotifications, markNotificationRead, markNotificationsRead } from '../../utils/serverRequest'
import BackButton from '../Globales/backButton/BackButton'
import DashboardTitle from '../dashboardTitle/dashboardTitle'
import Spinner from '../Globales/spinner/spinner'
import {NotificationContext} from '../context/context'
import EmptyState from '../Globales/emptyState/emptyState'

const NotificationsPage = (props) => {

    const {checkUnreadNotif, unreadNotif} = useContext(NotificationContext)
    const [checkUnreadNotifValue, setCheckUnreadNotif] = checkUnreadNotif
    const [unreadNotification, setUnreadNotification] = unreadNotif

    const [notifications, setNotifications] = useState([])
    const [actualPage, setActualPage] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    var socket
    let history = useHistory()

    // Infinite Scroll
    const observer = useRef()
    const lastElementRef = useCallback(node => {
        if (isLoading) {
            return
        }
        if (observer.current) {
            observer.current.disconnect()
        }
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && actualPage + 1 < pageCount) {
                setActualPage(actualPage + 1)
            }
        })
        if (node) {
            observer.current.observe(node)
        }
    }, [pageCount, isLoading])

    // Busca las 1ras 20 notificaciones
    useEffect(() => {

        let getNotifs = async () => {
            setIsLoading(true)
            let res = await getNotifications(actualPage)
            if (res.status === 200) {
                let notifsToAdd = res.data.notifications
                let count = res.data.count
                setNotifications([...notifications, ...notifsToAdd])
                setPageCount(count)
            }
            else {
                console.log('Error al buscar las nuevas notificaciones')
            }
            setIsLoading(false)
        }

        getNotifs()

    }, [actualPage])

    // Inicializo sockets
    useEffect(() => {
        let userInfo = JSON.parse(localStorage.getItem('user'))

        socket = io({
            query: {
                ...userInfo
            }
        })

        socket.on('paciente-post', (data) => {
            handleNewNotification(data)
        })

        socket.on('paciente-comment', (data) => {
            handleNewNotification(data)
        })

        return () => socket.close()

    }, [])

    const handleNewNotification = (newNotif) => {
        setUnreadNotification(true)
        setNotifications(prevState => ([newNotif, ...prevState.slice(0, prevState.length - 1)])) // Para que no quede desactualizado el estado en el useEffect
    }

    const handleClickNotification = async (notif) => {
        history.push(notif.link)

        if (!notif.read) {
            let res = await markNotificationRead(notif.id)

            if (res.status === 200) {
                setCheckUnreadNotif(checkUnreadNotifValue + 1)
                let newNotifications = notifications.map(n => {
                    return {
                        ...n,
                        read: n.id === notif.id ? true : n.read
                    }
                })
                setNotifications(newNotifications)
            }
            else {
                alert('Error al marcar como leida la notif')
            }
        }
    }

    const markAllAsRead = async (e) => {
        e.preventDefault()
        if (notifications.some(n => !n.read)) {

            let actualNotifications = [...notifications]
            let readNotifications = actualNotifications.map(n => {
                return {
                    ...n,
                    read: true
                }
            })

            setNotifications(readNotifications)

            let res = await markNotificationsRead()

            if (res.status !== 200) {
                setNotifications(actualNotifications)
                alert('Error al marcar las notificaciones como leídas')
            }
            setUnreadNotification(false)
        }
    }

    const isAnyUnread = () => {
        return notifications.some(n => !n.read)
      }    

    return (
        <div className='dashboardContainer'>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <BackButton onClick={() => history.goBack()} />
                <DashboardTitle title='Notificaciones' />
            </div>

            {notifications && notifications.length > 0 && isAnyUnread() && <a className="d-block mr-auto mb-2" href="#" style={{ fontSize: '14px', textAlign: 'left' }} onClick={(e) => markAllAsRead(e)}>Marcar todas como leídas</a>}

            {!isLoading && notifications && notifications.length > 0 ? notifications.map((n, idx) => {
                if (idx === notifications.length - 1) {
                    return <Notification key={idx} data={n} ref={lastElementRef} handleClickNotification={() => handleClickNotification(n)} />
                }
                else {
                    return <Notification key={idx} data={n} handleClickNotification={() => handleClickNotification(n)} />
                }
            })
            : !isLoading && <div className="d-flex align-items-center justify-content-center" style={{height: '80%'}}>
                <EmptyState ruta="/images/vacio.svg" titulo="Todavía no tienes notificaciones"/>
            </div>
            }
            {isLoading && <div className="d-block"><Spinner spinnerType='small' /></div>}
        </div>
    )
}

export default NotificationsPage