import React, {useState, useEffect} from 'react'
import {NotificationContext} from './context';
import {getUnreadNotification} from '../../utils/serverRequest'

const NotificationProvider = props => {
    const [unreadNotification, setUnreadNotification] = useState(false)
    const [checkUnreadNotif, setCheckUnreadNotif] = useState(0)

    useEffect(() => {

        let anyUnreadNotif = async () => {
            let res = await getUnreadNotification()

            if(res.status === 200) {
                setUnreadNotification(res.data.unread)
            }
            else {
                // alert('Error al recibir una notificación')
            }
        }

        anyUnreadNotif()
    }, [checkUnreadNotif])

    return (
        <NotificationContext.Provider value={{unreadNotif: [unreadNotification, setUnreadNotification], checkUnreadNotif: [checkUnreadNotif, setCheckUnreadNotif] }}>
            {props.children}
        </NotificationContext.Provider>
    )
}

export default NotificationProvider;

