//Packages & Utils
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/context";

// Components
import DashboardTitle from "../dashboardTitle/dashboardTitle";
import PostContainer from "../Globales/postContainer/postContainer";
import BoxInfoFeedPaciente from "../Globales/boxInfoFeedPaciente/BoxInfoFeedPaciente";
import FeedMoreInformation from "../Globales/feedMoreInformation/FeedMoreInformation";
import Spinner from "../Globales/spinner/spinner";
import BackButton from "../Globales/backButton/BackButton";
import EmptyState from "../Globales/emptyState/emptyState";
import { obtenerInfoPacientes } from "../../utils/serverRequest";
import { Animated } from "react-animated-css";

const FeedPaciente = (props) => {
  const [dataPaciente, setDataPaciente] = useState({
    nombreCompleto: "",
    avatar: "",
    fechaNacimiento: "",
    dni: "",
    id: "",
    obraSocial: "",
    nacionalidad: "",
    genero: "",
    domicilio: "",
    localidad: "",
    codigoPostal: "",
    telefono1: "",
    telefono2: "",
    estaEscolarizado: "",
    informacionEscolar: "",
    obraSocial: "",
    nombreTitluar: "",
    numeroTarjeta: "",
    fechaVencimiento: "",
    codigoSeguridad: "",
    terapiasActivas: [],
    responsables: [],
    responsableNombre: "",
    responsableTelefono: "",
    terapiasTerapeutaEscritura: [],
    terapiasTerapeutaLectura: [],
    terapeutasAsignados: [],
    archivos: [],
    accesos: {},
    creadoresDePosts: [],
  });
  const idPaciente = props.match.params.id;
  const [pageSeleccionada, setPageSeleccionada] = useState("foro");
  const [terapiaSeleccionada, setTerapiaSeleccionada] = useState("0");
  const [isLoading, setSpinner] = useState(true); // change to true when integrate with backend

  // Context
  const [user, setUser] = useContext(UserContext);
  const rolUsuario = user.rol;
  const emailUsuario = user.email;

  useEffect(() => {
    const informacionDePacientes = async () => {
      setSpinner(true);
      let data = await obtenerInfoPacientes(idPaciente);

      if (data.ok) {
        let nuevoPaciente = {
          nombreCompleto: data.paciente.nombreCompleto,
          id: data.paciente._id,
          avatar: data.paciente.avatar || "",
          dni: data.paciente.dni,
          obraSocial: data.paciente.obraSocial,
          nacionalidad: data.paciente.nacionalidad,
          genero: data.paciente.genero,
          domicilio: data.paciente.domicilio,
          localidad: data.paciente.localidad,
          codigoPostal: data.paciente.codigoPostal,
          telefono1: data.paciente.telefono1,
          telefono2: data.paciente.telefono2,
          estaEscolarizado: data.paciente.estaEscolarizado,
          informacionEscolar: data.paciente.informacionEscolar,
          obraSocial: data.paciente.obraSocial,
          nombreTitluar: data.paciente.nombreTitluar,
          numeroTarjeta: data.paciente.numeroTarjeta,
          fechaVencimiento: data.paciente.fechaVencimiento,
          codigoSeguridad: data.paciente.codigoSeguridad,
          fechaNacimiento: data.paciente.fechaNacimiento,
          responsables: data.paciente.responsables,
          responsableNombre: data.paciente.responsable.nombreCompleto,
          responsableTelefono: data.paciente.responsable.telefono1,
          terapiasActivas: data.paciente.terapias,
          terapiasTerapeutaEscritura: data.paciente.terapiasTerapeutaEscritura,
          terapiasTerapeutaLectura: data.paciente.terapiasTerapeutaLectura,
          archivos: data.paciente.archivos,
          accesos: data.paciente.accesos,
          terapeutasAsignados: data.paciente.terapeutasAsignados,
          creadoresDePosts: data.paciente.creadoresDePosts,
        };
        setDataPaciente(nuevoPaciente);
      } else {
        alert("Error al importar los pacientes");
      }
      setSpinner(false);
    };
    informacionDePacientes();
  }, []);

  const handleClickFilter = (terapia) => {
    let terapiaSelec = terapia;
    setTerapiaSeleccionada(terapiaSelec);
  };

  const handlePage = (id_page) => {
    let idPage = id_page;
    setPageSeleccionada(idPage);
  };

  //funcion para el backButton
  const goBack = () => {
    props.history.push({
      pathname: "/panel/pacientes",
    });
  };

  return (
    <div className="dashboardContainer">
      <Animated animationIn="fadeIn">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <BackButton onClick={goBack} />
          <DashboardTitle title="Perfil de paciente" />
        </div>
      </Animated>
      {isLoading ? (
        <Spinner />
      ) : (
        <Animated animationIn="fadeIn">
          <>
            {rolUsuario === "Terapeuta" &&
            dataPaciente.terapiasTerapeutaEscritura.length === 0 &&
            dataPaciente.terapiasTerapeutaLectura.length === 0 &&
            emailUsuario !== "giselabisetto@yahoo.com.ar" ? (
              <div style={{ marginTop: "56px" }}>
                <EmptyState
                  ruta="/images/acceso_denegado.svg"
                  subtitulo={`No tenés acceso a ver el perfil del paciente ${dataPaciente.nombreCompleto}`}
                />
              </div>
            ) : (
              <>
                <div className="col-xl-9 col-12 container" style={{ padding: "0px 0px 0px 0px" }}>
                  <BoxInfoFeedPaciente
                    avatarPaciente={dataPaciente.avatar}
                    nombrePaciente={dataPaciente.nombreCompleto}
                    terapiasTerapeutaEscritura={dataPaciente.terapiasTerapeutaEscritura}
                    terapiasTerapeutaLectura={dataPaciente.terapiasTerapeutaLectura}
                    clicked={handlePage}
                    paginaSeleccionada={pageSeleccionada}
                  />
                </div>
                {pageSeleccionada === "foro" ? (
                  <div className="col-xl-9 col-12 container" style={{ padding: "0px 0px 0px 0px" }}>
                    <PostContainer
                      feed="paciente"
                      ruta="paciente"
                      terapeutasAsignados={dataPaciente.terapeutasAsignados}
                      terapiasTerapeutaEscritura={dataPaciente.terapiasTerapeutaEscritura}
                      terapiasTerapeutaLectura={dataPaciente.terapiasTerapeutaLectura}
                      nombre={dataPaciente.nombreCompleto}
                      terapiasActivas={dataPaciente.terapiasActivas}
                      displayFilters={true}
                      idPaciente={idPaciente}
                      idTerapia={
                        rolUsuario === "Superadmin" || emailUsuario === "giselabisetto@yahoo.com.ar"
                          ? dataPaciente.terapiasActivas.length > 0
                            ? dataPaciente.terapiasActivas[0]._id
                            : ""
                          : dataPaciente.terapiasTerapeutaEscritura.length > 0
                          ? dataPaciente.terapiasTerapeutaEscritura[0]._id
                          : ""
                      }
                      tseleccionada={terapiaSeleccionada}
                      clickedFilter={handleClickFilter}
                      creadoresDePosts={dataPaciente.creadoresDePosts}
                    />
                  </div>
                ) : (
                  <div className="col-xl-9 col-12 container" style={{ padding: "0px 0px 0px 0px" }}>
                    <Animated animationIn="fadeIn">
                      <FeedMoreInformation data={dataPaciente} />
                    </Animated>
                  </div>
                )}
              </>
            )}
          </>
        </Animated>
      )}
      {/* <ScrollTopButton /> */}
    </div>
  );
};

export default FeedPaciente;
