//Packages & Utils
import React, { useState, useEffect } from 'react';
// import styles from './feedTerapia.module.css';
// import { useHistory } from 'react-router-dom';
// Components
import DashboardTitle from '../dashboardTitle/dashboardTitle';
import BoxInfoFeedTerapia from '../Globales/boxInfoFeedTerapia/BoxInfoFeedTerapia';
import TerapiaMoreInformation from '../Globales/terapiaMoreInformation/terapiaMoreInformation';
import Spinner from '../Globales/spinner/spinner';
import EmptyState from '../Globales/emptyState/emptyState';
import BackButton from '../Globales/backButton/BackButton';
import { obtenerInfoTerapia } from '../../utils/serverRequest';
import PostContainer from '../Globales/postContainer/postContainer';
import { Animated } from 'react-animated-css'

// Var

const FeedTerapia = props => {
  const [isLoading, setSpinner] = useState(true);
  const [dataTerapia, setDataTerapia] = useState({
    terapeutasActivos: [],
    pacientesActivos: [],
    terapiaId: '',
    terapiaDescripcion: '',
    terapiaTitulo: '',
    colorHex: '',
    creadoresDePosts: []
  });
  const [tieneAcceso, setTieneAcceso] = useState();
  const [pageSeleccionada, setPageSeleccionada] = useState('foro');

  // let history = useHistory();

  useEffect(() => {
    const infoTerapia = async () => {
      let data = await obtenerInfoTerapia(props.match.params.id);
      if (data.ok) {
        setTieneAcceso(data.tieneAcceso);
        setDataTerapia({
          ...dataTerapia,
          terapeutasActivos: data.terapia.terapeutas,
          pacientesActivos: data.terapia.pacientes,
          terapiaId: data.terapia._id,
          terapiaDescripcion: data.terapia.descripcion,
          terapiaTitulo: data.terapia.titulo,
          colorHex: data.terapia.colorHex,
          possibleMentions: data.terapia.possibleMentions,
          creadoresDePosts: data.terapia.creadoresDePosts
        });
        setSpinner(false);
      } else {
        alert('Error al obtener la información de la terapia');
      }
    };
    infoTerapia();
  }, []);

  const handlePage = id_page => {
    let idPage = id_page;
    setPageSeleccionada(idPage);
  };
  //funcion para el backButton
  const goBack = () => {
    props.history.push({
      pathname: `/panel/terapias/`
    });
  };

  return (
    <div className='dashboardContainer'>
      <Animated animationIn="fadeIn">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <BackButton onClick={goBack} />
          <DashboardTitle title='Perfil de la terapia' />
        </div>
      </Animated>
      {isLoading ? (
        <Spinner />
      ) : (
          <>
            {!tieneAcceso ? (
              <div style={{ marginTop: '56px' }}>
                <EmptyState
                  ruta='/images/acceso_denegado.svg'
                  subtitulo={`No tenés acceso a ver el perfil de la terapia ${dataTerapia.terapiaTitulo}`}
                />
              </div>
            ) : (
                <>
                  <div
                    className='col-xl-9 col-12 container'
                    style={{ padding: '0px 0px 0px 0px' }}
                  >
                    <Animated animationIn="fadeIn">
                      <BoxInfoFeedTerapia
                        tituloTerapia={dataTerapia.terapiaTitulo}
                        descripcionTerapia={dataTerapia.terapiaDescripcion}
                        clicked={handlePage}
                        paginaSeleccionada={pageSeleccionada}
                        colorHex={dataTerapia.colorHex}
                      />
                    </Animated>
                  </div>
                  {pageSeleccionada === 'foro' ? (
                    <div
                      className='col-xl-9 col-12 container'
                      style={{ padding: '0px 0px 0px 0px' }}
                    >
                      <PostContainer
                        feed='terapia'
                        nombre={dataTerapia.terapiaTitulo}
                        idTerapia={dataTerapia.terapiaId}
                        colorHex={dataTerapia.colorHex}
                        displayFilters={false}
                        ruta='terapia'
                        possibleMentions={dataTerapia.possibleMentions}
                        creadoresDePosts={dataTerapia.creadoresDePosts}
                      />
                    </div>
                  ) : (
                      <div
                        className='col-xl-9 col-12 container'
                        style={{ padding: '0px 0px 0px 0px' }}
                      >
                        <Animated animationIn="fadeIn">
                          <TerapiaMoreInformation data={dataTerapia} />
                        </Animated>
                      </div>
                    )}
                </>
              )}
          </>
        )}
    </div>
  );
};

export default FeedTerapia;
