import React from 'react'
import styles from './filtroButton.module.css'
import {FaFilter} from 'react-icons/fa'

const FiltroButton = (props) => {

    return (
        <div className={`${styles.filtroButton}`} onClick={props.onClick}>
            <FaFilter size={props.size} color={props.color} className="mr-2"/>
            FILTRAR
        </div>
    )
}

export default FiltroButton;