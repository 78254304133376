import React from 'react';
import styles from './dashboardTitle.module.css';

const DashboardTitle = props => {
  return (
    <div className={styles.dashboardTitleContainer}>
      <h3 className={styles.dashboardTitle + ' colorFuentePrincipal'}>
        {props.title}
      </h3>
    </div>
  );
};

export default DashboardTitle;
