import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import styles from "./login.module.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import FormContainer from "../Globales/formContainer/FormContainer";
import FormInput from "../Globales/formInput/FormInput";
import FormButton from "../Globales/formButton/FormButton";
import InputMessage from "../Globales/inputMessage/InputMessage";
import { changePassword, loginUsuario, verificarTokenUsuario, verifyChangePasswordToken } from "../../utils/serverRequest";
import { UserContext } from "../context/context";
import qs from "qs";
import ProcessMessage from "./processMessage";

const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

const ChangePassword = (props) => {
  const [userId, setuserId] = useState("");
  const [serverError, setServerError] = useState(false);
  const [serverErrorDescription, setServerErrorDescription] = useState();
  const [token, settoken] = useState("");
  const [badToken, setbadToken] = useState(false);
  const [newPassword, setnewPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [isSubmitting, setisSubmitting] = useState(true);
  const [user, setUser] = useContext(UserContext);
  let history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    checkToken(query.token);
    if (!query.userId) {
      setbadToken(true);
    } else {
      setuserId(query.userId);
    }
  }, []);

  const checkToken = async (token) => {
    const res = await verifyChangePasswordToken(token);

    if (res.status === 200) {
      settoken(token);
    } else {
      setbadToken(true);
    }
    setisSubmitting(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await changePassword(newPassword, userId, token);

    if (res.status === 200) {
      localStorage.clear();
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("user", JSON.stringify(res.data.user));
      setUser(JSON.parse(localStorage.getItem("user")));
      history.push("/panel/pacientes");
    } else {
      setServerErrorDescription(res.data.error);
      setServerError(true);
    }
  };

  const isPasswordValid = () => {
    return passwordRegex.test(newPassword);
  };

  return (
    <div className="col-lg-6 col-md-6 col-sm-9 col-xs-12 container">
      {!isSubmitting && badToken ? (
        <ProcessMessage imageSrc="/images/acceso_denegado.svg" message="Éste link ha vencido." />
      ) : (
        !isSubmitting && (
          <FormContainer title="Cambiar contraseña" clicked={handleSubmit}>
            <FormInput
              labelText="Nueva contraseña"
              labelHtmlFor="Nueva contraseña"
              atrName="Nueva contraseña"
              atrId="Nueva contraseña"
              atrType="password"
              atrValue={newPassword}
              changed={(e) => setnewPassword(e.target.value)}
              isRequired={true}
              hasErrors={newPassword && !isPasswordValid(newPassword)}
            >
              {newPassword && !isPasswordValid(newPassword) && (
                <InputMessage
                  message="La contraseña deberá tener al menos 8 caracteres, 1 mayúscula y 1 número"
                  atrId="Nueva contraseña"
                  hasErrors={newPassword && !isPasswordValid(newPassword)}
                />
              )}
            </FormInput>
            {serverError && <InputMessage message={serverErrorDescription} atrId="hint_sverror" hasErrors={serverError ? true : false} />}
            <FormInput
              labelText="Confirmar contraseña"
              labelHtmlFor="Confirmar contraseña"
              atrName="Confirmar contraseña"
              atrId="Confirmar contraseña"
              atrType="password"
              atrValue={confirmPassword}
              changed={(e) => setconfirmPassword(e.target.value)}
              isRequired={true}
              hasErrors={confirmPassword && confirmPassword !== newPassword}
            >
              {confirmPassword && confirmPassword !== newPassword && (
                <InputMessage
                  message="Las contraseñas no son idénticas"
                  atrId="Confirmar contraseña"
                  hasErrors={confirmPassword && confirmPassword !== newPassword}
                />
              )}
            </FormInput>

            <div className={styles.buttonPosition + " col-xl-4 col-lg-6 col-12"} style={{ marginTop: "32px" }}>
              <FormButton text="CAMBIAR" buttonType="submit" buttonStyle="primario" atrName="ingresar" atrDisabled={isSubmitting} />
            </div>
          </FormContainer>
        )
      )}
    </div>
  );
};

ChangePassword.propTypes = {};

export default ChangePassword;
