import React, {useState, useEffect} from 'react'
import styles from './filtrosPost.module.css'
import DropdownPuroTerapeuta from '../Globales/dropdownPuroTerapeuta/dropdownPuroTerapeuta'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import CustomDatePicker from '../Globales/customDatePicker/customDatePicker';

const FiltrosPost = (props) => {

    const addTerapeuta = (terapeutaId) => {
        let nuevosTerapeutasSeleccionados = [...props.terapeutasSeleccionados, props.terapeutasIniciales.find(t => t._id === terapeutaId)]
        props.setTerapeutasSeleccionados(nuevosTerapeutasSeleccionados)
        props.setCambioFiltro(true)
    }

    const removerTerapeuta = (terapeutaId) => {
        let nuevosTerapeutasSeleccionados = props.terapeutasSeleccionados.filter(t => t._id !== terapeutaId)
        props.setTerapeutasSeleccionados(nuevosTerapeutasSeleccionados)
        props.setCambioFiltro(true)
    }

    return (
        <Container>
            <DropdownPuroTerapeuta
                list={props.terapeutasIniciales}
                terapeutasSeleccionados={props.terapeutasSeleccionados}
                addItems={addTerapeuta}
                removeItem={removerTerapeuta}
                buttonTitle='SELECCIONAR'
            />
            <Row>
                <Col xs={12} md={6}>
                    <CustomDatePicker setCambioFiltro={props.setCambioFiltro} date={props.fechaMin} setDate={props.setFechaMin} inputLabel="Desde" inputReadOnly={true} iconColor='#167bff'/>
                </Col>
                <Col xs={12} md={6}>
                    <CustomDatePicker setCambioFiltro={props.setCambioFiltro} date={props.fechaMax} setDate={props.setFechaMax} inputLabel="Hasta" inputReadOnly={true} iconColor='#167bff'/>
                </Col>
            </Row>
        </Container>
    )
}

export default FiltrosPost