import React, { useState } from 'react';
import OptionLabel from '../optionLabel/optionLabel';
import { MdClose } from 'react-icons/md';
import styles from './dropdownTerapeuta.module.css';

const DropdownTerapeuta = props => {
  const [terapeutaSeleccionado, setTerapeutaSeleccionado] = useState('');
  const [tipoAccesoSeleccionado, setTipoAccesosSeleccionado] = useState('');

  const handleTipoAcceso = e => {
    e.preventDefault();
    setTipoAccesosSeleccionado(e.target.value);
  };

  const handleTerapeutaSeleccionado = e => {
    e.preventDefault();
    setTerapeutaSeleccionado(e.target.value);
  };

  const handleAgregar = e => {
    if (!terapeutaSeleccionado || !tipoAccesoSeleccionado) {
      alert('Deberá seleccionar un terapeuta y el tipo de acceso');
    } else {
      let nuevoAcceso = {
        terapia: props.util,
        terapeuta: terapeutaSeleccionado,
        tipoAcceso: tipoAccesoSeleccionado
      };
      setTerapeutaSeleccionado('');
      setTipoAccesosSeleccionado('');
      props.addItems(nuevoAcceso);
    }
  };

  const handleRemover = terapeutaId => {
    let accesoARemover = {
      terapia: props.util,
      terapeuta: terapeutaId
    };
    props.removeItem(accesoARemover);
  };
  return (
    <div className='row'>
      <div className='col-8'>
        <div className='form-group form-group-lg'>
          <label
            className='control-label label-label'
            style={{ float: 'left' }}
            htmlFor='list'
          >
            Terapeuta
          </label>
          <select
            className=' select form-control'
            id='list'
            value={terapeutaSeleccionado}
            onChange={handleTerapeutaSeleccionado}
          >
            <option selected value=''></option>
            {props.list.length > 0 ? (
              props.list.map((item, index) => (
                <OptionLabel
                  key={index}
                  text={item.nombreCompleto}
                  value={item._id}
                />
              ))
            ) : (
              <></>
            )}
          </select>
        </div>
      </div>

      <div className='col-4'>
        <label
          className='control-label label-label'
          style={{ float: 'left' }}
          htmlFor='accesos'
        >
          Acceso
        </label>
        <select
          className=' select form-control'
          id='accesos'
          value={tipoAccesoSeleccionado}
          onChange={handleTipoAcceso}
        >
          <option selected value=''></option>
          <option value='Escritura'>Escritura</option>
          <option value='Lectura'>Lectura</option>
        </select>
      </div>

      <div className={'col-xl-4 col-lg-6 col-12 ' + styles.containerButton}>
        <button
          type='button'
          className={
            'btn btn-outline-primary btn-block ' + styles.btnGeneralStyle
          }
          onClick={handleAgregar}
        >
          {props.buttonTitle}
        </button>
      </div>
      {/* props.addItems(props.util) */}
      <div className='col-12'>
        <ul className={styles.selectedList + ' col-12'}>
          {props.selectedItems.length > 0 ? (
            props.selectedItems.map((item, key) => (
              <li key={key} className={styles.selectedItem}>
                <MdClose
                  className={styles.deleteSelectedItem}
                  color='red'
                  onClick={() => handleRemover(item.terapeuta._id)}
                />
                <p className={styles.terapeutaName}>
                  {item.terapeuta.nombreCompleto} - {item.tipoAcceso}
                </p>
              </li>
            ))
          ) : (
            <li>{props.emptyState}</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default DropdownTerapeuta;
