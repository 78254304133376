import React from 'react';
import Pagination from 'react-bootstrap/Pagination'

const Paginacion = props => {

  const ejecutarDistintoPrimerPagina = (num) => {
    if (props.paginaActual !== 1) {
      props.handler(num);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  const ejecutarDistintoUltimaPagina = (num) => {
    if (props.paginaActual !== props.cantidad) {
      props.handler(num);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  return (
    <Pagination style={{display: 'flex',justifyContent: 'center', marginTop: '42px', width: 'auto'}}>
      <Pagination.Prev className="mr-2" onClick={() => ejecutarDistintoPrimerPagina(props.paginaActual - 1)}/>

      {props.paginaActual !== 1 && props.paginaActual -2 > 1  ? <Pagination.Item onClick={() => props.handler(1)}>1</Pagination.Item> : <></>}
      {props.cantidad > 5 && props.paginaActual -3 > 1 ? <Pagination.Ellipsis /> : <></>}

      {props.paginaActual > 2 ? <Pagination.Item onClick={() => props.handler(Math.max(1, props.paginaActual -2))}>{Math.max(1, props.paginaActual -2)}</Pagination.Item> : <></>}
      {props.paginaActual > 1 ?<Pagination.Item onClick={() => props.handler(Math.max(1, props.paginaActual -1))}>{Math.max(1, props.paginaActual -1)}</Pagination.Item> : <></>}
      
      <Pagination.Item active>{props.paginaActual}</Pagination.Item>
      
      {props.paginaActual <= props.cantidad - 1 ? <Pagination.Item onClick={() => props.handler(Math.min(props.cantidad, props.paginaActual +1 ))}>{Math.min(props.cantidad, props.paginaActual +1 )}</Pagination.Item> : <></>}
      {props.paginaActual <= props.cantidad - 2 ? <Pagination.Item onClick={() => props.handler(Math.min(props.cantidad, props.paginaActual +2 ))}>{Math.min(props.cantidad, props.paginaActual +2 )}</Pagination.Item> : <></>}
      {props.cantidad > 3 && props.paginaActual + 3 < props.cantidad ? <Pagination.Ellipsis /> : <></>}
      {props.cantidad > 3 && props.paginaActual + 2 < props.cantidad ? <Pagination.Item onClick={() => props.handler(props.cantidad)}>{props.cantidad}</Pagination.Item> : <></>}
      
      <Pagination.Next className="ml-2" onClick={() => {ejecutarDistintoUltimaPagina(props.paginaActual + 1) }}/>
    </Pagination>
  )
}

export default Paginacion

// import React from 'react';
// import styles from './paginacion.module.css';
// import _ from 'underscore';

// const Paginacion = props => {
//   return (
//     <div className='mt-5'>
//       <button
//         className='btn btn-sm btn-outline-secondary mr-1'
//         onClick={() => {
//           if (props.paginaActual !== 1) {
//             props.handler(props.paginaActual - 1);
//             window.scrollTo({ top: 0, behavior: 'smooth' });
//           }
//         }}
//       >
//         {'<'}
//       </button>
//       {_.times(props.cantidad, num => (
//         <button
//           key={num}
//           className={
//             props.paginaActual === num + 1
//               ? 'btn btn-sm btn-outline-secondary mr-1 ' + styles.isActive
//               : 'btn btn-sm btn-outline-secondary mr-1 ' + styles.isNotActice
//           }
//           onClick={() => {
//             if (props.paginaActual !== num + 1) {
//               props.handler(num + 1);
//               window.scrollTo({ top: 0, behavior: 'smooth' });
//             }
//           }}
//         >
//           {num + 1}
//         </button>
//       ))}
//       <button
//         className='btn btn-sm btn-outline-secondary mr-1'
//         onClick={() => {
//           if (props.paginaActual !== props.cantidad) {
//             props.handler(props.paginaActual + 1);
//             window.scrollTo({ top: 0, behavior: 'smooth' });
//           }
//         }}
//       >
//         >
//       </button>
//     </div>
//   );
// };

// export default Paginacion;
