import React, {useState} from 'react'
import MoreImages from './moreImages'
import MoreImagesVertical from './moreImagesVertical'
import ImagesCarousel from './imagesCarousel'
import ModalCarousel from './modalCarousel'
import ReactPlayer from 'react-player'
import styles from './reactPlayer.module.css'

const ImagePost = (props) => {

    const [modalCarousel, setModalCarousel] = useState(false)
    const [videoPlaying1, setVideoPlaying1] = useState(false)

    const handleModalCarousel = (value, index = 0) => {
        setModalCarousel(value)
    }

    return (
    <div style={{marginTop: '24px'}}>
        {/* SI SOLO HAY IMAGENES */}
        {(props.imagenes.length > 0 && props.videos.length === 0) ?
        <div style={{display: 'flex', flexDirection: 'row', height: '250px', width: '100%'}}>
            {/* Imagen Vertical */}
            <div style={{padding: '2px', width: props.imagenes.length === 1 ? '100%' :  props.imagenes.length === 2 || props.imagenes.length === 3 || props.imagenes.length === 4 ? '50%' : '33.3333%', cursor: 'pointer'}} onClick={() => handleModalCarousel(true, 0)}>
                <img src={props.imagenes[0]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
            </div>
            {props.imagenes.length === 2 &&
                /* Imagen Vertical */
                <div style={{padding: '2px', width: '50%', height: '250px', cursor: 'pointer'}} onClick={() => handleModalCarousel(true, 1)}>
                    <img src={props.imagenes[1]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
                </div>
            }
            {props.imagenes.length === 3 &&
                <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '50%'}}>
                <div style={{padding: '2px', cursor: 'pointer', height: '125px'}} onClick={() => handleModalCarousel(true, 1)}>
                    <img src={props.imagenes[1]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
                </div>
                <div style={{padding: '2px', height: '125px'}} onClick={() => handleModalCarousel(true, 2)}>
                    <img src={props.imagenes[2]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
                </div>
            </div>
            }

            {props.imagenes.length === 4 &&
                <div style={{display: 'flex', flexDirection: 'column', height: '250px', width: '50%'}}>
                <div style={{padding: '2px', cursor: 'pointer', height: '125px'}} onClick={() => handleModalCarousel(true, 1)}>
                    <img src={props.imagenes[1]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
                </div>
                <div style={{padding: '2px', position: 'relative', height: '125px'}} onClick={() => handleModalCarousel(true, 2)}>
                    <MoreImages cantidad='1' />
                    <img src={props.imagenes[2]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
                </div>
            </div>
            }

            {(props.imagenes.length === 5) &&
            <>
            <div style={{display: 'flex', flexDirection: 'column', height: '250px', width: '33.3333%'}}>
                <div style={{padding: '2px', cursor: 'pointer', height: '125px'}} onClick={() => handleModalCarousel(true, 1)}>
                    <img src={props.imagenes[1]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
                </div>
                <div style={{padding: '2px', position: 'relative', cursor: 'pointer', height: '125px'}} onClick={() => handleModalCarousel(true, 2)}>
                    <img src={props.imagenes[2]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
                </div>
            </div>
            
            <div style={{display: 'flex', flexDirection: 'column', height: '250px', width: '33.3333%'}}>
                <div style={{padding: '2px', cursor: 'pointer', height: '125px'}} onClick={() => handleModalCarousel(true, 3)}>
                    <img src={props.imagenes[3]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
                </div>
                <div style={{padding: '2px', position: 'relative', height: '125px'}} onClick={() => handleModalCarousel(true, 4)}>
                    <img src={props.imagenes[4]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
                </div>
            </div>
            </>
        }

        {(props.imagenes.length > 5) &&
            <>
            <div style={{display: 'flex', flexDirection: 'column', height: '250px', width: '33.3333%'}}>
                <div style={{padding: '2px', cursor: 'pointer', height: '125px'}} onClick={() => handleModalCarousel(true, 1)}>
                    <img src={props.imagenes[1]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
                </div>
                <div style={{padding: '2px', position: 'relative', cursor: 'pointer', height: '125px'}} onClick={() => handleModalCarousel(true, 2)}>
                    <img src={props.imagenes[2]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
                </div>
            </div>
            
            <div style={{display: 'flex', flexDirection: 'column', height: '250px', width: '33.3333%'}}>
                <div style={{padding: '2px', cursor: 'pointer', height: '125px'}} onClick={() => handleModalCarousel(true, 3)}>
                    <img src={props.imagenes[3]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
                </div>
                <div style={{padding: '2px', position: 'relative', height: '125px'}} onClick={() => handleModalCarousel(true, 4)}>
                    <MoreImages cantidad={(props.imagenes.length - 5).toString()}/>
                    <img src={props.imagenes[4]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
                </div>
            </div>
            </>
        }        
        </div> : <></>
    }

    {/* SI HAY VIDEOS E IMAGENES */}
    {props.videos.length > 0 && props.imagenes.length > 0 ?
        <div style={{display: 'flex', flexDirection: 'row', height: '250px', width: '100%'}}>
            <div style={{width: '50%', cursor: 'pointer', padding: '2px'}} >
                {/* Video Vertical */}
                <ReactPlayer playing={videoPlaying1 && !modalCarousel} onPlay={() => setVideoPlaying1(true)} onPause={() => setVideoPlaying1(false)} url={props.videos[0]} className={styles.reproductor} styles={{padding: '2px'}} width='100%' height='100%' controls={true} />
            </div>
        {props.imagenes.length === 1 && 
            <div style={{padding: '2px', width: '50%',position: 'relative', height: '250px', cursor: 'pointer'}} onClick={() => handleModalCarousel(true, props.videos.length > 1 ? 1 : props.videos.length )}>
                {/* Imagen Vertical */}
                {props.videos.length > 1 ? <MoreImagesVertical cantidad={props.videos.length - 1} /> : <></>}
                <img src={props.imagenes[0]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
            </div>
        }

        {props.imagenes.length === 2 &&
        <>
            <div style={{display: 'flex', flexDirection: 'column', height: '250px', width: '50%'}}>
                <div style={{padding: '2px', width: '100%', cursor: 'pointer', height: '125px'}} onClick={() => handleModalCarousel(true, props.videos.length)}>
                    <img src={props.imagenes[0]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
                </div>
                <div style={{padding: '2px', position: 'relative', width: '100%', cursor: 'pointer', height: '125px'}} onClick={() => handleModalCarousel(true, props.videos.length > 1 ? 1 : props.videos.length)}>
                    {props.videos.length > 1 ? <MoreImages cantidad={props.videos.length - 1} /> : <></>}
                    <img src={props.imagenes[1]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
                </div>
            </div>
        </>
        }
        {props.imagenes.length === 3 &&
            <div style={{display: 'flex', flexDirection: 'column', height: '250px', width: '50%'}}>
            <div style={{padding: '2px', cursor: 'pointer', height: '125px'}} onClick={() => handleModalCarousel(true, props.videos.length)}>
                <img src={props.imagenes[0]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
            </div>
            <div style={{padding: '2px', position: 'relative', height: '125px'}} onClick={() => handleModalCarousel(true, props.videos.length > 1 ? 1 : props.videos.length + 1)}>
                {props.videos.length > 1 ? <MoreImages cantidad={props.videos.length - 1 + 1} /> : <></>}
                <img src={props.imagenes[1]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
            </div>
        </div>}

        {props.imagenes.length === 4 &&
            <div style={{display: 'flex', flexDirection: 'column', height: '250px', width: '50%'}}>
                <div style={{padding: '2px', cursor: 'pointer', height: '125px'}} onClick={() => handleModalCarousel(true, props.videos.length)}>
                    <img src={props.imagenes[0]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
                </div>
                <div style={{padding: '2px', position: 'relative', height: '125px'}} onClick={() => handleModalCarousel(true, props.videos.length > 1 ? 1 : props.videos.length + 1 )}>
                    <MoreImages cantidad={props.videos.length - 1 + 2} />
                    <img src={props.imagenes[1]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
                </div>
            </div>
        }

        {(props.imagenes.length === 5) &&
        <>
        <div style={{display: 'flex', flexDirection: 'column', height: '250px', width: '33.3333%'}}>
            <div style={{padding: '2px', cursor: 'pointer', height: '125px'}} onClick={() => handleModalCarousel(true, props.videos.length)}>
                <img src={props.imagenes[0]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
            </div>
            <div style={{padding: '2px', position: 'relative', cursor: 'pointer', height: '125px'}} onClick={() => handleModalCarousel(true, props.videos.length +1)}>
                <img src={props.imagenes[1]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
            </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'column', height: '250px', width: '33.3333%'}}>
            <div style={{padding: '2px', cursor: 'pointer', height: '125px'}} onClick={() => handleModalCarousel(true, props.videos.length+2)}>
                <img src={props.imagenes[2]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
            </div>
            <div style={{padding: '2px', position: 'relative', height: '125px'}} onClick={() => handleModalCarousel(true, props.videos.length > 1 ? 1 : props.videos.length + 3)}>
                {props.videos.length > 1 ? <MoreImages cantidad={props.videos.length - 1 + 1} /> : <></>}
                <img src={props.imagenes[3]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
            </div>
        </div>
        </>
        }

        {(props.imagenes.length > 5) &&
        <>
        <div style={{display: 'flex', flexDirection: 'column', height: '250px', width: '33.3333%'}}>
            <div style={{padding: '2px',  cursor: 'pointer', height: '125px'}} onClick={() => handleModalCarousel(true, props.videos.length)}>
                <img src={props.imagenes[0]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
            </div>
            <div style={{padding: '2px', position: 'relative', cursor: 'pointer', height: '125px'}} onClick={() => handleModalCarousel(true, props.videos.length+1)}>
                <img src={props.imagenes[1]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
            </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'column', height: '250px', width: '33.3333%'}}>
            <div style={{padding: '2px', cursor: 'pointer', height: '125px'}} onClick={() => handleModalCarousel(true, props.videos.length+2)}>
                <img src={props.imagenes[2]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
            </div>
            <div style={{padding: '2px', position: 'relative', height: '125px'}} onClick={() => handleModalCarousel(true, props.videos.length > 1 ? 1 : props.videos.length + 3 )}>
                {props.videos.length > 1 ? <MoreImages cantidad={props.videos.length - 1 + props.imagenes.length - 4} /> : <MoreImages cantidad={props.imagenes.length - 5}/>}
                <img src={props.imagenes[3]} alt="" style={{borderRadius: '6px', objectFit: 'cover', height: '100%', width: '100%'}}/>
            </div>
        </div>
        </>
        }
        </div> : <></>
    }

    {/* SI SOLO HAY VIDEOS */}
    {props.videos.length > 0 && props.imagenes.length === 0 ? 
        <div style={{display: 'flex', flexDirection: 'row', height: '250px', width: '100%'}}>
            <div style={{width: props.videos.length === 1 ? '100%' : '50%', cursor: 'pointer', padding: '2px'}}>
                {/* Video Vertical */}
                <ReactPlayer  url={props.videos[0]} controls={true} className={props.videos.length === 1 ? styles.reproductorRectangular : styles.reproductor } styles={{padding: '2px'}} width='100%' height='100%'/>
            </div>
    {props.videos.length === 2 &&
        <div style={{width:'50%' , cursor: 'pointer', height: '100%', padding: '2px'}}>
            {/* Video Vertical */}
            <ReactPlayer url={props.videos[1]} controls={true} className={styles.reproductor} styles={{padding: '2px'}} width='100%' height='100%'/>
        </div>
    }

    {props.videos.length > 2 &&
    <div style={{width:'50%', position: 'relative', cursor: 'pointer', padding: '2px'}} onClick={() => handleModalCarousel(true, 0)}>
        {/* Video Vertical */}
        <MoreImagesVertical cantidad={props.videos.length-2}/>
        <ReactPlayer playing={videoPlaying1 && !modalCarousel} onPlay={() => setVideoPlaying1(true)} onPause={() => setVideoPlaying1(false)} url={props.videos[1]} className={styles.reproductorRectangular} playIcon styles={{padding: '2px'}} width='100%' height='100%' onClick={() => handleModalCarousel(true, 0)}/>
    </div>
    }

        </div>

    : <></>}
            {modalCarousel &&
            <ModalCarousel isOpen={modalCarousel} closeModal={handleModalCarousel}>
                <ImagesCarousel imagenes={props.imagenes} videos={props.videos} index={0}/>
            </ModalCarousel>
        }

        </div>)
    }

export default ImagePost