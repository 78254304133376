import React, { useState } from 'react';
import styles from './signUp.module.css';
//Libreria Yup para validaciones del formulario
import * as yup from 'yup';
//funcion para Postear nuevos usuarios
import { crearUsuario, uploadUserAvatar } from '../../utils/serverRequest';
import { useFormik } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import FormContainer from '../Globales/formContainer/FormContainer';
import FormInput from '../Globales/formInput/FormInput';
import FormButton from '../Globales/formButton/FormButton';
import FormSelect from '../Globales/formSelect/FormSelect';
import InputMessage from '../Globales/inputMessage/InputMessage';
import FormInputFile from '../Globales/formInputFile/FormInputFile';
import Avatar from '../Globales/avatar/avatar';
import { MdClose } from 'react-icons/md';
import Alert from '../Globales/alertPropia/alertPropia';

//Patron de Validacion de contraseña (8 minimo caracteres, 1 mayusc 1 minuscula, 1 numero)
const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

const roles = ['Admin', 'Terapeuta'];

let validationSchemaSignup = yup.object().shape({
  nombreCompleto: yup.string().required('Este campo es requerido'),
  fechaNacimiento: yup.date().required('Este campo es requerido'),
  dni: yup.string().required('Este campo es requerido'),
  rol: yup
    .mixed()
    .oneOf(roles)
    .required('Este campo es requerido'),
  email: yup
    .string()
    .email('Ingrese un correo electrónico válido')
    .required('Este campo es requerido'),
  password: yup
    .string()
    .matches(
      passwordRegex,
      'La contraseña deberá tener al menos 8 caracteres, 1 mayúscula y 1 número'
    )
    .required('Este campo es requerido'),
  rePassword: yup
    .string()
    .oneOf(
      [yup.ref('password'), null],
      'La contraseña ingresada no coincide con la de arriba'
    )
    .required('Este campo es requerido')
});

const SignUp = () => {
  let history = useHistory();
  const [serverError, setServerError] = useState(false);
  const [serverErrorDescription, setServerErrorDescription] = useState([]);
  const [avatar, setAvatar] = useState('');
  const [alertImage, setAlertImage] = useState(false);

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    isSubmitting
  } = useFormik({
    initialValues: {
      nombreCompleto: '',
      fechaNacimiento: '',
      dni: '',
      rol: '',
      email: '',
      password: '',
      rePassword: ''
    },
    validationSchema: validationSchemaSignup,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: async values => {
      let res = await crearUsuario(values);

      switch (res.status) {
        case 200:
          if (avatar) {
            let data = await enviarArchivo(res.data.user._id);
            if (data.ok) {
              history.push('/registrarse/confirmacion')
            } else {
              alert('No se pudo crear la foto');
              history.push('/registrarse/confirmacion')            }
          } else {
            history.push('/registrarse/confirmacion')          }
          break;
        case 400:
          setServerError(true);
          if (res.data.err.dni && res.data.err.email) {
            setServerErrorDescription(
              res.data.err.dni.message + ' ' + res.data.err.email.message
            );
          } else if (res.data.err.dni) {
            setServerErrorDescription(res.data.err.dni.message);
          } else {
            setServerErrorDescription(res.data.err.email.message);
          }
          break;
        case 500:
          setServerError(true);
          console.log(res.data.err);
          setServerErrorDescription('Hubo un error inesperado del servidor');
          break;
        default:
          console.log(res);
          break;
      }
    }
  });

  const addImage = e => {
    e.preventDefault();
    let nuevoAvatar = e.target.files[0];
    if (isImage(nuevoAvatar.name)) {
      setAvatar(nuevoAvatar);
    } else {
      setAlertImage(true);
      setTimeout(() => {
        setAlertImage(false);
      }, 5000);
    }
    // Para poder volver a cargar el mismo archivo después de sacarlo
    e.target.value = '';
  };

  const isImage = fileName => {
    let extension = fileName
      .substring(fileName.lastIndexOf('.') + 1)
      .toLowerCase();
    switch (extension) {
      case 'jpg':
      case 'png':
      case 'jpeg':
        return true;
      default:
        return false;
    }
  };

  const removeImage = e => {
    e.preventDefault();
    setAvatar('');
  };

  const enviarArchivo = async userId => {
    let formData = new FormData();
    formData.append('Archivos', avatar);
    let data = await uploadUserAvatar(formData, userId);
    return data;
  };

  return (
    <div className='col-lg-6 col-md-6 col-sm-9 col-xs-12 container'>
      {alertImage && (
        <Alert variant='danger' show={alertImage}>
          <strong>Archivo no válido.</strong> Seleccione un archivo con
          extensión <strong>JPG</strong>, <strong>JPEG</strong> o{' '}
          <strong>PNG</strong>
        </Alert>
      )}

      <FormContainer title='Crear un usuario' clicked={handleSubmit}>
        <FormInput
          labelText='Nombre completo'
          labelHtmlFor='nombreCompleto'
          atrName='nombreCompleto'
          atrId='nombreCompleto'
          atrType='text'
          atrValue={values.nombreCompleto}
          changed={handleChange}
          hasErrors={errors.nombreCompleto ? true : false}
        >
          {errors.nombreCompleto ? (
            <InputMessage
              message={errors.nombreCompleto}
              atrId='hint_nombreCompleto'
              hasErrors={errors.nombreCompleto ? true : false}
            />
          ) : null}
        </FormInput>

        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
            <FormInput
              labelText='Fecha de nacimiento'
              labelHtmlFor='fechaNacimiento'
              atrName='fechaNacimiento'
              atrId='fechaNacimiento'
              atrType='date'
              atrValue={values.fechaNacimiento}
              changed={handleChange}
              hasErrors={errors.fechaNacimiento ? true : false}
            >
              {errors.fechaNacimiento ? (
                <InputMessage
                  message={errors.fechaNacimiento}
                  atrId='hint_fechaNacimiento'
                  hasErrors={errors.fechaNacimiento ? true : false}
                />
              ) : null}
            </FormInput>
          </div>

          <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
            <FormInput
              labelText='Documento'
              labelHtmlFor='dni'
              atrName='dni'
              atrId='dni'
              atrType='text'
              atrValue={values.dni}
              changed={handleChange}
              hasErrors={errors.dni ? true : false}
            >
              {errors.dni ? (
                <InputMessage
                  message={errors.dni}
                  atrId='hint_dni'
                  hasErrors={errors.dni ? true : false}
                />
              ) : null}
            </FormInput>
          </div>
        </div>

        <FormSelect
          labelText='Rol'
          labelHtmlFor='rol-picker'
          atrId='rol-picker'
          atrName='rol'
          atrValue={values.rol}
          changed={handleChange}
          list={roles}
          hasErrors={errors.rol ? true : false}
        >
          {errors.rol ? (
            <InputMessage
              message={errors.rol}
              atrId='hint_rol'
              hasErrors={errors.rol ? true : false}
            />
          ) : null}
        </FormSelect>
        <FormInput
          labelText='Correo electrónico'
          labelHtmlFor='email'
          atrName='email'
          atrId='email'
          atrType='text'
          atrMinLength='8'
          atrValue={values.email}
          changed={handleChange}
          hasErrors={errors.email ? true : false}
        >
          {errors.email ? (
            <InputMessage
              message={errors.email}
              atrId='hint_email'
              hasErrors={errors.email ? true : false}
            />
          ) : null}
        </FormInput>
        <FormInput
          labelText='Contraseña'
          labelHtmlFor='password'
          atrName='password'
          atrId='password'
          atrType='password'
          atrMinLength='8'
          atrValue={values.password}
          changed={handleChange}
          hasErrors={errors.password ? true : false}
        >
          {errors.password ? (
            <InputMessage
              message={errors.password}
              atrId='hint_password'
              hasErrors={errors.password ? true : false}
            />
          ) : (
            <InputMessage
              message='La contraseña deberá tener al menos 8 caracteres, 1 mayúscula y 1 número'
              atrId='hint_password'
            />
          )}
        </FormInput>
        <FormInput
          labelText='Repita su contraseña'
          labelHtmlFor='rePassword'
          atrName='rePassword'
          atrId='rePassword'
          atrType='password'
          changed={handleChange}
          hasErrors={errors.rePassword ? true : false}
        >
          {errors.rePassword ? (
            <InputMessage
              message={errors.rePassword}
              atrId='hint_rePassword'
              hasErrors={errors.rePassword ? true : false}
            />
          ) : (
            <InputMessage
              message='Deberá coincidir con la contraseña ingresada arriba'
              atrId='hint_rePassword'
            />
          )}
        </FormInput>

        <div
          className={'row ' + styles.containerAvatarMax}
          style={{ marginLeft: '0px', marginRight: '0px', marginTop: '24px' }}
        >
          <div className={styles.buttonPosition + ' col-xl-4 col-lg-6 col-12'}>
            <FormInputFile
              text='SUBIR FOTO DE PERFIL'
              multiple={false}
              id='archivoAvatar'
              onChange={addImage}
            />
          </div>
          {avatar ? (
            <div
              className={'col-xl-4 col-lg-6 col-12 ' + styles.avatarContainer}
            >
              <div className={styles.avatar}>
                <MdClose color='red' onClick={removeImage} />
              </div>
              <Avatar rutaAvatar={URL.createObjectURL(avatar)} size='l' />
            </div>
          ) : (
            <div className={'col-xl-8 col lg-6 col-12 ' + styles.noHayAvatar}>
              <ul
                style={{
                  paddingLeft: '0px',
                  marginBottom: '0px',
                  listStyleType: 'none'
                }}
              >
                <p style={{ textAlign: 'left', marginBottom: '0px' }}>
                  Aún no ha seleccionado una foto de perfil
                </p>
              </ul>
            </div>
          )}
        </div>

        {serverError && (
          <InputMessage
            message={serverErrorDescription}
            atrId='hint_sverror'
            hasErrors={serverError ? true : false}
          />
        )}
        <div
          className={styles.buttonPosition + ' col-xl-4 col-lg-6 col-12'}
          style={{ marginTop: '32px' }}
        >
          <FormButton
            text='REGISTRARSE'
            buttonType='submit'
            atrName='registrarse'
            buttonStyle='primario'
            atrDisabled={isSubmitting}
          />
        </div>

        <div className='form-group'>
          <p className={styles.refText}>
            ¿Ya tenés cuenta?{' '}
            <Link to='/ingresar'>
              <a href='#'>Ingresá acá</a>
            </Link>
          </p>
        </div>
      </FormContainer>
    </div>
  );
};

export default SignUp;
