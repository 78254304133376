import React from 'react';
import './PrivateRoute.css';
import { Route, Redirect } from 'react-router-dom';
import Sidebar from '../../sidebar/sidebar.jsx';
import { useState } from 'react';
import { checkToken } from '../../../utils/utils';
import Navbar from '../../navbar/navbar';
import { stillLoggedIn } from '../../../utils/utils';
import ScrollToTop from '../scrollToTop/ScrollToTop';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = action => {
    if (action) {
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  };

  const checkLoggedIn = async () => {
    let still = await stillLoggedIn();
    if (!still) {
      localStorage.removeItem('token');
    }
  };

  checkLoggedIn();

  return (
    <div className='AppPrivada container-fluid'>
      <div className='row' style={{ height: '100%' }}>
        <div
          className={
            (sidebarOpen ? '' : 'd-none ') +
            'd-md-block col-md-2 col-sm-6 col-8 sidebar position-fixed ' +
            'sidebarShadow'
          }
        >
          <Sidebar sidebarToggler={toggleSidebar} />
        </div>
        <div id='main-container' className='rightScreen col-12 col-md-10'>
          <Navbar sidebarToggler={() => toggleSidebar(true)} />
          <div
            className='row dashboardContent'
            onClick={() => toggleSidebar(false)}
          >
            <div className='col-12'>
              <Route
                {...rest}
                render={props =>
                  checkToken() ? (
                    <ScrollToTop>
                      <Component {...props} />
                    </ScrollToTop>
                  ) : (
                    <Redirect to='/ingresar' />
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateRoute;
