import React, { useState } from "react";
import styles from "./postInfo.module.css";
import ReaccionesPosibles from "../reaccionesPosibles/reaccionesPosibles";
import ReaccionesDadas from "../reaccionesDadas/reaccionesDadas";
import ViewsList from "../viewsList/viewsList";
import Modal from "../modalWithoutFooter/modalWithoutFooter";
import ReactionsTab from "../reactionTabs/reactionTabs";
import ModalPostFiles from "./postModalFiles";
import PostFilePreview from "./postFilePreview";

const PostInfo = (props) => {
  const [modalViewsOpen, setModalViewsOpen] = useState(false);
  const [modalArchivosOpen, setModalArchivosOpen] = useState(false);
  let user = JSON.parse(localStorage.getItem("user"));

  const handleViewsModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setModalViewsOpen(!modalViewsOpen);
  };
  const handleModalArchivos = (e, value) => {
    if (e) {
      e.preventDefault();
    }
    setModalArchivosOpen(value);
  };

  return (
    <div className={styles.postInfoContainer}>
      {props.archivosPost.length > 0 && (
        <div style={{ width: "fit-content", fontSize: "14px", padding: "8px 0" }}>
          <a href="#" onClick={(event) => handleModalArchivos(event, true)}>
            {props.archivosPost.length === 1 ? "Ver 1 archivo adjunto" : `Ver ${props.archivosPost.length} archivos adjuntos`}
          </a>
        </div>
      )}

      {props.archivosPost.length === 0 && props.cantidadReacciones > 0 && (
        <ReaccionesDadas
          className="mr-auto"
          cantidadReacciones={props.cantidadReacciones}
          reacciones={props.reaccionesDadas}
          handleReactionModal={props.setModalReactionsOpen}
        />
      )}
      <div className="d-flex justify-end align-items-center ml-auto">
        {props.views && props.views.length > 0 && user.email === "dra.donadio@gmail.com" && (
          <a className="mr-2" style={{ fontSize: "14px" }} href="#" onClick={handleViewsModal}>
            {props.views.length === 1 ? "1 visto" : `${props.views.length} vistos`}
          </a>
        )}
        {props.commentsLength > 0 && (
          <p className="m-0" style={{ fontSize: "14px" }}>
            {props.commentsLength === 1 ? `${props.commentsLength} comentario` : `${props.commentsLength} comentarios`}
          </p>
        )}
      </div>
      {modalViewsOpen && (
        <Modal title="Vistos" isOpen={modalViewsOpen} closeModal={handleViewsModal}>
          <ViewsList views={props.views} />
        </Modal>
      )}

      {modalArchivosOpen && (
        <ModalPostFiles isOpen={modalArchivosOpen} closeModal={handleModalArchivos}>
          <ul style={{ padding: "0px" }}>
            {props.archivosPost.map((archivo) => (
              <PostFilePreview originalLink={archivo.originalLink} presignedUrl={archivo.presignedUrl} link={archivo} />
            ))}
          </ul>
        </ModalPostFiles>
      )}
    </div>
  );
};

export default PostInfo;
