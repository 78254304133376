import React from "react";
import PropTypes from "prop-types";
import styles from "./spinner.module.css";

const Spinner = (props) => {
  let classes;

  switch (props.spinnerType) {
    case "forButtonWhite":
      classes = `spinner-border spinner-border-sm ${styles.forButtonWhite}`;
      break;
    case "forButtonBlue":
      classes = `spinner-border spinner-border-sm ${styles.forButtonBlue}`;
      break;
    case "extrasmall":
      classes = `spinner-border spinner-border-sm ${styles.extraSmall}`;
      break;
    case "small":
      classes = `spinner-border spinner-border-sm ${styles.small}`;
      break;
    default:
      classes = "spinner-border m-5";
      break;
  }

  return (
    <div className={classes} role="status" style={{ color: "#7F7F7F" }}>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

Spinner.propTypes = {
  spinnerType: PropTypes.string,
};

export default Spinner;
