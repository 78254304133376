import React from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import FormInput from '../formInput/FormInput'
import {FaCalendar} from 'react-icons/fa'

const CustomDatePicker = (props) => {

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <div className="mr-3" style={{width: '100%'}}>
                <FormInput disabled={true} labelText={props.inputLabel} readOnly={props.inputReadOnly} atrValue={props.date ? `${props.date.getDate()}/${props.date.getMonth()+1}/${props.date.getFullYear()}` : ''}/>
            </div>
            <DatePicker
                dateFormat="dd/MM/yyyy"
                showPopperArrow={false}
                selected={props.date}
                maxDate={new Date()}
                onChange={newDate => {props.setDate(newDate)}}
                customInput={<FaCalendar style={{cursor: 'pointer', color: props.iconColor}}/>}
                shouldCloseOnSelect={true}
                popperPlacement="auto"

            />
        </div>
    )
}

export default CustomDatePicker