import React from "react";
import styles from "./grilla.module.css";
import { Animated } from "react-animated-css";

const Grilla = (props) => {
  return (
    <div className={styles.grilla + " table-responsive-lg"}>
      <Animated animationIn="fadeIn">
        <table className="table text-left">
          <thead>
            <tr>
              {props.titulos.map((titulo, key) => (
                <th key={key} scope="col">
                  {titulo}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{props.filas.map((fila) => fila)}</tbody>
        </table>
      </Animated>
    </div>
  );
};

export default Grilla;
