import React from "react";
import PropTypes from "prop-types";
import styles from "../itemsStyles.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const InfoItem = (props) => {
  return (
    <div className={"row " + styles.rowStyle}>
      <div className={"col-12 " + styles.dataBox}>
        <p className={"col-xl-3 col-lg-6 " + styles.textConcept}>
          <strong>{props.title}:</strong>
        </p>
        <p className={"col-xl-9 col-lg-6 " + styles.textValue}>{props.value}</p>
      </div>
    </div>
  );
};

InfoItem.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
};

export default InfoItem;
