import React, { useState } from 'react'
import Toast from 'react-bootstrap/Toast'
import Image from 'react-bootstrap/Image'
import Avatar from '../avatar/avatar'
import { useHistory } from 'react-router-dom'
import parse from 'html-react-parser';
import styles from './toast.module.css'

const CustomToast = (props) => {

    let history = useHistory()

    return (
        <Toast onClose={props.onClose} show={props.show} delay={6000} autohide className={styles.customToast}>
            <Toast.Header>
                <Avatar
                    rutaAvatar={props.data.avatar}
                    size='m'
                    className="mr-2"
                    alt="Avatar"
                />
                <strong className="ml-1 mr-auto text-truncate">{parse(props.data.title)}</strong>
                <small>Ahora</small>
            </Toast.Header>

            <Toast.Body style={{ cursor: 'pointer' }} onClick={props.onClick}>
                <p style={{ whiteSpace: 'break-spaces', textAlign: 'left' }}>
                    {parse(props.data.message)}
                </p>
            </Toast.Body>
        </Toast>
    )
}

export default CustomToast
