import React, { useState, useContext, useEffect } from 'react';
import './userNavbar.css';
import { useHistory, useLocation } from 'react-router-dom';
import io from 'socket.io-client'
import {UserContext, NotificationContext} from '../context/context';
import { markNotificationRead } from '../../utils/serverRequest'
//
import Avatar from '../Globales/avatar/avatar.jsx';
import { Dropdown } from 'react-bootstrap';
import NotificationsWeb from '../notificationsWeb/notificationsWeb'
import Toast from '../Globales/Toast/toast'
import _ from 'underscore'
import { MdNotificationsNone } from 'react-icons/md'

const UserNavbar = () => {
  const [user, setUser] = useContext(UserContext);
  const {unreadNotif} = useContext(NotificationContext)
  const [unreadNotification, setUnreadNotification] = unreadNotif
  const [windowSize, setWindowSize] = useState(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)
  const [notifications, setNotifications] = useState([])
  const [dropdownNotifications, setDropdownNotifications] = useState([])
  let history = useHistory();
  var socket

  const getSize = () => {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  useEffect(() => {
    // Window size handler for rendering appropiate notifications componente
    if (typeof window !== 'object') {
      return false;
    }

    let isMounted = true;

    function handleResize() {
      if (isMounted) {
        setWindowSize(getSize());
      }
    }

    window.addEventListener('resize', _.throttle(handleResize, 200));

    // Sockets
    let userInfo = JSON.parse(localStorage.getItem('user'))

    socket = io({
      query: {
        ...userInfo
      }
    })

    socket.on('paciente-post', (data) => {
      handleNewNotification(data)
    })

    socket.on('paciente-comment', (data) => {
      handleNewNotification(data)
    })

    return () => {
      isMounted = false;
      window.removeEventListener('resize', handleResize);
      socket.close()
    };
  }, []);

  const handleClick = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser('');
    history.push('/ingresar')
  };

  const handleNewNotification = (newNotif) => {
    setDropdownNotifications(prevState => ([newNotif, ...prevState.slice(0, prevState.length - 1)]))
    setUnreadNotification(true)
    setNotifications(prevState => {
      if (prevState.length > 2) {
        return [...prevState.slice(0, prevState.length - 1), newNotif]
      }
      else {
        return [...prevState, newNotif]
      }
    })
  }

  const handleClickNotification = async (notif) => {
    if (!notif.read) {
      let res = await markNotificationRead(notif.id)

      if (res.status === 200) {
        let newDropdownNotifications = dropdownNotifications.map(n => {
          return {
            ...n,
            read: n.id === notif.id ? true : n.read
          }
        })
        setDropdownNotifications(newDropdownNotifications)
      }
      else {
        alert('Error al marcar como leida la notif')
      }
    }
    history.push(notif.link)
    window.location.reload()
  }

  const isAnyUnread = () => {
    return dropdownNotifications.some(n => !n.read)
  }

  return (
    <div className='userNavbarContainer'>
      {windowSize > 768 ?
        <NotificationsWeb
          notifications={notifications}
          setNotifications={setNotifications}
          dropdownNotifications={dropdownNotifications}
          setDropdownNotifications={setDropdownNotifications}
        />
        : <div id="notificationContainer">
            <MdNotificationsNone className="mr-2" id="notificationItem" onClick={() => history.push('/panel/notifications')} size="24px" />
            {unreadNotification && <div id="notificationBadge" style={{ height: '8px', width: '8px', borderRadius: '50%', backgroundColor: '#007BFF' }}></div>}
        </div>}
      <div className='userNameContainer'>
        <p style={{ marginBottom: '0px' }}>{user.nombreCompleto}</p>
      </div>
      <div style={{ cursor: 'pointer' }}>
        <Dropdown alignRight>
          <Dropdown.Toggle variant='none' id='dropdown-basic'>
            <Avatar rutaAvatar={user.avatar} size='s' />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as='button' onClick={handleClick}>
              Cerrar sesión
              </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {/* Avisos en la pantalla */}
      {notifications.length > 0 &&
        <div className="d-none d-md-block" style={{ position: 'fixed', bottom: '5vh', left: '70vw' }}>
          {notifications.map((n, key) => (
            <Toast key={key} data={n} onClose={() => setNotifications([...notifications].slice(1))} show={true} onClick={() => handleClickNotification(n)} />
          ))}
        </div>
      }

    </div>
  );
};

export default UserNavbar;
