import React from 'react';
import { Modal} from 'react-bootstrap';

const ModalCarousel = props => {
  return (
      <Modal style={{height: '500px'}} centered animation={false} show={props.isOpen} onHide={() => props.closeModal(false)}>
        {/* <Modal.Body> */}
          {props.children}
        {/* </Modal.Body> */}
      </Modal>
  );
};

export default ModalCarousel;
