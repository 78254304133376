import React, { useState, useEffect } from 'react';
import InputMessage from '../Globales/inputMessage/InputMessage';
import FormInput from '../Globales/formInput/FormInput';
import FormTextArea from '../Globales/formTextArea/FormTextArea';
import FormStep from '../Globales/formStep/formStep';
import FormStepSubtitle from '../Globales/formStepSubtitle/formStepSubtitle';
import FormSelect from '../Globales/formSelect/FormSelect';
import { MdClose } from 'react-icons/md';
import { obtenerObrasSociales } from '../../utils/serverRequest';
import styles from '../Globales/dropdownTerapeuta/dropdownTerapeuta.module.css';

const parentescos = [
  'Padre/Madre',
  'Abuelo/a',
  'Hermano/a',
  'Tio/a',
  'Primo/a'
];

const Step2 = props => {
  const [obrasSociales, setObrasSociales] = useState([]);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const getObrasSociales = async () => {
      let data = await obtenerObrasSociales();

      setObrasSociales(data.obrasSociales);
    };
    getObrasSociales();
  }, []);

  const handleAgregarResponsable = () => {
    props.values.responsables.push({
      nombreCompletoResponsable: '',
      emailResponsable: '',
      telefonoResponsable1: '',
      telefonoResponsable2: '',
      documentoResponsable: '',
      parentesco: '',
      comentariosAdicionales: ''
      // obraSocialResponsable: '',
      // nombreTitularResponsable: '',
      // numeroTarjetaResponsable: '',
      // fechaVencimientoResponsable: '',
      // codigoSeguridadResponsable: ''
    });
    // El setCounter lo utilizo para cambiar el estado y poder hacer un re-render al componente, ya que pusheando el los valores en value, no cambia el estado, y por ende, no se renderea el componente.
    setCounter(counter + 1);
  };

  const handleRemover = indexResponsable => {
    props.values.responsables.splice(indexResponsable, 1);
    // El setCounter lo utilizo para cambiar el estado y poder hacer un re-render al componente, ya que pusheando el los valores en value, no cambia el estado, y por ende, no se renderea el componente.
    setCounter(counter - 1);
  };

  let errorsParsed = { responsables: [] };

  if (props.errors.responsables) {
    errorsParsed.responsables = props.errors.responsables.map(i =>
      i === undefined ? {} : i
    );
  }
  return (
    <FormStep stepsHandler={props.aumentarDecrementarPaso}>
      {props.values.responsables.map((responsable, index) => (
        <>
          <div
            className='row'
            style={{ marginTop: '32px', marginLeft: '0px', marginRight: '0px' }}
          >
            <h5
              style={{
                textAlign: 'left',
                color: '#167bff',
                fontSize: '20px'
              }}
            >
              Responsable #{index + 1}
            </h5>
            {index > 0 && (
              <div style={{ marginLeft: '8px', marginBottom: '8px' }}>
                <MdClose
                  style={{ cursor: 'pointer' }}
                  color='red'
                  onClick={() => handleRemover(index)}
                />
              </div>
            )}
          </div>
          <div
            style={{
              borderLeft: 'dotted 1px',
              paddingLeft: '20px',
              borderColor: '#dbdbdb'
            }}
          >
            <FormStepSubtitle title='Información general' />
            <div className='row'>
              <div className='col-12'>
                <FormInput
                  labelText='Nombre completo (*)'
                  labelHtmlFor='nombreCompletoResponsable'
                  atrName={`responsables.${index}.nombreCompletoResponsable`}
                  atrId='nombreCompletoResponsable'
                  atrType='text'
                  atrValue={responsable.nombreCompletoResponsable}
                  changed={props.handleChange}
                  hasErrors={
                    errorsParsed.responsables[index]
                      ? errorsParsed.responsables[index]
                          .nombreCompletoResponsable
                        ? true
                        : false
                      : false
                  }
                >
                  {errorsParsed.responsables[index] && (
                    <>
                      {errorsParsed.responsables[index]
                        .nombreCompletoResponsable && (
                        <InputMessage
                          message={
                            errorsParsed.responsables[index]
                              .nombreCompletoResponsable
                          }
                          atrId='hint_nombreCompletoResponsable'
                          hasErrors={
                            errorsParsed.responsables[index]
                              .nombreCompletoResponsable
                              ? true
                              : false
                          }
                        />
                      )}
                    </>
                  )}
                </FormInput>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <FormInput
                  labelText='Correo electrónico (*)'
                  labelHtmlFor='emailResponsable'
                  atrName={`responsables.${index}.emailResponsable`}
                  atrId='emailResponsable'
                  atrType='text'
                  atrValue={responsable.emailResponsable}
                  changed={props.handleChange}
                  hasErrors={
                    errorsParsed.responsables[index]
                      ? errorsParsed.responsables[index].emailResponsable
                        ? true
                        : false
                      : false
                  }
                >
                  {errorsParsed.responsables[index] && (
                    <>
                      {errorsParsed.responsables[index].emailResponsable && (
                        <InputMessage
                          message={
                            errorsParsed.responsables[index].emailResponsable
                          }
                          atrId='hint_emailResponsable'
                          hasErrors={
                            errorsParsed.responsables[index].emailResponsable
                              ? true
                              : false
                          }
                        />
                      )}
                    </>
                  )}
                </FormInput>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 col-12'>
                <FormInput
                  labelText='Número de documento (*)'
                  labelHtmlFor='documentoResponsable'
                  atrName={`responsables.${index}.documentoResponsable`}
                  atrId='documentoResponsable'
                  atrType='text'
                  atrValue={responsable.documentoResponsable}
                  changed={props.handleChange}
                  hasErrors={
                    errorsParsed.responsables[index]
                      ? errorsParsed.responsables[index].documentoResponsable
                        ? true
                        : false
                      : false
                  }
                >
                  {errorsParsed.responsables[index] && (
                    <>
                      {errorsParsed.responsables[index]
                        .documentoResponsable && (
                        <InputMessage
                          message={
                            errorsParsed.responsables[index]
                              .documentoResponsable
                          }
                          atrId='hint_documentoResponsable'
                          hasErrors={
                            errorsParsed.responsables[index]
                              .documentoResponsable
                              ? true
                              : false
                          }
                        />
                      )}
                    </>
                  )}
                </FormInput>
              </div>
              <div className='col-md-6 col-12'>
                <FormSelect
                  labelText='Parentesco (*)'
                  labelHtmlFor='parentesco'
                  atrName={`responsables.${index}.parentesco`}
                  atrId='parentesco'
                  atrType='text'
                  atrValue={responsable.parentesco}
                  changed={props.handleChange}
                  hasErrors={
                    errorsParsed.responsables[index]
                      ? errorsParsed.responsables[index].parentesco
                        ? true
                        : false
                      : false
                  }
                  list={parentescos}
                >
                  {errorsParsed.responsables[index] && (
                    <>
                      {errorsParsed.responsables[index].parentesco && (
                        <InputMessage
                          message={errorsParsed.responsables[index].parentesco}
                          atrId='hint_parentesco'
                          hasErrors={
                            errorsParsed.responsables[index].parentesco
                              ? true
                              : false
                          }
                        />
                      )}
                    </>
                  )}
                </FormSelect>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 col-12'>
                <FormInput
                  labelText='Teléfono (1) (*)'
                  labelHtmlFor='telefonoResponsable1'
                  atrName={`responsables.${index}.telefonoResponsable1`}
                  atrId='telefonoResponsable1'
                  atrType='text'
                  atrValue={responsable.telefonoResponsable1}
                  changed={props.handleChange}
                  hasErrors={
                    errorsParsed.responsables[index]
                      ? errorsParsed.responsables[index].telefonoResponsable1
                        ? true
                        : false
                      : false
                  }
                >
                  {errorsParsed.responsables[index] && (
                    <>
                      {errorsParsed.responsables[index]
                        .telefonoResponsable1 && (
                        <InputMessage
                          message={
                            errorsParsed.responsables[index]
                              .telefonoResponsable1
                          }
                          atrId='hint_telefonoResponsable1'
                          hasErrors={
                            errorsParsed.responsables[index]
                              .telefonoResponsable1
                              ? true
                              : false
                          }
                        />
                      )}
                    </>
                  )}
                </FormInput>
              </div>
              <div className='col-md-6 col-12'>
                <FormInput
                  labelText='Teléfono (2)'
                  labelHtmlFor='telefonoResponsable2'
                  atrName={`responsables.${index}.telefonoResponsable2`}
                  atrId='telefonoResponsable2'
                  atrType='text'
                  atrValue={responsable.telefonoResponsable2}
                  changed={props.handleChange}
                  hasErrors={
                    errorsParsed.responsables[index]
                      ? errorsParsed.responsables[index].telefonoResponsable2
                        ? true
                        : false
                      : false
                  }
                >
                  {errorsParsed.responsables[index] && (
                    <>
                      {errorsParsed.responsables[index]
                        .telefonoResponsable2 && (
                        <InputMessage
                          message={
                            errorsParsed.responsables[index]
                              .telefonoResponsable2
                          }
                          atrId='hint_telefonoResponsable2'
                          hasErrors={
                            errorsParsed.responsables[index]
                              .telefonoResponsable2
                              ? true
                              : false
                          }
                        />
                      )}
                    </>
                  )}
                </FormInput>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <FormTextArea
                  labelText='Comentarios adicionales'
                  labelHtmlFor='comentariosAdicionales'
                  atrName={`responsables.${index}.comentariosAdicionales`}
                  atrId='comentariosAdicionales'
                  atrType='text'
                  atrValue={responsable.comentariosAdicionales}
                  changed={props.handleChange}
                />
              </div>
            </div>

            {index === 0 && (
              <>
                {' '}
                {/* OBRA SOCIAL */}
                <FormStepSubtitle title='Datos de la obra social' />
                <div className='row'>
                  <div className='col-12'>
                    <FormSelect
                      labelText='Obra social'
                      labelHtmlFor='obraSocialResponsable'
                      atrName={`responsables.${index}.obraSocialResponsable`}
                      atrId='obraSocialResponsable'
                      atrType='text'
                      atrValue={responsable.obraSocialResponsable}
                      changed={props.handleChange}
                      hasErrors={
                        errorsParsed.responsables[index]
                          ? errorsParsed.responsables[index]
                              .obraSocialResponsable
                            ? true
                            : false
                          : false
                      }
                      list={obrasSociales}
                    >
                      {errorsParsed.responsables[index] && (
                        <>
                          {errorsParsed.responsables[index]
                            .obraSocialResponsable && (
                            <InputMessage
                              message={
                                errorsParsed.responsables[index]
                                  .obraSocialResponsable
                              }
                              atrId='hint_obraSocialResponsable'
                              hasErrors={
                                errorsParsed.responsables[index]
                                  .obraSocialResponsable
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </>
                      )}
                    </FormSelect>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <FormInput
                      labelText='Nombre que figura en la tarjeta'
                      labelHtmlFor='nombreTitularResponsable'
                      atrName={`responsables.${index}.nombreTitularResponsable`}
                      atrId='nombreTitularResponsable'
                      atrType='text'
                      atrValue={responsable.nombreTitularResponsable}
                      changed={props.handleChange}
                      hasErrors={
                        errorsParsed.responsables[index]
                          ? errorsParsed.responsables[index]
                              .nombreTitularResponsable
                            ? true
                            : false
                          : false
                      }
                    >
                      {errorsParsed.responsables[index] && (
                        <>
                          {errorsParsed.responsables[index]
                            .nombreTitularResponsable && (
                            <InputMessage
                              message={
                                errorsParsed.responsables[index]
                                  .nombreTitularResponsable
                              }
                              atrId='hint_nombreTitularResponsable'
                              hasErrors={
                                errorsParsed.responsables[index]
                                  .nombreTitularResponsable
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </>
                      )}
                    </FormInput>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <FormInput
                      labelText='Número de la tarjeta'
                      labelHtmlFor='numeroTarjetaResponsable'
                      atrName={`responsables.${index}.numeroTarjetaResponsable`}
                      atrId='numeroTarjetaResponsable'
                      atrType='text'
                      atrValue={responsable.numeroTarjetaResponsable}
                      changed={props.handleChange}
                      hasErrors={
                        errorsParsed.responsables[index]
                          ? errorsParsed.responsables[index]
                              .numeroTarjetaResponsable
                            ? true
                            : false
                          : false
                      }
                    >
                      {errorsParsed.responsables[index] && (
                        <>
                          {errorsParsed.responsables[index]
                            .numeroTarjetaResponsable && (
                            <InputMessage
                              message={
                                errorsParsed.responsables[index]
                                  .numeroTarjetaResponsable
                              }
                              atrId='hint_numeroTarjetaResponsable'
                              hasErrors={
                                errorsParsed.responsables[index]
                                  .numeroTarjetaResponsable
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </>
                      )}
                    </FormInput>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-12'>
                    <FormInput
                      labelText='Fecha de vencimiento'
                      labelHtmlFor='fechaVencimientoResponsable'
                      atrName={`responsables.${index}.fechaVencimientoResponsable`}
                      atrId='fechaVencimientoResponsable'
                      atrType='text'
                      atrPlaceholder='MM/AA'
                      atrValue={responsable.fechaVencimientoResponsable}
                      changed={props.handleChange}
                      hasErrors={
                        errorsParsed.responsables[index]
                          ? errorsParsed.responsables[index]
                              .fechaVencimientoResponsable
                            ? true
                            : false
                          : false
                      }
                    >
                      {errorsParsed.responsables[index] && (
                        <>
                          {errorsParsed.responsables[index]
                            .fechaVencimientoResponsable && (
                            <InputMessage
                              message={
                                errorsParsed.responsables[index]
                                  .fechaVencimientoResponsable
                              }
                              atrId='hint_fechaVencimientoResponsable'
                              hasErrors={
                                errorsParsed.responsables[index]
                                  .fechaVencimientoResponsable
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </>
                      )}
                    </FormInput>
                  </div>
                  <div className='col-md-6 col-12'>
                    <FormInput
                      labelText='Código de seguridad'
                      labelHtmlFor='codigoSeguridadResponsable'
                      atrName={`responsables.${index}.codigoSeguridadResponsable`}
                      atrId='codigoSeguridadResponsable'
                      atrType='text'
                      atrValue={responsable.codigoSeguridadResponsable}
                      changed={props.handleChange}
                      hasErrors={
                        errorsParsed.responsables[index]
                          ? errorsParsed.responsables[index]
                              .codigoSeguridadResponsable
                            ? true
                            : false
                          : false
                      }
                    >
                      {errorsParsed.responsables[index] && (
                        <>
                          {errorsParsed.responsables[index]
                            .codigoSeguridadResponsable && (
                            <InputMessage
                              message={
                                errorsParsed.responsables[index]
                                  .codigoSeguridadResponsable
                              }
                              atrId='hint_codigoSeguridadResponsable'
                              hasErrors={
                                errorsParsed.responsables[index]
                                  .codigoSeguridadResponsable
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </>
                      )}
                    </FormInput>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ))}
      <div
        className={'col-xl-4 col-lg-6 col-12 ' + styles.containerButton}
        style={{ paddingLeft: '0px', paddingRight: '0px', marginTop: '32px' }}
      >
        <button
          type='button'
          className={
            'btn btn-outline-primary btn-block ' + styles.btnGeneralStyle
          }
          onClick={handleAgregarResponsable}
        >
          AGREGAR OTRO RESPONSABLE
        </button>
      </div>
    </FormStep>
  );
};

export default Step2;
