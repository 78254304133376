import React, { useState } from 'react'
import styles from './reactionTabs.module.css'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import UserReactionList from '../usersReactionList/usersReactionList'
import { Twemoji } from 'react-emoji-render';
import _ from 'underscore'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'

const ReactionTabs = (props) => {
    const [key, setKey] = useState('home');

    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="all">
            <Row className={`${styles.marginHeight}`}>
                <Col xs={12}>
                    <Nav variant="pills" className="flex position-fixed align-items-center" style={{ zIndex: '1500' }}>
                        <Nav.Item>
                            <Nav.Link eventKey="all">Todas</Nav.Link>
                        </Nav.Item>
                        {window.innerWidth < 420 ? props.reactions.slice(0, 3).map(reactionArray => (
                            <Nav.Item>
                                <Nav.Link eventKey={reactionArray[0]}><Twemoji text={reactionArray[0]} onlyEmojiClassName={styles.reaccionEmoji} /></Nav.Link>
                            </Nav.Item>
                        ))
                            : props.reactions.map(reactionArray => (
                                <Nav.Item>
                                    <Nav.Link eventKey={reactionArray[0]}><Twemoji text={reactionArray[0]} onlyEmojiClassName={styles.reaccionEmoji} /></Nav.Link>
                                </Nav.Item>
                            ))
                        }
                        {
                            props.reactions.length > 3 && window.innerWidth < 420 &&
                            <NavDropdown title="Mas" id="basic-nav-dropdown">
                                {props.reactions.slice(3).map(reactionArray => (
                                    <NavDropdown.Item eventKey={reactionArray[0]}><Twemoji text={reactionArray[0]} onlyEmojiClassName={styles.reaccionEmoji} /></NavDropdown.Item>
                                ))}
                            </NavDropdown>
                        }
                    </Nav>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Tab.Content>
                        <Tab.Pane eventKey="all" title="Todas" activeKey={key} onSelect={(k) => setKey(k)}>
                            <UserReactionList reactions={_.flatten(props.reactions.map(r => r[1]))} />
                        </Tab.Pane>
                        {props.reactions.map(reactionArray => (
                            <Tab.Pane eventKey={reactionArray[0]}>
                                <UserReactionList reactions={reactionArray[1]} />
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    );
}

export default ReactionTabs
