import React from "react";
import PropTypes from "prop-types";
import styles from "../itemsStyles.module.css";
import Badge from "../../badge/Badge";
import Avatar from "../../avatar/avatar";

const AccesosItem = (props) => {
  return (
    <div className={"row " + styles.rowStyle} style={{ marginTop: "8px" }}>
      <div className={"col-12 " + styles.dataBox}>
        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start" }}>
          {props.accesosTerapeutas.map((acceso, index) => {
            let terapiaActual = props.terapiasActivas.find((t) => t.titulo === acceso.terapia);
            let colorTerapiaActual = terapiaActual.colorHex;

            return (
              <div className="col-12 col-lg-4" style={{ marginBottom: "16px" }}>
                <div style={{ marginBottom: "24px" }}>
                  <Badge key={index} nombreTerapia={acceso.terapia} colorHex={colorTerapiaActual} badgeStyle="secondary" />
                </div>

                {acceso.terapeutas.map((terapeuta, index) => {
                  return (
                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "16px", paddingLeft: "8px" }}>
                      <Avatar rutaAvatar={terapeuta.avatar} size="s" />
                      <p style={{ marginBottom: "0px", marginLeft: "8px" }} key={index}>{`${terapeuta.nombreCompleto}`}</p>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

AccesosItem.propTypes = {};

export default AccesosItem;
