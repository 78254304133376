//Packages & Utils
import React from 'react';
import styles from './badge.module.css';



const Badge = props => {

  // let spanStyleClasses;
  // switch (props.badgeStyle) {
  //   case 'primary':
  //     spanStyleClasses = ' badge badge-primary';
  //     break;
  //   case 'secondary':
  //     spanStyleClasses = ' badge badge-secondary ' + styles.spanSecondary;
  //     break;
  //   default:
  //     spanStyleClasses = ' badge badge-primary';
  //     break;
  // }
  return (
    <span className={`${styles.badgeTerapia} badge`} style={{background: props.colorHex ?  props.colorHex : 'blue', color: 'white'}}> 
      {props.nombreTerapia}
    </span>
  );
};

export default Badge;
