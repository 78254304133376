import React from 'react';
import styles from './formTextArea.module.css';

const FormTextArea = props => {
  return (
    <div className='form-group form-group-lg'>
      <label className={styles.inputLabel} htmlFor={props.labelHtmlFor}>
        {props.labelText}
      </label>
      <textarea
        className='form-control'
        maxLength={props.atrMaxLength}
        id={props.atrId}
        name={props.atrName}
        type={props.atrType}
        value={props.atrValue}
        onChange={props.changed}
      />
    </div>
  );
};

export default FormTextArea;
