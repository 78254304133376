import React from 'react';
import OptionLabel from '../optionLabel/optionLabel';
import styles from './dropdown.module.css';

const Dropdown = props => {
  return (
    <>
      <div className='form-group form-group-lg'>
        <label className={styles.inputLabel} htmlFor={props.labelHtmlFor}>
          {props.labelText}
        </label>
        <select
          className='form-control select'
          id='terapeutas'
          name='terapeutaSeleccionado'
          value={props.atrValue}
          onChange={props.changed}
        >
          <option defaultValue value={null}></option>
          {props.data.map((terapeuta, index) => (
              <OptionLabel
                key={index}
                text={terapeuta.nombreCompleto}
                value={terapeuta._id}
              />
            ))}
        </select>
      </div>
    </>
  );
};

export default Dropdown;
