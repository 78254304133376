import React from "react";
import PropTypes from "prop-types";
import styles from "./customToolbar.module.css";
import { MdDelete } from "react-icons/md";

const CustomToolbar = (props) => {
  return (
    <div id="toolbar" style={{ borderBottom: "1px solid #dee2e6" }}>
      <div style={{ display: "flex", flex: "10", justifyContent: "space-evenly" }}>
        <button className="ql-header" value="1"></button>
        <button className="ql-header" value="2"></button>
        <button className="ql-header" value="3"></button>
        <button className="ql-bold"></button>
        <button className="ql-italic"></button>
        <button className="ql-underline"></button>
        <button className="ql-list" value="ordered"></button>
        <button className="ql-list" value="bullet"></button>
      </div>
      <button className={`ql-clear`} disabled={props.clearDisabled} style={{ display: "flex", flex: "1", justifySelf: "ml-auto", cursor: "default" }}>
        <MdDelete
          className={`ml-auto ${props.clearDisabled ? styles.disabledClear : styles.activeClear}`}
          style={{ height: "18px", width: "18px" }}
        />
      </button>
    </div>
  );
};

CustomToolbar.propTypes = {
  clearDisabled: {
    type: PropTypes.bool,
  },
};

export default CustomToolbar;
