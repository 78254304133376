import React from "react";
import PropTypes from "prop-types";
import Box from "../../Globales/box/Box";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./confirmationMailPassword.module.css";

const ConfirmationMailPassword = (props) => {
  return (
    <Container>
      <Box>
        <Image src={props.imageSrc || "/images/confirmationIllustration.svg"} className={styles.imgClass} alt="ilustracion" />
        <Container fluid>
          <Row>
            <Col xs={12}>
              <h4 className={styles.title}>{props.title || "Solicitud exitosa"}</h4>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className={styles.content}>
                Se ha enviado <strong>un correo a tu casilla</strong> para continuar con el proceso.
              </p>
              <p className={styles.content}>
                Tenés <strong>1 hora para completar el proceso</strong>, de lo contrario deberás solicitar nuevamente un cambio de contraseña.
              </p>
              {/* <p className={styles.content}>
                <Link to="/ingresar">
                  <a href="#">Volver al inicio</a>
                </Link>
              </p> */}
            </Col>
          </Row>
        </Container>
      </Box>
    </Container>
  );
};

ConfirmationMailPassword.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ConfirmationMailPassword;

// /images/confirmationIllustration.svg
// El usuario se ha creado con éxito
