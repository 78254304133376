import React from 'react';
import styles from './formButton.module.css';
import Spinner from '../spinner/spinner';

const FormButton = props => {
  let btnParticularStyle;
  let btnSize;
  let btnSpinner;

  switch (props.buttonStyle) {
    case 'principal':
      btnParticularStyle = ' btn btn-primary btn-block';
      break;
    case 'secundario':
      btnParticularStyle = ' btn btn-outline-primary btn-block';
      break;
    case 'secundario-outlined':
      btnParticularStyle = ' btn btn-outline-secondary btn-block';
      break;
    default:
      btnParticularStyle = ' btn btn-primary btn-block';
      break;
  }

  switch (props.buttonSize) {
    case 'large':
      btnSize = styles.btnSizeLarge;
      break;
    case 'medium':
      btnSize = styles.btnSizeMedium;
      break;
    case 'small':
      btnSize = styles.btnSizeSmall;
      break;
    default:
      btnSize = styles.btnSizeLarge;
      break;
  }

  switch (props.spinnerType) {
    case 'white':
      btnSpinner = 'forButtonWhite';
      break;
    case 'blue':
      btnSpinner = 'forButtonBlue';
      break;
    default:
      btnSpinner = 'forButtonWhite';
      break;
  }
  return (
    <>
      <div className='form-group'>
        <button
          style={{cursor: props.atrDisabled ? 'not-allowed' : 'pointer'}}
          type={props.buttonType || 'button'}
          name={props.atrName}
          value={props.buttonValue || 'props.value'}
          onClick={props.clicked}
          disabled={props.atrDisabled}
          className={
            styles.btnGeneralStyle + ' ' + btnSize + btnParticularStyle
          }
        >
          {props.isSubmitting && (
            <> {props.spinnerType && <Spinner spinnerType={btnSpinner} />}</>
          )}
          {props.text || 'props.text'}
        </button>
      </div>
    </>
  );
};
export default FormButton;
