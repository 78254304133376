import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalPostFiles = props => {
  return (
    <div>
      <Modal show={props.isOpen} onHide={(event) => props.closeModal(event, false)} scrollable={true} onRequestClose={(event) => props.closeModal(event, false)} centered size='sm'>
        <Modal.Header closeButton>
            <Modal.Title>
                Archivos
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {props.children}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalPostFiles;
