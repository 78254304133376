import React from 'react';
import styles from './stepper.module.css';
import { MdCheck } from 'react-icons/md';

const Stepper = props => {
  return (
    <div className={styles.stepperContainer}>
      {props.steps.map((step, key) => (
        <React.Fragment key={key}>
          <div className={styles.stepWrapper} onClick={() => props.navigateSteps(key+1)}>
            <div
              className={
                key + 1 <= props.actualStep
                  ? styles.stepNumberWrapperBlue
                  : styles.stepNumberWrapperGrey
              }
            >
              <div>{key + 1 < props.actualStep ? <MdCheck /> : key + 1}</div>
            </div>
            <p
              className={
                key + 1 <= props.actualStep
                  ? styles.stepDescriptionBlue
                  : styles.stepDescriptionGrey
              }
              style={{ marginBottom: '0px' }}
            >
              {step.description}
            </p>
          </div>
          {!step.last ? (
            <div
              className={
                key + 1 < props.actualStep
                  ? styles.dividerLineBlue
                  : styles.dividerLineGrey
              }
            />
          ) : (
            <></>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Stepper;
