import React from 'react';
import FormStepButtons from '../formStepButtons/formStepButtons';

const FormStep = props => {
  return (
    <div>
      {props.children}
      <FormStepButtons
        first={props.first}
        last={props.last}
        handler={props.stepsHandler}
        isSubmitting={props.isSubmitting}
        textSubmitButton={props.textSubmitButton}
      />
    </div>
  );
};

export default FormStep;
