import React, { useContext } from 'react';
import { MdEdit } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';
import styles from './terapiaCard.module.css';
import FormButton from '../Globales/formButton/FormButton';
import { useHistory } from 'react-router-dom';
import {UserContext} from '../context/context';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {Animated} from 'react-animated-css'

const TerapiaCard = props => {
  let history = useHistory();
  
  const [user, setUser] = useContext(UserContext);
  const rolUsuario = user.rol;

  //Funcion que redirije a Editar Terapias
  const handleEdit = () => {
    history.push(
      {
      pathname: `/panel/terapias/${props.id}`,
      state: {
      titulo: props.titulo,
      terapeutas: props.terapeutas,
      descripcion: props.descripcion,
      colorHex: props.colorHex
      }
    })
  };

//Funcion que te manda al foro
  const handleRedirectToForo = () => {
    history.push(
      {
      pathname: `/panel/terapias/perfil/${props.id}`,
      state: {
        _id: props.id,
        titulo: props.titulo,
        descripcion: props.descripcion,
        colorHex: props.colorHex
      } 
    })
  };

//   background-color: rgba(255,255,255,1);
// background: @include filter-gradient(#ffffff, #55efc4, horizontal);
// background: @include background-image(linear-gradient(left, rgba(255,255,255,1) 0%, rgba(85,239,196,1) 38%, rgba(85,239,196,1) 100%));

  return (
    <>
    <Animated animationIn='fadeIn'>
      <div className={'card ' + styles.terapiaCard} style={{}} id={props.id}>
        <div style={{height: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', background: `linear-gradient(to right, rgba(255,255,255,1) -25%, ${props.colorHex} 38%, ${props.colorHex} 100%)`}}></div>
        <div className={'card-body ' + styles.terapiaContainer}>
          <h5 className={styles.titleBlockWithText + ' card-title'}>
            {props.titulo}
          </h5>

          {props.descripcion ? (
            <p className={'card-text ' + styles.descriptionBlockWithText}>
              {props.descripcion}
            </p>
          ) : (
            <p
              className={'card-text ' + styles.descriptionBlockWithText}
              style={{ fontStyle: 'italic' }}
            >
              (Sin descripción)
            </p>
          )}

          <div className={styles.terapiaButtons + ''}>
            <div style={{ width: '60%' }}>
              <FormButton
                type='button'
                buttonStyle='secundario'
                text='IR AL FORO'
                clicked={handleRedirectToForo}
              />
            </div>
            {rolUsuario !== 'Terapeuta' && (
              <div className={styles.buttonsContainer}>
                <button className={styles.customButton}>
                  <MdEdit
                    color='#7f7f7f'
                    size={25}
                    className={styles.customSvg + ' ' + styles.customSvgEdit}
                    onClick={handleEdit}
                  />
                </button>
                {rolUsuario === 'Admin' ? (
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip>
                        La terapia podrá ser eliminada únicamente por un usuario
                        con rol Superadmin
                      </Tooltip>
                    }
                  >
                    <button className={styles.customButton} disabled>
                      <IoMdTrash
                        color='#dbdbdb'
                        size={25}
                        className={styles.customSvg}
                      />
                    </button>
                  </OverlayTrigger>
                ) : (
                  <button className={styles.customButton}>
                    <IoMdTrash
                      color='#7f7f7f'
                      size={25}
                      className={
                        styles.customSvg + ' ' + styles.customSvgDelete
                      }
                      onClick={() => props.clicked(props.id)}
                    />
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      </Animated>
    </>
  );
};

export default TerapiaCard;
