import React from 'react';
import styles from './supportButton.module.css';
import { FaWhatsapp } from 'react-icons/fa';

const SupportButton = props => {
  return (
    <>
      <div className='form-group'>
        <a
          href={props.linkSoporte}
          target='_blank'
          rel='noopener noreferrer'
          name={props.atrName}
          className={'btn btn-block ' + styles.btnGeneralStyle}
        >
          <FaWhatsapp
            size='16px'
            color='#25D366'
            style={{ marginRight: '6px' }}
          />
          {props.text}
        </a>
      </div>
    </>
  );
};
export default SupportButton;
