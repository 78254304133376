import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './modificacionPaciente.module.css';
import * as yup from 'yup';
import { useFormik } from 'formik';
import FormContainer from '../Globales/formContainer/FormContainer';
import Step1 from '../creacionPaciente/step1';
import Step2 from '../creacionPaciente/step2';
import Step3 from '../creacionPaciente/step3';
import Step4 from '../creacionPaciente/step4';
import Stepper from '../Globales/stepper/stepper';
import DashboardTitle from '../dashboardTitle/dashboardTitle';
import Spinner from '../Globales/spinner/spinner';
import BackButton from '../Globales/backButton/BackButton'
import _ from 'underscore';
import {
  obtenerPaciente,
  modificarPaciente,
  uploadPacienteArchivos,
  uploadPacienteAvatar
} from '../../utils/serverRequest';
import Alert from '../Globales/alertPropia/alertPropia';
import {Animated} from 'react-animated-css'

const generos = ['Femenino', 'Masculino'];
const parentescos = [
  'Padre/Madre',
  'Abuelo/a',
  'Hermano/a',
  'Tio/a',
  'Primo/a',
  'Representante legal'
];

let validationSchemaStep1 = yup.object().shape({
  nombreCompleto: yup.string().required('Este campo es requerido'),
  fechaNacimiento: yup.date().required('Este campo es requerido'),
  dni: yup.string().required('Este campo es requerido'),
  nacionalidad: yup.string().required('Este campo es requerido'),
  genero: yup
    .mixed()
    .oneOf(generos)
    .required('Este campo es requerido'),
  direccion: yup.string().required('Este campo es requerido'),
  localidad: yup.string().required('Este campo es requerido'),
  codigoPostal: yup.string().required('Este campo es requerido'),
  telefono1: yup.string(),
  telefono2: yup.string(),
  estaEscolarizado: yup.boolean(),
  informacionEscolar: yup.string(),
  obraSocial: yup.string().required('Este campo es requerido'),
  nombreTitular: yup.string().required('Este campo es requerido'),
  numeroTarjeta: yup.string().required('Este campo es requerido'),
  fechaVencimiento: yup.string(),
  codigoSeguridad: yup.string()
});

let validationSchemaStep2 = yup.object().shape({
  responsables: yup.array().of(
    yup.object().shape({
      nombreCompletoResponsable: yup
        .string()
        .required('Este campo es requerido'),
      emailResponsable: yup
        .string()
        .email('Ingrese un correo electrónico válido')
        .required('Este campo es requerido'),
      documentoResponsable: yup.string().required('Este campo es requerido'),
      parentesco: yup
        .mixed()
        .oneOf(parentescos)
        .required('Este campo es requerido'),
      telefonoResponsable1: yup.string().required('Este campo es requerido'),
      telefonoResponsable2: yup.string(),
      comentariosAdicionales: yup.string(),
      obraSocialResponsable: yup.string(),
      nombreTitularResponsable: yup.string(),
      numeroTarjetaResponsable: yup.string(),
      fechaVencimientoResponsable: yup.string(),
      codigoSeguridadResponsable: yup.string()
    })
  )
});

const schemaArray = [validationSchemaStep1, validationSchemaStep2];

const ModificacionPaciente = props => {
  const [actualFormStep, setFormStep] = useState(1);
  const [alertFail, setAlertFail] = useState(false);
  const [terapiasIniciales, setTerapiasIniciales] = useState([]);
  const [terapiasSeleccionadas, setTerapiasSeleccionadas] = useState([]);
  const [terapiaActual, setTerapiaActual] = useState('');
  // const [terapeutasIniciales, setTerapeutasIniciales] = useState([]);
  // const [terapeutaActual, setTerapeutaActual] = useState('');
  const [terapeutasSeleccionados, setTerapeutasSeleccionados] = useState([]);
  const [paciente, setPaciente] = useState({});
  const [isLoading, setSpinner] = useState(true);
  const [valoresIniciales, setValoresIniciales] = useState({});
  const [avatar, setAvatar] = useState('');
  const [newAvatar, setNewAvatar] = useState('');
  const [archivos, setArchivos] = useState([]);
  const [newArchivos, setNewArchivos] = useState([]);
  const [alertImage, setAlertImage] = useState(false);
  let history =useHistory();

  useEffect(() => {
    let buscarPaciente = async () => {
      let data = await obtenerPaciente(props.location.state.pacienteId);
      if (data.ok) {
        setPaciente(data.paciente);
        setAvatar(data.paciente.avatar);
        setArchivos(data.paciente.archivos);
        setValoresIniciales(getValoresInicialesWithUpdate(data.paciente));
        let terapiasNoAsignadas = data.terapias.filter(
          terapia =>
            !_.contains(terapia.pacientes, props.location.state.pacienteId)
        );
        setTerapiasIniciales(terapiasNoAsignadas);
        setTerapiasSeleccionadas(data.terapiasAsignadas);
        setTerapeutasSeleccionados(data.accesos);
        setSpinner(false);
      } else {
        setSpinner(false);
        alert(data.err);
      }
    };
    buscarPaciente();
  }, []);

  const handleCheckEscolarizado = e => {
    setFieldValue('estaEscolarizado', e.target.checked);
    if (e.target.checked === false) {
      setFieldValue('informacionEscolar', '');
    }
  };

  const getValoresInicialesWithUpdate = paciente => {
    let datosPaciente = {
      nombreCompleto: paciente.nombreCompleto || '',
      dni: paciente.dni || '',
      fechaNacimiento: paciente.fechaNacimiento || '',
      nacionalidad: paciente.nacionalidad || '',
      genero: paciente.genero || '',
      direccion: paciente.direccion || '',
      localidad: paciente.localidad || '',
      codigoPostal: paciente.codigoPostal || '',
      telefono1: paciente.telefono1 || '',
      telefono2: paciente.telefono2 || '',
      estaEscolarizado: paciente.estaEscolarizado || false,
      informacionEscolar: paciente.informacionEscolar || '',
      obraSocial: paciente.obraSocial || '',
      nombreTitular: paciente.nombreTitular || '',
      numeroTarjeta: paciente.numeroTarjeta || '',
      fechaVencimiento: paciente.fechaVencimiento || '',
      codigoSeguridad: paciente.codigoSeguridad || ''
    };

    let responsablesInitialValues = paciente.responsables.map(item => ({
      nombreCompletoResponsable: item.nombreCompleto || '',
      emailResponsable: item.email || '',
      telefonoResponsable1: item.telefono1 || '',
      telefonoResponsable2: item.telefono2 || '',
      documentoResponsable: item.documentoResponsable || '',
      parentesco: item.parentesco || '',
      comentariosAdicionales: item.comentariosAdicionales || '',
      obraSocialResponsable: item.obraSocial || '',
      nombreTitularResponsable: item.nombreTitular || '',
      numeroTarjetaResponsable: item.numeroTarjeta || '',
      fechaVencimientoResponsable: item.fechaVencimiento || '',
      codigoSeguridadResponsable: item.codigoSeguridad || ''
    }));

    let vIniciales = {
      ...datosPaciente,
      responsables: responsablesInitialValues
    };
    return vIniciales;
  };

  // useEffect(() => {
  //   let nuevosTerapeutasIniciales = terapiasSeleccionadas.flatMap(terapia =>
  //     terapia.terapeutas.map(terapeuta => {
  //       return { terapia: terapia._id, terapeuta };
  //     })
  //   );
  //   setTerapeutasIniciales([...nuevosTerapeutasIniciales]); // Para manejar un campo de iniciales en el estado
  // }, [terapiasSeleccionadas]);

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    isSubmitting,
    setFieldValue,
    validateForm
  } = useFormik({
    initialValues: { ...valoresIniciales },
    enableReinitialize: true,
    validationSchema: schemaArray[actualFormStep - 1],
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: async values => {
      let datosPaciente = {
        _id: props.location.state.pacienteId,
        nombreCompleto: values.nombreCompleto,
        dni: values.dni,
        fechaNacimiento: values.fechaNacimiento,
        nacionalidad: values.nacionalidad,
        genero: values.genero,
        direccion: values.direccion,
        localidad: values.localidad,
        codigoPostal: values.codigoPostal,
        telefono1: values.telefono1,
        telefono2: values.telefono2,
        estaEscolarizado: values.estaEscolarizado,
        informacionEscolar: values.informacionEscolar,
        obraSocial: values.obraSocial,
        nombreTitular: values.nombreTitular,
        numeroTarjeta: values.numeroTarjeta,
        fechaVencimiento: values.fechaVencimiento,
        codigoSeguridad: values.codigoSeguridad,
        avatar,
        archivos
      };

      let responsablesArray = values.responsables.map(item => ({
        nombreCompleto: item.nombreCompletoResponsable,
        email: item.emailResponsable,
        parentesco: item.parentesco,
        documentoResponsable: item.documentoResponsable,
        telefono1: item.telefonoResponsable1,
        telefono2: item.telefonoResponsable2,
        comentariosAdicionales: item.comentariosAdicionales,
        obraSocial: item.obraSocialResponsable,
        nombreTitular: item.nombreTitularResponsable,
        numeroTarjeta: item.numeroTarjetaResponsable,
        fechaVencimiento: item.fechaVencimientoResponsable,
        codigoSeguridad: item.codigoSeguridadResponsable
      }));

      let nuevoPaciente = { ...datosPaciente, responsables: responsablesArray };
      let terapias = terapiasSeleccionadas.map(t => t._id);
      let accesos = terapeutasSeleccionados.map(a => {
        return { ...a, terapeuta: a.terapeuta._id };
      });

      let data = await modificarPaciente({ nuevoPaciente, terapias, accesos });
      var dataAvatarReq;
      var dataArchivosReq;
      if (data.ok) {
        if (newAvatar) {
          dataAvatarReq = await enviarArchivo(data.paciente._id);
        }

        if (newArchivos.length > 0) {
          dataArchivosReq = await enviarArchivos(data.paciente._id);
        }

        if (dataAvatarReq && !dataAvatarReq.ok) {
          alert('No se ha guardado el avatar del paciente');
        }

        if (dataArchivosReq && !dataArchivosReq.ok) {
          alert('No se han guardado los archivos del paciente');
        }

        props.history.push({
          pathname: '/panel/pacientes',
          state: {
            alertSuccess: true,
            titulo: 'Modificación exitosa.',
            descripcion: `Se ha modificado al paciente ${values.nombreCompleto}`
          }
        });
      } else {
        switch (data.err.status) {
          case 400:
            alert('Error');
            setAlertFail(true);
            setTimeout(() => {
              setAlertFail(false);
            }, 4000);
            break;
          case 500:
            alert('Errir');
            setAlertFail(true);
            setTimeout(() => {
              setAlertFail(false);
            }, 2000);
            break;
          default:
            console.log(data);
            break;
        }
      }
    }
  });

  const aumentarDecrementarPaso = num => {
    let step = actualFormStep + num;
    if (num > 0 && step !== 4) {
      validateForm(values).then(res => {
        if (_.isEmpty(res)) {
          setFormStep(step);
        }
      });
    } else {
      setFormStep(step);
    }
  };

  const seleccionarTerapia = e => {
    e.preventDefault();
    setTerapiaActual(e.target.value);
  };

  const agregarTerapiaSeleccionada = e => {
    e.preventDefault();
    if (terapiaActual) {
      let nuevasTerapiasSeleccionadas = terapiasIniciales.filter(
        terapia => terapia._id === terapiaActual
      );
      let nuevasTerapiasIniciales = terapiasIniciales.filter(
        terapia => terapia._id !== terapiaActual
      );
      setTerapiasSeleccionadas([
        ...terapiasSeleccionadas,
        ...nuevasTerapiasSeleccionadas
      ]);
      setTerapiasIniciales(nuevasTerapiasIniciales);
      setTerapiaActual('');
    }
  };

  const agregarTerapeutasSeleccionados = nuevoAcceso => {
    let nuevoTerapeuta = {
      terapia: nuevoAcceso.terapia,
      terapeuta: terapiasSeleccionadas
        .filter(terapia => terapia._id === nuevoAcceso.terapia)
        .flatMap(terapia => terapia.terapeutas)
        .find(terapeuta => terapeuta._id === nuevoAcceso.terapeuta),
      tipoAcceso: nuevoAcceso.tipoAcceso
    };
    let nuevosTerapeutasSeleccionados = [
      ...terapeutasSeleccionados,
      nuevoTerapeuta
    ];
    setTerapeutasSeleccionados(nuevosTerapeutasSeleccionados);
  };

  const removerTerapiaSeleccionada = idTerapia => {
    let nuevasTerapiasIniciales = terapiasSeleccionadas.filter(
      terapia => terapia._id === idTerapia
    );
    let nuevasTerapiasSeleccionadas = terapiasSeleccionadas.filter(
      terapia => terapia._id !== idTerapia
    );
    setTerapiasIniciales([...terapiasIniciales, ...nuevasTerapiasIniciales]);
    setTerapiasSeleccionadas(nuevasTerapiasSeleccionadas);
  };

  const removerAccesoSeleccionado = acceso => {
    let index = terapeutasSeleccionados.findIndex(
      tt =>
        tt.terapia === acceso.terapia && tt.terapeuta._id === acceso.terapeuta
    );
    let nuevosTerapeutasSeleccionados = [...terapeutasSeleccionados];
    nuevosTerapeutasSeleccionados.splice(index, 1);
    setTerapeutasSeleccionados(nuevosTerapeutasSeleccionados);
  };

  const addImage = e => {
    e.preventDefault();
    let nuevoAvatar = e.target.files[0];
    if (isImage(nuevoAvatar.name)) {
      setAvatar('');
      setNewAvatar(nuevoAvatar);
    } else {
      setAlertImage(true);
      setTimeout(() => {
        setAlertImage(false);
      }, 5000);
    }
    // Para poder volver a cargar el mismo archivo después de sacarlo
    e.target.value = '';
  };

  const isImage = fileName => {
    let extension = fileName
      .substring(fileName.lastIndexOf('.') + 1)
      .toLowerCase();

    switch (extension) {
      case 'jpg':
      case 'png':
      case 'jpeg':
        return true;
      default:
        return false;
    }
  };

  const removeImage = e => {
    setAvatar('');
    setNewAvatar('');
  };

  const addArchivos = e => {
    // archivos es un Array de Strings (Paths de los files)
    let nuevosArchivos = [...e.target.files].filter(
      file =>
        !archivos.some(
          archivo =>
            archivo.substring(archivo.lastIndexOf('/') + 1) === file.name
        )
    );
    setNewArchivos([...newArchivos, ...nuevosArchivos]);
  };

  const removerArchivo = fileName => {
    let nuevosArchivos = [...archivos].filter(archivo => archivo !== fileName);
    setArchivos(nuevosArchivos);
  };

  const removerNewArchivo = fileName => {
    let nuevosArchivos = [...newArchivos].filter(
      archivo => archivo.name !== fileName
    );
    setNewArchivos(nuevosArchivos);
  };

  const enviarArchivo = async pacienteId => {
    let formData = new FormData();
    formData.append('Archivos', newAvatar);
    let data = await uploadPacienteAvatar(formData, pacienteId);
    return data;
  };

  const enviarArchivos = async pacienteId => {
    let formData = new FormData();
    newArchivos.forEach(archivo => formData.append('Archivos', archivo));
    let data = await uploadPacienteArchivos(formData, pacienteId);
    return data;
  };

  const stepsForStepper = [
    { description: 'DATOS DEL PACIENTE', last: false },
    { description: 'DATOS DE RESPONSABLES', last: false },
    { description: 'ASIGNACIÓN DE TERAPIAS', last: false },
    { description: 'ASIGNACIÓN DE TERAPEUTAS', last: true }
  ];
  var actualStep;
  var tituloActual;

  switch (actualFormStep) {
    case 1:
      tituloActual = 'Datos del paciente';
      actualStep = (
        // <Animated animationOut="fadeOut">
        <Step1
          title={tituloActual}
          handleChange={handleChange}
          values={values}
          errors={errors}
          aumentarDecrementarPaso={aumentarDecrementarPaso}
          avatar={avatar}
          newAvatar={newAvatar}
          addImage={addImage}
          removeImage={removeImage}
          archivos={archivos}
          newArchivos={newArchivos}
          addArchivos={addArchivos}
          handleRemoverArchivo={removerArchivo}
          handleRemoverNewArchivo={removerNewArchivo}
          handleCheckEscolarizado={handleCheckEscolarizado}
        />
        //</Animated>
      );
      break;
    case 2:
      tituloActual = 'Datos de responsables';
      actualStep = (
        // <Animated animationIn="fadeIn" animationOut="fadeOut">
        <Step2
          title={tituloActual}
          handleChange={handleChange}
          values={values}
          errors={errors}
          aumentarDecrementarPaso={aumentarDecrementarPaso}
          dataResponsablesPaciente={paciente.responsables}
        />
      //</Animated> 
      );
      break;
    case 3:
      tituloActual = 'Asignación de terapias';
      actualStep = (
        <Step3
          title={tituloActual}
          handleChange={handleChange}
          values={values}
          errors={errors}
          aumentarDecrementarPaso={aumentarDecrementarPaso}
          terapiasIniciales={terapiasIniciales}
          agregarTerapias={agregarTerapiaSeleccionada}
          removerTerapias={removerTerapiaSeleccionada}
          selectItem={seleccionarTerapia}
          selectedItems={terapiasSeleccionadas}
          terapiaActual={terapiaActual}
        />
      );
      break;
    case 4:
      tituloActual = 'Asignación de terapeutas';
      actualStep = (
        <Step4
          title={tituloActual}
          handleChange={handleChange}
          values={values}
          errors={errors}
          aumentarDecrementarPaso={aumentarDecrementarPaso}
          terapiasSeleccionadas={terapiasSeleccionadas}
          agregarTerapeutas={agregarTerapeutasSeleccionados}
          removerAcceso={removerAccesoSeleccionado}
          selectedItems={terapeutasSeleccionados}
          isSubmitting={isSubmitting}
          textSubmitButton='MODIFICAR PACIENTE'
        />
      );
      break;
    default:
      actualStep = <></>;
  }
   //funcion para el backButton
   const goBack = () => {
    history.goBack()
  }

  const navigateSteps = (stepNum) => {
    validateForm(values).then(res => {
      if(_.isEmpty(res)){
        setFormStep(stepNum)
      }
    })
  }


  return (
    <div className='dashboardContainer'>
      <Animated animationIn="fadeIn">
        <div style={{display:'flex', flexDirection:'row'}}>
          <BackButton onClick = {goBack }/>
          <DashboardTitle title='Modificar paciente' />
        </div>
      </Animated>
      {alertFail && (
        <Alert variant='danger' show={alertFail}>
          <strong>Creación Fallida </strong>
          No se ha podido crear a {' ' + values.nombreCompleto}
        </Alert>
      )}
      {alertImage && (
        <Alert variant='danger' show={alertImage}>
          <strong>Archivo no válido.</strong> Seleccione un archivo con
          extensión <strong>JPG</strong>, <strong>JPEG</strong> o{' '}
          <strong>PNG</strong>
        </Alert>
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div
            className={
              'col-lg-9 col-md-12 col-sm-12 col-xs-12 container ' +
              styles.stepperMobile
            }
          >
            <h5 style={{ textAlign: 'left', marginBottom: '0px' }}>
              {'Paso ' + actualFormStep + ' de ' + stepsForStepper.length}
            </h5>
          </div>
          <div
            className={
              'col-lg-9 col-md-12 col-sm-12 col-xs-12 container ' +
              styles.stepperWeb
            }
          >
            <Animated animationIn="fadeIn">
              <Stepper actualStep={actualFormStep} steps={stepsForStepper} navigateSteps={navigateSteps}/>
            </Animated>
          </div>
          <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 container'>
          <Animated animationIn="fadeIn">
            <FormContainer title={tituloActual} clicked={handleSubmit}>
              {actualStep}
            </FormContainer>
            </Animated>
          </div>
        </>
      )}
    </div>
  );
};

export default ModificacionPaciente;
