import React from "react";
import styles from "./modalForEditor.module.css";
import { Modal, Button } from "react-bootstrap";
import Spinner from "../spinner/spinner";
import "./modalForEditor.module.css";
import "./modalContent.css";

const ModalForEditor = (props) => {
  return (
    <Modal
      id="modalForEditorContainer"
      className={`${styles.modalPropio}`}
      show={props.isOpen}
      onHide={() => props.closeModal(false)}
      centered={props.centered || false}
      dialogClassName={"modalTarget"}
    >
      <div
        style={{
          height: "6px",
          borderTopLeftRadius: "3px",
          borderTopRightRadius: "3px",
          background: `linear-gradient(to right, rgba(255,255,255,1) -25%, #167bff 38%, #167bff 100%)`,
        }}
      ></div>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/* <p>{props.content}</p> */}
        {props.children}
      </Modal.Body>

      <Modal.Footer className={props.keyboardOpened && props.onMobile() ? "d-none" : ""}>
        <Button variant="outline-secondary" onClick={() => props.closeModal(false)} style={{ fontSize: "13px", fontWeight: "700" }}>
          {props.botonSecundario.toUpperCase()}
        </Button>
        <Button
          variant={props.danger ? "danger" : "primary"}
          onClick={props.okHandler}
          disabled={props.isSubmitting || props.disabledButton}
          style={{ fontSize: "13px", fontWeight: "700" }}
        >
          {props.isSubmitting ? <Spinner spinnerType="forButtonBlue" /> : props.botonPrimario.toUpperCase()}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalForEditor;
