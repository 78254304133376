//Packages & Utils
import React, {useState} from 'react';
import styles from './groupedPosts.module.css'

// Components
import Post from '../post/Post';
import ButtonsTab from '../buttonsTab/ButtonsTab';
import EmptyState from '../emptyState/emptyState';
import Spinner from '../spinner/spinner';
import FiltroButton from '../../filtroButton/filtroButton'
import FiltrosPost from '../../filtrosPost/filtrosPost'
import ModalPropio from '../modal/modalPropio'
import Alert from 'react-bootstrap/Alert'
import {MdClose} from 'react-icons/md'


const GroupedPosts = React.forwardRef((props, ref) => {

  const [modalFiltros, setModalFiltros] = useState(false);
  const [terapeutasSeleccionados, setTerapeutasSeleccionados] = useState([])
  const [fechaMin, setFechaMin] = useState('')
  const [fechaMax, setFechaMax] = useState('')
  // Para trabar el botón del modal de filtros
  const [cambioFiltro, setCambioFiltro] = useState(false)

  const user = JSON.parse(localStorage.getItem('user'))

  const handleClickFilter = terapia => {
    let terapiaSelec = terapia;
    props.setTerapiaSeleccionada(terapiaSelec);
  };

  const handleFiltrar = () => {
    setModalFiltros(false)
    props.handleFiltrar(terapeutasSeleccionados, fechaMin, fechaMax)
  }

  const closeModalFiltros = (terapeutasSeleccionadosIniciales, fechaMinInicial, fechaMaxInicial) => {
    setTerapeutasSeleccionados(terapeutasSeleccionadosIniciales)
    setFechaMin(fechaMinInicial)
    setFechaMax(fechaMaxInicial)
    setModalFiltros(false)
  }

  const filtrosVacios = () => {
    return terapeutasSeleccionados.length < 1 && !fechaMax && !fechaMin
  }

  const removerCreadorFiltrado = (creador) => {
    let nuevosCreadoresFiltrados = terapeutasSeleccionados.filter(t => t._id !== creador._id)
    setTerapeutasSeleccionados(nuevosCreadoresFiltrados);
    props.handleFiltrar(nuevosCreadoresFiltrados, fechaMin, fechaMax)
  }

  return (
    <>
      {props.isLoading ? (
        <Spinner />
      ) : (
          <>
            {props.data.length === 0 && filtrosVacios() ? (
              <>
                {props.displayFilters ? (
                  <>
                    <div className={styles.fadeIn}>
                      <ButtonsTab
                        data={props.terapiasActivas}
                        clicked={handleClickFilter}
                        tseleccionada={props.terapiaSeleccionada}
                        stopSpinnerFilterTerapia={() =>
                          props.setIsLoadingFilterTerapia(false)
                        }
                      />
                    </div>
                    {props.isLoadingFilterByTerapia && <Spinner />}
                    
                    {props.terapiaSeleccionada !== '' ? (
                      <>
                        {!props.isLoadingFilterByTerapia && (
                          <div style={{ paddingTop: '40px' }}>
                            <EmptyState
                              ruta='/images/blank_canvas.svg'
                              subtitulo={`Aún no hay posts creados en la terapia seleccionada`}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                        <>
                          {!props.isLoadingFilterByTerapia && (
                            <div style={{ paddingTop: '40px' }}>
                              <EmptyState ruta='/images/blank_canvas.svg' subtitulo='Aún no hay posts creados para este paciente'/>
                            </div>
                          )}
                        </>
                      )}
                  </>
                ) : (
                    <>
                      {!props.isLoadingFisLoadingFilterByTerapia && (
                        <div style={{ paddingTop: '40px' }}>
                          <EmptyState ruta='/images/blank_canvas.svg' subtitulo='Aún no hay posts creados para esta terapia'/>
                        </div>
                      )}
                    </>
                  )}
              </>
            ) : (
                <div>
                  {props.displayFilters && (
                    <div className={styles.fadeIn}>
                      <ButtonsTab data={props.terapiasActivas} clicked={handleClickFilter} tseleccionada={props.terapiaSeleccionada}/>
                    </div>
                  )}
                  <div style={{display: 'flex', alignItems: 'center', margin: '24px 0'}}>
                    { props.spinnerQueryParams ? !filtrosVacios() && <div className="mr-4">
                      <FiltroButton size="20" color="white" onClick={() => setModalFiltros(true)}/>
                    </div>
                    : <div className="mr-4">
                      <FiltroButton size="20" color="white" onClick={() => setModalFiltros(true)}/>
                    </div>}
                    <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: '-8px'}}>
                    {props.queryParams.creadores.map((cr, key) => <Alert key={key} className="mr-3" style={{fontSize: '16px', padding: '4px 8px', margin: '0px 0px 8px 0px', backgroundColor: '#fff', maxHeight: '34px'}}><p className="d-inline-block mr-2 mb-0">{cr.nombreCompleto}</p><MdClose style={{cursor: 'pointer'}} onClick={() => {removerCreadorFiltrado(cr)}}/></Alert>) } 
                      {props.queryParams.fechaMin && <Alert className="mr-3" style={{fontSize: '16px', padding: '4px 8px', margin: '0px 0px 8px 0px', backgroundColor: '#fff', maxHeight: '34px'}} closeLabel><p className="d-inline-block mr-2 mb-0">Desde: {`${props.queryParams.fechaMin.getDate()}/${props.queryParams.fechaMin.getMonth()+1}/${props.queryParams.fechaMin.getFullYear()}`}</p><MdClose style={{cursor: 'pointer'}} onClick={() => {setFechaMin(''); props.handleFiltrar(terapeutasSeleccionados, '', fechaMax)} }/></Alert>}
                      {props.queryParams.fechaMax && <Alert className="mr-3" style={{fontSize: '16px', padding: '4px 8px', margin: '0px 0px 8px 0px', backgroundColor: '#fff', maxHeight: '34px'}} closeLabel><p className="d-inline-block mr-2 mb-0">Hasta: {`${props.queryParams.fechaMax.getDate()}/${props.queryParams.fechaMax.getMonth()+1}/${props.queryParams.fechaMax.getFullYear()}`}</p><MdClose style={{cursor: 'pointer'}} onClick={() => {setFechaMax(''); props.handleFiltrar(terapeutasSeleccionados, fechaMin, '')} }/></Alert>}
                      {!filtrosVacios() && <Alert className={`${styles.borrarTodosFiltros}`} onClick={() => {setTerapeutasSeleccionados([]); setFechaMin(''); setFechaMax(''); props.handleFiltrar([], '', '')}}>Quitar filtros</Alert>}
                    </div>
                  </div>
                  <>
                    {props.isLoadingFilterByTerapia ? (
                      <Spinner />
                    ) : (
                        <>
                        {
                          modalFiltros &&
                          
                          <ModalPropio
                            okHandler={() => {handleFiltrar(); setCambioFiltro(false)}}
                            isOpen={modalFiltros}
                            closeModal={() => closeModalFiltros(props.queryParams.creadores, props.queryParams.fechaMin, props.queryParams.fechaMax)}
                            title='Filtrar'
                            botonSecundario='Cancelar'
                            botonPrimario='Filtrar'
                            // disabledButton={!cambioFiltro}
                          >
                            <FiltrosPost
                              terapeutasInicialesInmutables={props.creadoresDePosts}
                              terapeutasIniciales={props.creadoresDePosts.filter(cr => !terapeutasSeleccionados.some(t => t._id === cr._id))}
                              terapeutasSeleccionados={terapeutasSeleccionados}
                              fechaMin={fechaMin}
                              setFechaMin={setFechaMin}
                              fechaMax={fechaMax}
                              setFechaMax={setFechaMax}
                              setTerapeutasSeleccionados={setTerapeutasSeleccionados}
                              setCambioFiltro={setCambioFiltro}
                            />
                          </ModalPropio>
                        }

                          {
                            
                            props.spinnerQueryParams ? <Spinner />
                            
                            : props.data && props.data.length > 0 ? props.data.map((post, index) => {
                            return props.data.length === index + 1
                              ? <Post
                                feed={props.feed}
                                key={post._id}
                                avatarTerapeuta={post.creador.avatar}
                                idCreador={post.creador._id}
                                nombreTerapeuta={post.creador.nombreCompleto}
                                antiguedadPost={post.fechaCreacion}
                                textoPost={post.contenido}
                                rawContent={post.rawContent}
                                areLoadingFiles={props.nuevoPostId === post._id ? props.areLoadingFiles : false}
                                imagenesPost={post.imagenes || []}
                                videosPost={post.videos || []}
                                archivosPost={post.archivos || []}
                                nombreTerapia={props.feed === 'paciente' ? post.terapia.titulo : ''}
                                idPost={post._id}
                                idPaciente={props.idPaciente || ''}
                                idTerapia={props.idTerapia || ''}
                                colorTerapia={props.colorHex || post.terapia.colorHex}
                                modalEliminar={props.handleModalEliminarPost}
                                tieneComentarios={post.cantidadComentarios > 0 ? true : false}
                                cantidadComentarios={post.cantidadComentarios}
                                ref={ref}
                                possibleMentions={props.possibleMentions}
                                views={post.views}
                                isViewed={post.views && post.views.some(v => v.viewer.id === user.id)}
                                reacciones={post.reacciones || []} // Las que tiene el post [[':smile:', [PostReaccion]], [':open_mouth', [PostReaccion], ...n]
                                reaccionesPosibles={post.reaccionesPosibles || []} // [Reaccion] --> Las reacciones 'puras' (Con titulo, emoji code)
                                dioReaccion={post.dioReaccion}
                                reaccionDada={post.reaccionDada}
                                postReaccion={post.postReaccion}
                                defaultReaction={post.defaultReaction}
                                cantidadReacciones={post.cantidadReacciones}
                              />
                              :
                              <Post
                                feed={props.feed}
                                key={post._id}
                                avatarTerapeuta={post.creador.avatar}
                                idCreador={post.creador._id}
                                nombreTerapeuta={post.creador.nombreCompleto}
                                antiguedadPost={post.fechaCreacion}
                                textoPost={post.contenido}
                                rawContent={post.rawContent}
                                areLoadingFiles={props.nuevoPostId === post._id ? props.areLoadingFiles : false}
                                imagenesPost={post.imagenes || []}
                                videosPost={post.videos || []}
                                archivosPost={post.archivos || []}
                                nombreTerapia={props.feed === 'paciente' ? post.terapia.titulo : ''}
                                idPost={post._id}
                                idPaciente={props.idPaciente || ''}
                                idTerapia={props.idTerapia || ''}
                                colorTerapia={props.colorHex || post.terapia.colorHex}
                                modalEliminar={props.handleModalEliminarPost}
                                tieneComentarios={post.cantidadComentarios > 0 ? true : false}
                                cantidadComentarios={post.cantidadComentarios}
                                possibleMentions={props.possibleMentions}
                                views={post.views}
                                isViewed={post.views && post.views.some(v => v.viewer.id === user.id)}
                                reacciones={post.reacciones || []} // Las que tiene el post [[':smile:', [PostReaccion]], [':open_mouth', [PostReaccion], ...n]
                                reaccionesPosibles={post.reaccionesPosibles || []} // [Reaccion] --> Las reacciones 'puras' (Con titulo, emoji code)
                                dioReaccion={post.dioReaccion}
                                reaccionDada={post.reaccionDada}
                                postReaccion={post.postReaccion}
                                defaultReaction={post.defaultReaction}
                                cantidadReacciones={post.cantidadReacciones}
                              />
                          }) 
                          : <EmptyState ruta='/images/blank_canvas.svg' subtitulo='No se encontraron posts con los filtros seleccionados'/>}
                        </>
                      )}
                  </>
                </div>
              )}
          </>
        )}
    </>
  );
});

export default GroupedPosts;
