import React from 'react';
import styles from './buscador.module.css';
import { FaSearch } from 'react-icons/fa';

const Buscador = props => {
  return (
    <div className={styles.buscadorContainer}>
      <input
        className={styles.buscador}
        type='text'
        placeholder={props.placeholder}
        value={props.valor}
        onChange={props.handler}
      />
      <div className={styles.icon}>
        <FaSearch size='16px' color='#6f6f6f' style={{ marginRight: '12px' }} />
      </div>
    </div>
  );
};

export default Buscador;
