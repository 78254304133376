import React from 'react';
import styles from './formInputFile.module.css';

const FormInputFile = props => {
  return (
    <div class='form-group' style={{ marginBottom: '0px' }}>
      <label for={props.id} className={styles.btnBootstrap}>
        {props.text}
      </label>
      <input
        id={props.id}
        type='file'
        tabIndex='0'
        multiple={props.multiple}
        onChange={props.onChange}
      />
    </div>
  );
};

export default FormInputFile;
