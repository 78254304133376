import React, { useState, useEffect, useContext } from "react";
import styles from "./modalEditor.module.css";
import QuilEditor from "../quilEditor/quilEditor";
import ModalForEditor from "../modalForEditor/modalForEditor";
import PostMakerFiles from "../postMakerFiles/postMakerFiles";
import _ from "underscore";
import { Prompt } from "react-router";

const ModalEditor = (props) => {
  const [dataPost, setDataPost] = useState({
    contenido: "",
    mentions: [],
    plainText: "",
    terapia: props.idTerapia,
  });
  const [keyboardOpened, setkeyboardOpened] = useState(false);
  const [shouldBlockNavigation, setshouldBlockNavigation] = useState(false);

  const handleChange = (contenido, mentions, plainText) => {
    setDataPost({
      ...dataPost,
      contenido,
      mentions,
      plainText,
    });

    if (contenido !== "" && contenido !== "<p><br></p>") {
      setshouldBlockNavigation(true);
    } else {
      setshouldBlockNavigation(false);
    }
  };

  const handleSelect = (event) => {
    setDataPost({
      ...dataPost,
      terapia: event.target.value,
    });
  };

  const onMobile = () => {
    return window.innerWidth <= 768;
  };

  const handleSubmitPost = async () => {
    if (props.dataPost.archivos.length > 0) {
      let formData = new FormData();
      props.dataPost.archivos.forEach((archivo) => formData.append("Archivos", archivo));

      await props.handleSubmitPost(dataPost, formData, () =>
        setDataPost({
          contenido: "",
          mentions: [],
          plainText: "",
        })
      );
    } else {
      await props.handleSubmitPost(dataPost, "", () =>
        setDataPost({
          contenido: "",
          mentions: [],
          plainText: "",
        })
      );
    }
  };

  const onCloseModal = () => {
    props.setDataPost({
      ...props.dataPost,
      plainText: dataPost.plainText,
    });

    props.closeModal();
  };

  const clearEditor = () => {
    setDataPost({
      contenido: "",
      mentions: [],
      plainText: "",
    });

    props.setDataPost({
      ...props.dataPost,
      archivos: [],
    });
  };

  return (
    <ModalForEditor
      title={props.title}
      isOpen={props.modalPostEditor}
      danger={false}
      closeModal={onCloseModal}
      okHandler={handleSubmitPost}
      isSubmitting={props.isSubmitting}
      botonPrimario="Publicar"
      botonSecundario="Cancelar"
      centered={true}
      keyboardOpened={keyboardOpened}
      onMobile={onMobile}
      disabledButton={!dataPost.contenido || dataPost.contenido === "<p><br></p>"}
    >
      <Prompt when={shouldBlockNavigation} message="Hay cambios sin guardar, se perderán al salir" />
      <QuilEditor
        value={dataPost.contenido}
        handleChange={handleChange}
        placeholder={props.placeholder || "Ingrese acá el texto"}
        possibleMentions={props.possibleMentions}
        setKeyboardOpened={setkeyboardOpened}
        keyboardOpened={keyboardOpened}
        clearEditor={clearEditor}
        disabledButton={(!dataPost.contenido || dataPost.contenido === "<p><br></p>") && props.dataPost.archivos.length === 0}
      />
      <div className={keyboardOpened && onMobile() ? "d-none" : "d-flex justify-content-between align-items-center"}>
        <PostMakerFiles
          archivos={props.dataPost.archivos}
          addFiles={props.addFiles}
          removeFiles={props.removeFiles}
          filesQty={props.dataPost.archivos.length}
        />
        {props.feed === "paciente" && props.terapiasTerapeutaEscritura.length > 0 && (
          <div className={styles.buttonsSameLine}>
            <select className={styles.selectClass + " form-control"} id="exampleFormControlSelect1" onChange={handleSelect}>
              {props.terapiasTerapeutaEscritura.map((item, key) => (
                <option key={key} value={item._id}>
                  {item.titulo}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    </ModalForEditor>
  );
};

export default ModalEditor;
