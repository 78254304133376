import React from "react";
import { MdClose } from "react-icons/md";
import { FaFileExcel, FaFileCsv, FaFilePdf, FaFilePowerpoint, FaFileArchive, FaFileWord, FaFile, FaFileImage } from "react-icons/fa";

const FilePreview = (props) => {
  let tag = props.link ? (
    <>
      <a href={props.presignedUrl} target="_blank" rel="noopener noreferrer" style={{ margin: "0 1rem", verticalAlign: "middle", fontWeight: "400" }}>
        {props.file.name || props.originalLink || props.file.substring(props.file.lastIndexOf("/") + 1)}
      </a>
    </>
  ) : (
    <>
      <a href="" style={{ margin: "0 1rem", verticalAlign: "middle", fontWeight: "400" }}>
        {props.file.name || "Link archivo"}
      </a>
    </>
  );

  let removeButton = (
    <div style={{ marginRight: "8px" }}>
      <MdClose color="red" style={{ cursor: "pointer" }} onClick={() => props.handleRemoverArchivo(props.file.name || props.file)} />
    </div>
  );

  let extension = props.file.name
    ? props.file.name.substring(props.file.name.lastIndexOf(".") + 1).toLowerCase()
    : props.file.substring(props.file.lastIndexOf(".") + 1).toLowerCase();

  let extensionIcon;
  switch (extension) {
    case "pdf":
      extensionIcon = <FaFilePdf color="red" />;
      break;
    case "xls":
      extensionIcon = <FaFileExcel color="green" />;
      break;
    case "xlsx":
      extensionIcon = <FaFileExcel color="green" />;
      break;
    case "csv":
      extensionIcon = <FaFileCsv color="green" />;
      break;
    case "ppt":
      extensionIcon = <FaFilePowerpoint color="red" />;
      break;
    case "word":
      extensionIcon = <FaFileWord color="blue" />;
      break;
    case "docx":
      extensionIcon = <FaFileWord color="blue" />;
      break;
    case "zip":
      extensionIcon = <FaFileArchive color="grey" />;
      break;
    case "rar":
      extensionIcon = <FaFileArchive color="grey" />;
      break;
    case "jpg":
      extensionIcon = <FaFileImage color="grey" />;
      break;
    case "jpeg":
      extensionIcon = <FaFileImage color="grey" />;
      break;
    case "png":
      extensionIcon = <FaFileImage color="grey" />;
      break;
    default:
      extensionIcon = <FaFile color="grey" />;
      break;
  }

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "flex-start",
        verticalAlign: "middle",
        alignItems: "center",
      }}
    >
      {removeButton}
      {extensionIcon}
      {tag}
    </li>
  );
};

export default FilePreview;
