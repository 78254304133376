import React, { useState, useEffect } from 'react';
import FormContainer from '../Globales/formContainer/FormContainer';
import Alert from '../Globales/alertPropia/alertPropia';
import Dropdown from '../Globales/dropdown/dropdown';
import FormButton from '../Globales/formButton/FormButton';
import FormInput from '../Globales/formInput/FormInput';
import BackButton from '../Globales/backButton/BackButton';
import InputMessage from '../Globales/inputMessage/InputMessage';
import DashboardTitle from '../dashboardTitle/dashboardTitle';
import styles from './editarTerapias.module.css';
import { MdClose } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  obtenerTerapeutasPreview,
  editarTerapia
} from '../../utils/serverRequest';
import { differenceArrayObjectsWithId2 } from '../../utils/utils';
import FormTextArea from '../Globales/formTextArea/FormTextArea';
import ColorPicker from '../Globales/colorPicker/colorPicker'
import {Animated} from 'react-animated-css'

const EditarTerapias = props => {
  const { id } = props.match.params;
  const { titulo, descripcion, terapeutas, colorHex } = props.location.state;
  const [serverError, setServerError] = useState(false);
  const [serverErrorDescription, setServerErrorDescription] = useState('');
  const [isOpenAlertSuccess, setAlertSucces] = useState(false);
  const [datosTerapia, setDatosTerapia] = useState({
    titulo: titulo,
    id: id,
    descripcion: descripcion,
    terapeutas: terapeutas,
    previewTerapeutas: [],
    terapeutaSeleccionado: '',
    colorHex: colorHex || '#ffffff'
  });
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [colores, setColores] = useState([])

  let history = useHistory();
  useEffect(() => {
    let buscarTerapeutasPreview = async () => {
      let losTerapeutasTotales = await obtenerTerapeutasPreview();
      let previewFinal = differenceArrayObjectsWithId2(
        losTerapeutasTotales.terapeutas,
        datosTerapia.terapeutas
      );
      setDatosTerapia({
        ...datosTerapia,
        previewTerapeutas: previewFinal
      });
      setColores(losTerapeutasTotales.colores)

    };
    buscarTerapeutasPreview();
  }, []);

  const handleChangePropio = event => {
    event.preventDefault();
    setDatosTerapia({
      ...datosTerapia,
      [event.target.name]: event.target.value
    });
  };

  const agregarTerapeuta = event => {
    event.preventDefault();
    if (datosTerapia.terapeutaSeleccionado === '') {
      return alert('No ha seleccionado terapeuta');
    } else {
      let nuevosTerapeutas = [
        ...datosTerapia.terapeutas,
        ...datosTerapia.previewTerapeutas.filter(
          terapeuta => terapeuta._id === datosTerapia.terapeutaSeleccionado
        )
      ];
      let nuevoPreview = [
        ...datosTerapia.previewTerapeutas.filter(
          terapeuta => terapeuta._id !== datosTerapia.terapeutaSeleccionado
        )
      ];
      setDatosTerapia({
        ...datosTerapia,
        terapeutas: nuevosTerapeutas,
        previewTerapeutas: nuevoPreview,
        terapeutaSeleccionado: {}
      });
    }
  };

  const elimintarTerapeuta = (event, id) => {
    event.preventDefault();
    var newOptions = [
      ...datosTerapia.terapeutas.filter(terapeuta => {
        return terapeuta._id !== id;
      })
    ];
    var newPreview = [
      ...datosTerapia.previewTerapeutas,
      ...datosTerapia.terapeutas.filter(terapeuta => {
        return terapeuta._id === id;
      })
    ];
    setDatosTerapia({
      ...datosTerapia,
      terapeutas: newOptions,
      previewTerapeutas: newPreview
    });
  };
  let validationSchemaTerapias = yup.object().shape({
    titulo: yup.string().required('Este campo es requerido')
  });
  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    isSubmitting
  } = useFormik({
    initialValues: {
      titulo: titulo
    },
    validationSchema: validationSchemaTerapias,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: async () => {
      let terapiaEditada = {
        titulo: values.titulo,
        descripcion: datosTerapia.descripcion,
        terapeutas: datosTerapia.terapeutas.map(t => t._id),
        colorHex: datosTerapia.colorHex
      };
      let res = await editarTerapia(id, terapiaEditada);
      switch (res.status) {
        case 200:
          history.push({
            pathname: '/panel/terapias',
            state: {
              alertStatus: true,
              titulo: values.titulo,
              descripcion: datosTerapia.descripcion,
              terapeutas: datosTerapia.terapeutas,
              tipo: 'modificacion'
            }
          });
          setTimeout(() => {
            setAlertSucces(true);
          }, 1500);
          break;
        case 400:
          setServerError(true);
          setServerErrorDescription(res.data.err);
          break;
        case 500:
          setServerError(true);
          setServerErrorDescription('Hubo un error inesperado del servidor');
          break;
        default:
          break;
      }
    }
  });

  //funcion para el backButton
  const goBack = () => {
    history.goBack();
  };

  const handleChangeColor = (color, event) => {
    setShowColorPicker(false)
    setDatosTerapia({
      ...datosTerapia,
      colorHex: color.hex
    })

  }

  return (
    <div className='dashboardContainer'>
      <Animated animationIn="fadeIn">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <BackButton onClick={goBack} />
        <DashboardTitle title='Modificar terapia' />
      </div>
      </Animated>
      <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 container'>
      <Animated animationIn="fadeIn">
        <FormContainer title='Datos de la terapia' clicked={handleSubmit}>
          <Alert variant='success' show={isOpenAlertSuccess}>
            <strong>Modificación exitosa.</strong> Se ha modificado la terapia{' '}
            {values.titulo} de forma correcta{' '}
          </Alert>

          <FormInput
            labelText='Título (*)'
            labelHtmlFor='titulo'
            atrName='titulo'
            atrId='titulo'
            atrType='text'
            atrValue={values.titulo}
            changed={handleChange}
            hasErrors={errors.titulo ? true : false}
            isRequired={true}
          >
            {errors.titulo ? (
              <InputMessage
                message={errors.titulo}
                atrId='hint_tituloTerapia'
                hasErrors={errors.titulo ? true : false}
              />
            ) : null}
          </FormInput>

          <FormTextArea
            labelHtmlFor='descripcion'
            labelText='Descripción'
            atrMaxLength='100'
            atrId='descripcion'
            atrName='descripcion'
            atrType='text'
            atrValue={datosTerapia.descripcion}
            changed={handleChangePropio}
          />
            <ColorPicker handleChange={handleChangeColor} colores={colores} backgroundColor={datosTerapia.colorHex} show={showColorPicker} setShow={setShowColorPicker}/>

          <Dropdown
            labelText='Terapeutas'
            labelHtmlFor='terapeutas'
            atrValue={datosTerapia.terapeutaSeleccionado}
            changed={handleChangePropio}
            data={datosTerapia.previewTerapeutas}
          />

          <div
            className={styles.buttonPosition + ' col-xl-4 col-lg-6 col-12'}
            style={{ marginTop: '32px' }}
          >
            <FormButton
              text='AGREGAR TERAPEUTA'
              buttonType='button'
              buttonStyle='secundario'
              atrName='agregarTerapeuta'
              clicked={agregarTerapeuta}
            />
          </div>

          <div className={'col-12 ' + styles.containerSelectedList}>
            <ul className={styles.selectedList + ' col-12'}>
              {datosTerapia.terapeutas.length > 0 ? (
                datosTerapia.terapeutas.map((terapeuta, key) => (
                  <li className={styles.selectedItem}>
                    <MdClose
                      className={styles.deleteSelectedItem}
                      color='red'
                      onClick={event =>
                        elimintarTerapeuta(event, terapeuta._id)
                      }
                    />
                    <p className={styles.terapeutaName} value={terapeuta}>
                      {terapeuta.nombreCompleto}
                    </p>
                  </li>
                ))
              ) : (
                <li style={{ whiteSpace: 'nowrap' }}>
                  Aún no se han asignado terapias
                </li>
              )}
            </ul>
          </div>


          {serverError && (
            <InputMessage
              message={serverErrorDescription}
              atrId='hint_sverror'
              hasErrors={serverError ? true : false}
            />
          )}
          <div
            className={styles.buttonPosition + ' col-xl-4 col-lg-6 col-12'}
            style={{ marginTop: '32px' }}
          >
            <FormButton
              text='MODIFICAR TERAPIA'
              buttonType='submit'
              buttonStyle='primario'
              atrName='crearTerapia'
              atrDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              spinnerType='white'
            />
          </div>
        </FormContainer>
        </Animated>
      </div>
    </div>
  );
};

export default EditarTerapias;
