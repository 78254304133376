//Packages, Utils & Server Requests
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/context";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import {
  obtenerAprobaciones,
  aprobarSolicitudUsuario,
  aprobarSolicitudAcceso,
  desaprobarSolicitudUsuario,
  desaprobarSolicitudAcceso,
} from "../../utils/serverRequest";
import styles from "./dashboardAprobaciones.module.css";

// Components
import DashboardTitle from "../dashboardTitle/dashboardTitle";
import Grilla from "../Globales/grilla/grilla.jsx";
import Avatar from "../Globales/avatar/avatar.jsx";
import Paginacion from "../Globales/paginacion/paginacion.jsx";
import Spinner from "../Globales/spinner/spinner.jsx";
import ModalPropio from "../Globales/modal/modalPropio";
import Alert from "../Globales/alertPropia/alertPropia";
import EmptyState from "../Globales/emptyState/emptyState.jsx";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const DashboardAprobaciones = () => {
  // State
  const [aprobacionesInicialesUsuarios, setAprobacionesInicialesUsuarios] = useState([]);
  const [aprobacionesInicialesAcceso, setAprobacionesInicialesAcceso] = useState([]);
  const [paginaActual, setPaginaActual] = useState(1);
  const [isLoading, setSpinner] = useState(true);
  const [modalAprobarUsuario, setModalAprobarUsuario] = useState(false);
  const [modalAprobarAcceso, setModalAprobarAcceso] = useState(false);
  const [modalDesaprobarUsuario, setModalDesaprobarUsuario] = useState(false);
  const [modalDesaprobarAcceso, setModalDesaprobarAcceso] = useState(false);
  const [aprobacionSeleccionada, setAprobacionSeleccionada] = useState("");

  const [alertSuccessAprobUsuario, setAlertSuccessAprobUsuario] = useState(false);
  const [alertFailAprobUsuario, setAlertFailAprobUsuario] = useState(false);
  const [alertSuccessDesaprobUsuario, setAlertSuccessDesaprobUsuario] = useState(false);
  const [alertFailDesaprobUsuario, setAlertFailDesaprobUsuario] = useState(false);

  const [alertSuccessAprobAcceso, setAlertSuccessAprobAcceso] = useState(false);
  const [alertFailAprobAcceso, setAlertFailAprobAcceso] = useState(false);
  const [alertSuccessDesaprobAcceso, setAlertSuccessDesaprobAcceso] = useState(false);
  const [alertFailDesaprobAcceso, setAlertFailDesaprobAcceso] = useState(false);
  const [isSubmitting, setSubmit] = useState(false);

  // Context
  const [user, setUser] = useContext(UserContext);
  const rolUsuario = user.rol;

  // Service call
  useEffect(() => {
    const traerAprobaciones = async () => {
      let res = await obtenerAprobaciones();
      if (res.status === 200) {
        setSpinner(false);
        setAprobacionesInicialesUsuarios(res.data.usuarios);
        setAprobacionesInicialesAcceso(res.data.accesos);
      } else if (res.status === 401) {
        setSpinner(false);
        alert("No estás autorizado para ver ésta página");
      } else {
        setSpinner(false);
        alert("Error al importar las aprobaciones");
      }
    };
    traerAprobaciones();
  }, []);

  const totalAprobacionesIniciales = [...aprobacionesInicialesUsuarios, ...aprobacionesInicialesAcceso];

  const cantidadTotalAprobaciones = totalAprobacionesIniciales.length;
  const cantidadPaginas = Math.ceil(cantidadTotalAprobaciones / 12);

  // Filas de usuario
  let arrayFilasUsuario = aprobacionesInicialesUsuarios.slice((paginaActual - 1) * 12, paginaActual * 12).map((usuario, key) => (
    <tr key={key}>
      <th scope="row">
        <Avatar rutaAvatar={usuario.avatar} />
      </th>
      <td>{usuario.nombreCompleto}</td>
      <td>{usuario.email}</td>
      <td>{usuario.rol}</td>
      <td>Ingreso a la aplicación</td>
      {usuario.rol === "Admin" && usuario.rol === rolUsuario ? (
        <td style={{ display: "flex" }}>
          <OverlayTrigger placement="top" overlay={<Tooltip>Este usuario solo lo podrá desaprobar un usuario con rol Superadmin</Tooltip>}>
            <button className={styles.customButton} disabled>
              <MdCancel color="#dbdbdb" size={25} className={styles.customSvg} />
            </button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>Este usuario solo lo podrá aprobar un usuario con rol Superadmin</Tooltip>}>
            <button className={styles.customButton} disabled>
              <MdCheckCircle color="#a3d4ff" size={25} className={styles.customSvg} />
            </button>
          </OverlayTrigger>
        </td>
      ) : (
        <td style={{ display: "flex" }}>
          <button className={styles.customButton} onClick={() => handleDesaprobarUsuario(usuario)}>
            <MdCancel color="#7f7f7f" size={25} className={styles.customSvg + " " + styles.customSvgDisapprove} />
          </button>
          <button className={styles.customButton} onClick={() => handleAprobarUsuario(usuario)}>
            <MdCheckCircle color="#2699FB" size={25} className={styles.customSvg + " " + styles.customSvgApprove} />
          </button>
        </td>
      )}
    </tr>
  ));

  // Filas de accesos
  let arrayFilasAcceso = aprobacionesInicialesAcceso.slice((paginaActual - 1) * 12, paginaActual * 12).map((acceso, key) => (
    <tr key={key}>
      <th scope="row">
        <Avatar rutaAvatar={acceso.terapeuta.avatar} />
      </th>
      <td>{acceso.terapeuta.nombreCompleto}</td>
      <td>{acceso.terapeuta.email}</td>
      <td>{acceso.terapeuta.rol}</td>
      <td>{`Acceso a paciente: ${acceso.paciente.nombreCompleto}`}</td>
      <td style={{ display: "flex" }}>
        <button className={styles.customButton} onClick={() => handleDesaprobarAcceso(acceso)}>
          <MdCancel color="#7f7f7f" size={25} className={styles.customSvg + " " + styles.customSvgDisapprove} />
        </button>
        <button className={styles.customButton} onClick={() => handleAprobarAcceso(acceso)}>
          <MdCheckCircle color="#2699FB" size={25} className={styles.customSvg + " " + styles.customSvgApprove} />
        </button>
      </td>
    </tr>
  ));

  let arrayFilas = [...arrayFilasUsuario, arrayFilasAcceso];

  // Funciones para APROBAR una solicitud - USUARIO

  const handleAprobarUsuario = (usuario) => {
    setAprobacionSeleccionada(usuario);
    setModalAprobarUsuario(true);
  };

  const aprobSolicitudUsuario = async () => {
    setSubmit(true);
    let res = await aprobarSolicitudUsuario({
      rol: aprobacionSeleccionada.rol,
      _id: aprobacionSeleccionada._id,
    });
    setModalAprobarUsuario(false);
    if (res.data.ok) {
      setSubmit(false);
      setAlertSuccessAprobUsuario(true);
      setTimeout(() => {
        setAlertSuccessAprobUsuario(false);
      }, 3000);
      setAprobacionesInicialesUsuarios(aprobacionesInicialesUsuarios.filter((a) => a._id !== aprobacionSeleccionada._id));
    } else {
      setSubmit(false);
      setAlertFailAprobUsuario(true);
      setTimeout(() => {
        setAlertFailAprobUsuario(false);
      }, 3000);
    }
  };

  // Funciones para APROBAR una solicitud - ACCESOS

  const handleAprobarAcceso = (acceso) => {
    setAprobacionSeleccionada(acceso);
    setModalAprobarAcceso(true);
  };

  const aprobSolicitudAcceso = async () => {
    setSubmit(true);
    let res = await aprobarSolicitudAcceso(aprobacionSeleccionada._id);
    setModalAprobarAcceso(false);
    if (res.data.ok) {
      setSubmit(false);
      setAlertSuccessAprobAcceso(true);
      setTimeout(() => {
        setAlertSuccessAprobAcceso(false);
      }, 3000);
      setAprobacionesInicialesAcceso(aprobacionesInicialesAcceso.filter((a) => a._id !== aprobacionSeleccionada._id));
    } else {
      setSubmit(false);
      setAlertFailAprobAcceso(true);
      setTimeout(() => {
        setAlertFailAprobAcceso(false);
      }, 3000);
    }
  };

  // Funciones para DESAPROBAR una solicitud - USUARIO

  const handleDesaprobarUsuario = (usuario) => {
    setAprobacionSeleccionada(usuario);
    setModalDesaprobarUsuario(true);
  };

  const desaprobSolicitudUsuario = async () => {
    setSubmit(true);
    let res = await desaprobarSolicitudUsuario(aprobacionSeleccionada._id);
    setModalDesaprobarUsuario(false);
    if (res.data.ok) {
      setSubmit(false);
      setAlertSuccessDesaprobUsuario(true);
      setTimeout(() => {
        setAlertSuccessDesaprobUsuario(false);
      }, 3000);
      setAprobacionesInicialesUsuarios(aprobacionesInicialesUsuarios.filter((a) => a._id !== aprobacionSeleccionada._id));
    } else {
      setSubmit(false);
      setAlertFailDesaprobUsuario(true);
      setTimeout(() => {
        setAlertFailDesaprobUsuario(false);
      }, 3000);
    }
  };

  // Funciones para DESAPROBAR una solicitud - ACCESOS

  const handleDesaprobarAcceso = (acceso) => {
    setAprobacionSeleccionada(acceso);
    setModalDesaprobarAcceso(true);
  };

  const desaprobSolicitudAcceso = async () => {
    setSubmit(true);
    let res = await desaprobarSolicitudAcceso(aprobacionSeleccionada._id);
    setModalDesaprobarAcceso(false);
    if (res.data.ok) {
      setSubmit(false);
      setAlertSuccessDesaprobAcceso(true);
      setTimeout(() => {
        setAlertSuccessDesaprobAcceso(false);
      }, 3000);
      setAprobacionesInicialesAcceso(aprobacionesInicialesAcceso.filter((a) => a._id !== aprobacionSeleccionada._id));
    } else {
      setSubmit(false);
      setAlertFailDesaprobAcceso(true);
      setTimeout(() => {
        setAlertFailDesaprobAcceso(false);
      }, 3000);
    }
  };

  // Render

  return (
    <div className="dashboardContainer">
      <DashboardTitle title="Aprobaciones" />
      {alertSuccessAprobUsuario && (
        <Alert variant="success" show={alertSuccessAprobUsuario}>
          <strong>Operación exitosa.</strong> Se ha aprobado el acceso a la aplicación a {aprobacionSeleccionada.nombreCompleto}
        </Alert>
      )}

      {alertFailAprobUsuario && (
        <Alert variant="danger" show={alertFailAprobUsuario}>
          <strong>Operación fallida.</strong> No se ha podido aprobar el acceso a la aplicación a {aprobacionSeleccionada.nombreCompleto}. Intente
          nuevamente más tarde
        </Alert>
      )}

      {alertSuccessDesaprobUsuario && (
        <Alert variant="success" show={alertSuccessDesaprobUsuario}>
          <strong>Operación exitosa.</strong> Se ha desaprobado el acceso a la aplicación a {aprobacionSeleccionada.nombreCompleto}
        </Alert>
      )}

      {alertFailDesaprobUsuario && (
        <Alert variant="danger" show={alertFailDesaprobUsuario}>
          <strong>Operación fallida.</strong> No se ha podido desaprobar el acceso a la aplicación a {aprobacionSeleccionada.nombreCompleto}. Intente
          nuevamente más tarde
        </Alert>
      )}

      {alertSuccessAprobAcceso && (
        <Alert variant="success" show={alertSuccessAprobAcceso}>
          <strong>Operación exitosa.</strong> Se ha aprobado el acceso al paciente a {aprobacionSeleccionada.terapeuta.nombreCompleto}
        </Alert>
      )}

      {alertFailAprobAcceso && (
        <Alert variant="danger" show={alertFailAprobAcceso}>
          <strong>Operación fallida.</strong> No se ha podido aprobar el acceso al paciente a {aprobacionSeleccionada.terapeuta.nombreCompleto}. Intente
          nuevamente más tarde
        </Alert>
      )}

      {alertSuccessDesaprobAcceso && (
        <Alert variant="success" show={alertSuccessDesaprobAcceso}>
          <strong>Operación exitosa.</strong> Se ha desaprobado el acceso al paciente a {aprobacionSeleccionada.terapeuta.nombreCompleto}
        </Alert>
      )}

      {alertFailDesaprobAcceso && (
        <Alert variant="danger" show={alertFailDesaprobAcceso}>
          <strong>Operación fallida.</strong> No se ha podido desaprobar el acceso al paciente a {aprobacionSeleccionada.terapeuta.nombreCompleto}. Intente
          nuevamente más tarde
        </Alert>
      )}

      {isLoading ? (
        <Spinner />
      ) : totalAprobacionesIniciales.length === 0 ? (
        <EmptyState ruta="/images/undraw.svg" titulo="No hay aprobaciones pendientes." />
      ) : (
        <>
          <Grilla titulos={["Avatar", "Nombre completo", "Correo electrónico", "Rol", "Tipo de solicitud", "Acciones"]} filas={arrayFilas} />
          {arrayFilas.length !== 0 && <Paginacion cantidad={cantidadPaginas} paginaActual={paginaActual} handler={setPaginaActual} />}
        </>
      )}

      {modalAprobarUsuario && (
        <ModalPropio
          title="Aprobar solicitud"
          content={`¿Está seguro que quiere aprobar la solicitud de acceso a la aplicación del usuario ${aprobacionSeleccionada.nombreCompleto}?`}
          isOpen={modalAprobarUsuario}
          closeModal={setModalAprobarUsuario}
          okHandler={aprobSolicitudUsuario}
          isSubmitting={isSubmitting}
          botonPrimario="Aprobar"
          botonSecundario="Cancelar"
        />
      )}

      {modalAprobarAcceso && (
        <ModalPropio
          title="Aprobar solicitud"
          content={`¿Está seguro que quiere aprobar la siguiente solicitud del usuario ${aprobacionSeleccionada.terapeuta.nombreCompleto}?`}
          isOpen={modalAprobarAcceso}
          closeModal={setModalAprobarAcceso}
          okHandler={aprobSolicitudAcceso}
          isSubmitting={isSubmitting}
          botonPrimario="Aprobar"
          botonSecundario="Cancelar"
        >
          <p>
            <strong>Paciente: </strong>
            {aprobacionSeleccionada.paciente.nombreCompleto}
          </p>
          <p>
            <strong>Tipo de acceso: </strong>
            {aprobacionSeleccionada.tipoAcceso}
          </p>
          <p>
            <strong>Terapia: </strong>
            {aprobacionSeleccionada.terapia.titulo}
          </p>
        </ModalPropio>
      )}

      {modalDesaprobarUsuario && (
        <ModalPropio
          title="Desaprobar solicitud"
          content={`¿Está seguro que quiere desaprobar la solicitud de acceso a la aplicación del usuario ${aprobacionSeleccionada.nombreCompleto}?`}
          isOpen={modalDesaprobarUsuario}
          closeModal={setModalDesaprobarUsuario}
          okHandler={desaprobSolicitudUsuario}
          isSubmitting={isSubmitting}
          botonPrimario="Desaprobar"
          botonSecundario="Cancelar"
          danger={true}
        />
      )}

      {modalDesaprobarAcceso && (
        <ModalPropio
          title="Desaprobar solicitud"
          content={`¿Está seguro que quiere desaprobar la siguiente solicitud del usuario ${aprobacionSeleccionada.terapeuta.nombreCompleto}?`}
          isOpen={modalDesaprobarAcceso}
          closeModal={setModalDesaprobarAcceso}
          okHandler={desaprobSolicitudAcceso}
          isSubmitting={isSubmitting}
          botonPrimario="Desaprobar"
          botonSecundario="Cancelar"
          danger={true}
        >
          <p>
            <strong>Paciente: </strong>
            {aprobacionSeleccionada.paciente.nombreCompleto}
          </p>
          <p>
            <strong>Tipo de acceso: </strong>
            {aprobacionSeleccionada.tipoAcceso}
          </p>
          <p>
            <strong>Terapia: </strong>
            {aprobacionSeleccionada.terapia.titulo}
          </p>
        </ModalPropio>
      )}
    </div>
  );
};

export default DashboardAprobaciones;
