import React from 'react';
import styles from './emptyState.module.css';
import {Animated} from 'react-animated-css'

const EmptyState = props => {
  // 276 x 216

  return (
    <div className={styles.emptyStateContainer}>
      <Animated className="d-flex flex-column align-items-center justify-content-center" animationIn="fadeIn">
        <img
          className={styles.emptyStateImg}
          src={props.ruta}
          alt='Empty State'
        />
        {props.titulo && <h4>{props.titulo}</h4>}
        {props.subtitulo && <h5>{props.subtitulo}</h5>}
        {props.leyenda && <p className={styles.leyendaUno}>{props.leyenda}</p>}
        {props.leyenda2 && <p className={styles.leyendaDos}>{props.leyenda2}</p>}
        {props.children}
      </Animated>
    </div>
  );
};

export default EmptyState;
