import React from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";

const ProcessMessage = (props) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center ">
      <Image src={props.imageSrc} height="200" width="200" style={{ marginBottom: 16 }} />
      <p>{props.message}</p>
    </div>
  );
};

ProcessMessage.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default ProcessMessage;
