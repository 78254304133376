import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import "./quilEditor.css";
import _ from "underscore";
import Quill from "quill";
import CustomToolbar from "./customToolbar";

const icons = Quill.import("ui/icons");
icons.header[3] =
  '<svg viewBox="0 0 18 18"><path class="ql-fill" d="M16.65186,12.30664a2.6742,2.6742,0,0,1-2.915,2.68457,3.96592,3.96592,0,0,1-2.25537-.6709.56007.56007,0,0,1-.13232-.83594L11.64648,13c.209-.34082.48389-.36328.82471-.1543a2.32654,2.32654,0,0,0,1.12256.33008c.71484,0,1.12207-.35156,1.12207-.78125,0-.61523-.61621-.86816-1.46338-.86816H13.2085a.65159.65159,0,0,1-.68213-.41895l-.05518-.10937a.67114.67114,0,0,1,.14307-.78125l.71533-.86914a8.55289,8.55289,0,0,1,.68213-.7373V8.58887a3.93913,3.93913,0,0,1-.748.05469H11.9873a.54085.54085,0,0,1-.605-.60547V7.59863a.54085.54085,0,0,1,.605-.60547h3.75146a.53773.53773,0,0,1,.60547.59375v.17676a1.03723,1.03723,0,0,1-.27539.748L14.74854,10.0293A2.31132,2.31132,0,0,1,16.65186,12.30664ZM9,3A.99974.99974,0,0,0,8,4V8H3V4A1,1,0,0,0,1,4V14a1,1,0,0,0,2,0V10H8v4a1,1,0,0,0,2,0V4A.99974.99974,0,0,0,9,3Z"/></svg>';

const QuilEditor = (props) => {
  var editorRef = useRef(null);
  var initialWindowSize;

  useEffect(() => {
    initialWindowSize = document.body.clientHeight;
    window.addEventListener("resize", showElements);

    return () => {
      window.removeEventListener("resize", showElements);
    };
  }, []);

  const showElements = () => {
    if (document.body.clientHeight < initialWindowSize) {
      props.setKeyboardOpened(true);
    } else {
      props.setKeyboardOpened(false);
    }
  };

  const [{ editorModules }] = useState(() => {
    var toolbarOptions = [
      { header: 1 },
      { header: 2 },
      { header: 3 },
      "bold",
      "italic",
      "underline",
      { list: "ordered" },
      { list: "bullet" },
      "clear",
    ];
    const editorModules = {
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@"],
        source: function (searchTerm, renderList, mentionChar) {
          let values;

          if (mentionChar === "@") {
            values = props.possibleMentions.map((u) => {
              return { id: u._id, value: u.nombreCompleto };
            });
          }

          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++) if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
            renderList(matches, searchTerm);
          }
        },
        defaultMenuOrientation: "top",
      },
      toolbar: {
        container: "#toolbar",
        handlers: {
          clear: props.clearEditor,
        },
      },
    };
    return {
      editorModules,
    };
  });

  const getMentions = (delta) => {
    let mentionInserts = delta.ops.filter((op) => op.insert.mention).map((op) => op.insert.mention.id);
    return mentionInserts;
  };

  const handleChange = (content, delta, source, editor) => {
    props.handleChange(content, getMentions(editor.getContents()), editor.getText());
  };

  return (
    <div id="quillEditorContainer">
      <CustomToolbar clearDisabled={props.disabledButton} />
      {/* <hr></hr> */}
      <ReactQuill
        id="quillEditor"
        ref={(el) => (editorRef = el)}
        style={{ height: "90%" }}
        value={props.value}
        onChange={handleChange}
        modules={editorModules}
        placeholder={props.placeholder || "Ingrese aquí el texto"}
      />
    </div>
  );
};

export default QuilEditor;
