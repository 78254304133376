//Packages & Utils
import React from 'react';
import styles from './boxInfoFeedPaciente.module.css';
import { FaQuestionCircle } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// Components
import Avatar from '../avatar/avatar';
import Badge from '../badge/Badge';

const BoxInfoFeedPaciente = props => {
  let aForoStyle = '';
  let aInfoStyle = '';
  if (props.paginaSeleccionada === 'foro') {
    aForoStyle = styles.itemSelected;
    aInfoStyle = '';
  } else {
    aForoStyle = '';
    aInfoStyle = styles.itemSelected;
  }
  return (
    <div className={styles.box}>
      <div className={styles.contenedorNombreAvatar}>
        <div className={styles.contenedorAvatar + ' col-2'}>
          <Avatar rutaAvatar={props.avatarPaciente} size='xl' />
        </div>
        <div className={styles.contenedorNombre + ' col-10'}>
          <h4>{props.nombrePaciente}</h4>
          {props.terapiasTerapeutaEscritura.length > 0 ? (
            <p style={{ textAlign: 'left' }}>
              Tus terapias activas con el paciente son:
              {props.terapiasTerapeutaEscritura.map((terapia, index) => (
                <Badge
                  key={index}
                  nombreTerapia={terapia.titulo}
                  colorHex={terapia.colorHex}
                  badgeStyle='secondary'
                />
              ))}
              <OverlayTrigger
                placement='right'
                overlay={
                  <Tooltip>
                    Se refieren a aquellas terapias donde el terapeuta puede
                    crear nuevos posts, es decir, que tiene tipo de acceso
                    escritura. De igual manera, todos los terapeutas podrán
                    comentar cualquier post de cualquier terapia.
                  </Tooltip>
                }
              >
                <FaQuestionCircle
                  style={{ marginLeft: '8px', verticalAlign: 'text-bottom' }}
                />
              </OverlayTrigger>
            </p>
          ) : (
            <p style={{ textAlign: 'left' }}>
              No tenés terapias activas con el paciente
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip>
                    Se refieren a aquellas terapias donde el terapeuta puede
                    crear nuevos posts, es decir, que tiene tipo de acceso
                    escritura. De igual manera, todos los terapeutas podrán
                    comentar cualquier post de cualquier terapia.
                  </Tooltip>
                }
              >
                <FaQuestionCircle
                  style={{ marginLeft: '8px', verticalAlign: 'text-bottom' }}
                />
              </OverlayTrigger>
            </p>
          )}
        </div>
      </div>
      <div className={styles.contenedorBotones + ' container'}>
        <ul className={styles.linkList}>
          <li className={styles.linkItem}>
            <a
              className={aForoStyle}
              id='foro'
              onClick={() => props.clicked('foro')}
            >
              Foro
            </a>
          </li>
          <li className={styles.linkItem}>
            <a
              id='more_info'
              className={aInfoStyle}
              onClick={() => props.clicked('more_info')}
            >
              Información de paciente
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BoxInfoFeedPaciente;
