import React from 'react';
import styles from './inputMessage.module.css';

const InputMessage = props => {
  let spanClasses = 'form-text ' + styles.messageClass;
  let spanErrorClasses = spanClasses + ' ' + styles.spanError;

  return (
    <span
      className={!props.hasErrors ? spanClasses : spanErrorClasses}
      id={props.atrId}
    >
      {props.message}
    </span>
  );
};

export default InputMessage;
