import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './creacionPaciente.module.css';
import * as yup from 'yup';
import { useFormik } from 'formik';
import FormContainer from '../Globales/formContainer/FormContainer';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Stepper from '../Globales/stepper/stepper';
import _ from 'underscore';
import {
  obtenerTerapias,
  crearPaciente,
  uploadPacienteAvatar,
  uploadPacienteArchivos
} from '../../utils/serverRequest';
import Alert from '../Globales/alertPropia/alertPropia';
import DashboardTitle from '../dashboardTitle/dashboardTitle';
import { isImage } from '../../utils/utils';
import {Animated} from 'react-animated-css'

import BackButton from '../Globales/backButton/BackButton';
const generos = ['Femenino', 'Masculino'];
const parentescos = [
  'Padre/Madre',
  'Abuelo/a',
  'Hermano/a',
  'Tio/a',
  'Primo/a',
  'Representante legal'
];

let validationSchemaStep1 = yup.object().shape({
  nombreCompleto: yup.string().required('Este campo es requerido'),
  fechaNacimiento: yup.date().required('Este campo es requerido'),
  dni: yup.string().required('Este campo es requerido'),
  nacionalidad: yup.string().required('Este campo es requerido'),
  genero: yup
    .mixed()
    .oneOf(generos)
    .required('Este campo es requerido'),
  direccion: yup.string().required('Este campo es requerido'),
  localidad: yup.string().required('Este campo es requerido'),
  codigoPostal: yup.string().required('Este campo es requerido'),
  telefono1: yup.string(),
  telefono2: yup.string(),
  estaEscolarizado: yup.boolean(),
  informacionEscolar: yup.string(),
  obraSocial: yup.string().required('Este campo es requerido'),
  nombreTitular: yup.string().required('Este campo es requerido'),
  numeroTarjeta: yup.string().required('Este campo es requerido'),
  fechaVencimiento: yup.string(),
  codigoSeguridad: yup.string()
});

let validationSchemaStep2 = yup.object().shape({
  responsables: yup.array().of(
    yup.object().shape({
      nombreCompletoResponsable: yup
        .string()
        .required('Este campo es requerido'),
      emailResponsable: yup
        .string()
        .email('Ingrese un correo electrónico válido')
        .required('Este campo es requerido'),
      documentoResponsable: yup.string().required('Este campo es requerido'),
      parentesco: yup
        .mixed()
        .oneOf(parentescos)
        .required('Este campo es requerido'),
      telefonoResponsable1: yup.string().required('Este campo es requerido'),
      telefonoResponsable2: yup.string(),
      comentariosAdicionales: yup.string(),
      obraSocialResponsable: yup.string(),
      nombreTitularResponsable: yup.string(),
      numeroTarjetaResponsable: yup.string(),
      fechaVencimientoResponsable: yup.string(),
      codigoSeguridadResponsable: yup.string()
    })
  )
});

const schemaArray = [validationSchemaStep1, validationSchemaStep2];

// para diferenciar terapeutas por terapia, LLAMO A CADA OBJETO 'tt' EN CADA MAP/FILTER, etc

const CreacionPaciente = props => {
  const [actualFormStep, setFormStep] = useState(1);
  const [alertFail, setAlertFail] = useState(false);
  const [terapiasIniciales, setTerapiasIniciales] = useState([]);
  const [terapiasSeleccionadas, setTerapiasSeleccionadas] = useState([]);
  const [terapiaActual, setTerapiaActual] = useState('');
  const [terapeutasSeleccionados, setTerapeutasSeleccionados] = useState([]);
  const [serverErrorDescription, setServerErrorDescription] = useState(['']);
  const [avatar, setAvatar] = useState('');
  const [archivos, setArchivos] = useState([]);
  const [alertImage, setAlertImage] = useState(false);
  let history = useHistory();
  useEffect(() => {
    let buscarTerapias = async () => {
      let data = await obtenerTerapias();
      if (data.ok) {
        setTerapiasIniciales(data.terapias);
      } else {
        console.log('Error al traer las terapias');
      }
    };

    buscarTerapias();
  }, []);

  const handleCheckEscolarizado = e => {
    setFieldValue('estaEscolarizado', e.target.checked);
    if (e.target.checked === false) {
      setFieldValue('informacionEscolar', '');
    }
  };

  const addImage = e => {
    e.preventDefault();
    let nuevoAvatar = e.target.files[0];
    if (isImage(nuevoAvatar.name)) {
      setAvatar(nuevoAvatar);
    } else {
      setAlertImage(true);
      setTimeout(() => {
        setAlertImage(false);
      }, 5000);
    }
    // Para poder volver a cargar el mismo archivo después de sacarlo
    e.target.value = '';
  };

  const removeImage = e => {
    setAvatar('');
  };

  const addArchivos = e => {
    let nuevosArchivos = [...e.target.files].filter(
      file => !archivos.some(archivo => archivo.name === file.name)
    );
    setArchivos([...archivos, ...nuevosArchivos]);
  };

  const removerArchivo = fileName => {
    let nuevosArchivos = [...archivos].filter(
      archivo => archivo.name !== fileName
    );
    setArchivos(nuevosArchivos);
  };

  const enviarArchivo = async pacienteId => {
    let formData = new FormData();
    formData.append('Archivos', avatar);
    let data = await uploadPacienteAvatar(formData, pacienteId);
    return data;
  };

  const enviarArchivos = async pacienteId => {
    let formData = new FormData();
    archivos.forEach(archivo => formData.append('Archivos', archivo));
    let data = await uploadPacienteArchivos(formData, pacienteId);
    return data;
  };

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    isSubmitting,
    setFieldValue,
    validateForm
  } = useFormik({
    initialValues: {
      nombreCompleto: '',
      dni: '',
      fechaNacimiento: '',
      nacionalidad: '',
      genero: '',
      direccion: '',
      localidad: '',
      codigoPostal: '',
      telefono1: '',
      telefono2: '',
      estaEscolarizado: false,
      informacionEscolar: '',
      obraSocial: '',
      nombreTitular: '',
      numeroTarjeta: '',
      fechaVencimiento: '',
      codigoSeguridad: '',
      responsables: [
        {
          nombreCompletoResponsable: '',
          emailResponsable: '',
          telefonoResponsable1: '',
          telefonoResponsable2: '',
          documentoResponsable: '',
          parentesco: '',
          comentariosAdicionales: '',
          obraSocialResponsable: '',
          nombreTitularResponsable: '',
          numeroTarjetaResponsable: '',
          fechaVencimientoResponsable: '',
          codigoSeguridadResponsable: ''
        }
      ]
    },
    validationSchema: schemaArray[actualFormStep - 1],
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: async values => {
      let datosPaciente = {
        nombreCompleto: values.nombreCompleto,
        dni: values.dni,
        fechaNacimiento: values.fechaNacimiento,
        nacionalidad: values.nacionalidad,
        genero: values.genero,
        direccion: values.direccion,
        localidad: values.localidad,
        codigoPostal: values.codigoPostal,
        telefono1: values.telefono1,
        telefono2: values.telefono2,
        estaEscolarizado: values.estaEscolarizado,
        informacionEscolar: values.informacionEscolar,
        obraSocial: values.obraSocial,
        nombreTitular: values.nombreTitular,
        numeroTarjeta: values.numeroTarjeta,
        fechaVencimiento: values.fechaVencimiento,
        codigoSeguridad: values.codigoSeguridad,
        terapias: terapiasSeleccionadas.map(t => t._id),
        accesos: terapeutasSeleccionados.map(a => {
          return { ...a, terapeuta: a.terapeuta._id };
        })
      };

      let responsablesArray = values.responsables.map(item => ({
        nombreCompleto: item.nombreCompletoResponsable,
        email: item.emailResponsable,
        parentesco: item.parentesco,
        documentoResponsable: item.documentoResponsable,
        telefono1: item.telefonoResponsable1,
        telefono2: item.telefonoResponsable2,
        comentariosAdicionales: item.comentariosAdicionales,
        obraSocial: item.obraSocialResponsable,
        nombreTitular: item.nombreTitularResponsable,
        numeroTarjeta: item.numeroTarjetaResponsable,
        fechaVencimiento: item.fechaVencimientoResponsable,
        codigoSeguridad: item.codigoSeguridadResponsable
      }));

      let nuevoPaciente = { ...datosPaciente, responsables: responsablesArray };

      let data = await crearPaciente(nuevoPaciente);
      var dataAvatarReq;
      var dataArchivosReq;
      if (data.ok) {
        if (avatar) {
          dataAvatarReq = await enviarArchivo(data.paciente._id);
        }

        if (archivos.length > 0) {
          dataArchivosReq = await enviarArchivos(data.paciente._id);
        }

        if (dataAvatarReq && !dataAvatarReq.ok) {
          alert('No se ha guardado el avatar del paciente');
        }

        if (dataArchivosReq && !dataArchivosReq.ok) {
          alert('No se han guardado los archivos del paciente');
        }

        props.history.push({
          pathname: '/panel/pacientes',
          state: {
            alertSuccess: true,
            titulo: 'Creación exitosa. ',
            descripcion: `Se ha credo al paciente ${values.nombreCompleto}`
          }
        });
      } else {
        switch (data.err.status) {
          case 400:
            setServerErrorDescription(data.err.data.err.dni.message);
            setAlertFail(true);
            setTimeout(() => {
              setAlertFail(false);
            }, 4000);
            break;
          case 500:
            setServerErrorDescription('Hubo un error inesperado del servidor');
            setAlertFail(true);
            setTimeout(() => {
              setAlertFail(false);
            }, 2000);
            break;
          default:
            console.log(data);
            break;
        }
      }
    }
  });

  const aumentarDecrementarPaso = num => {
    let step = actualFormStep + num;
    if (num > 0 && step !== 4) {
      validateForm(values).then(res => {
        if (_.isEmpty(res)) {
          setFormStep(step);
        }
      });
    } else {
      setFormStep(step);
    }
  };

  const navigateSteps = (stepNum) => {
    validateForm(values).then(res => {
      if(_.isEmpty(res)){
        setFormStep(stepNum)
      }
    })
  }

  const seleccionarTerapia = e => {
    e.preventDefault();
    setTerapiaActual(e.target.value);
  };

  const agregarTerapiaSeleccionada = e => {
    e.preventDefault();
    if (terapiaActual) {
      let nuevasTerapiasSeleccionadas = terapiasIniciales.filter(
        terapia => terapia._id === terapiaActual
      );
      let nuevasTerapiasIniciales = terapiasIniciales.filter(
        terapia => terapia._id !== terapiaActual
      );
      setTerapiasSeleccionadas([
        ...terapiasSeleccionadas,
        ...nuevasTerapiasSeleccionadas
      ]);
      setTerapiasIniciales(nuevasTerapiasIniciales);
      setTerapiaActual('');
    }
  };

  const agregarTerapeutasSeleccionados = nuevoAcceso => {
    let nuevoTerapeuta = {
      terapia: nuevoAcceso.terapia,
      // Porque necesito los objetos 'Terapaeuta' para mostrar le nombre completo en la lisat de terapeutas seleccionados
      terapeuta: terapiasSeleccionadas
        .filter(terapia => terapia._id === nuevoAcceso.terapia)
        .flatMap(terapia => terapia.terapeutas)
        .find(terapeuta => terapeuta._id === nuevoAcceso.terapeuta),
      tipoAcceso: nuevoAcceso.tipoAcceso
    };
    let nuevosTerapeutasSeleccionados = [
      ...terapeutasSeleccionados,
      nuevoTerapeuta
    ];
    setTerapeutasSeleccionados(nuevosTerapeutasSeleccionados);
  };

  const removerTerapiaSeleccionada = idTerapia => {
    let nuevasTerapiasIniciales = terapiasSeleccionadas.filter(
      terapia => terapia._id === idTerapia
    );
    let nuevasTerapiasSeleccionadas = terapiasSeleccionadas.filter(
      terapia => terapia._id !== idTerapia
    );
    setTerapiasIniciales([...terapiasIniciales, ...nuevasTerapiasIniciales]);
    setTerapiasSeleccionadas(nuevasTerapiasSeleccionadas);
  };

  const removerAccesoSeleccionado = acceso => {
    let nuevosTerapeutasSeleccionados = terapeutasSeleccionados.filter(
      tt =>
        tt.terapia !== acceso.terapia &&
        tt.terapeuta._id !== acceso.terapeuta._id
    );
    setTerapeutasSeleccionados(nuevosTerapeutasSeleccionados);
  };

  const stepsForStepper = [
    { description: 'DATOS DEL PACIENTE', last: false },
    { description: 'DATOS DE RESPONSABLES', last: false },
    { description: 'ASIGNACIÓN DE TERAPIAS', last: false },
    { description: 'ASIGNACIÓN DE TERAPEUTAS', last: true }
  ];
  var actualStep;
  var tituloActual;

  switch (actualFormStep) {
    case 1:
      tituloActual = 'Datos del paciente';
      actualStep = (
        <Step1
          title={tituloActual}
          handleChange={handleChange}
          values={values}
          errors={errors}
          aumentarDecrementarPaso={aumentarDecrementarPaso}
          avatar={avatar}
          archivos={archivos}
          addImage={addImage}
          removeImage={removeImage}
          addArchivos={addArchivos}
          handleRemoverArchivo={removerArchivo}
          handleCheckEscolarizado={handleCheckEscolarizado}
        />
      );
      break;
    case 2:
      tituloActual = 'Datos del responsable';
      actualStep = (
        <Step2
          title={tituloActual}
          handleChange={handleChange}
          values={values}
          errors={errors}
          aumentarDecrementarPaso={aumentarDecrementarPaso}
        />
      );
      break;
    case 3:
      tituloActual = 'Asignación de terapias';
      actualStep = (
        <Step3
          title={tituloActual}
          handleChange={handleChange}
          values={values}
          errors={errors}
          aumentarDecrementarPaso={aumentarDecrementarPaso}
          terapiasIniciales={terapiasIniciales}
          agregarTerapias={agregarTerapiaSeleccionada}
          removerTerapias={removerTerapiaSeleccionada}
          selectItem={seleccionarTerapia}
          selectedItems={terapiasSeleccionadas}
          terapiaActual={terapiaActual}
        />
      );
      break;
    case 4:
      tituloActual = 'Asignación de terapeutas';
      actualStep = (
        <Step4
          title={tituloActual}
          handleChange={handleChange}
          values={values}
          errors={errors}
          aumentarDecrementarPaso={aumentarDecrementarPaso}
          terapiasSeleccionadas={terapiasSeleccionadas}
          agregarTerapeutas={agregarTerapeutasSeleccionados}
          removerAcceso={removerAccesoSeleccionado}
          selectedItems={terapeutasSeleccionados}
          isSubmitting={isSubmitting}
          textSubmitButton='CREAR PACIENTE'
        />
      );
      break;
    default:
      actualStep = <></>;
  }

  //funcion para el backButton
  const goBack = () => {
    history.goBack();
  };

  return (
    <div className='dashboardContainer'>
      <Animated animationIn="fadeIn">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <BackButton onClick={goBack} />
          <DashboardTitle title='Agregar paciente' />
        </div>
      </Animated>

      {alertFail && (
        <Alert variant='danger' show={alertFail}>
          <strong>Creación Fallida. </strong>
          {serverErrorDescription}
        </Alert>
      )}

      {alertImage && (
        <Alert variant='danger' show={alertImage}>
          <strong>Archivo no válido.</strong> Seleccione un archivo con
          extensión <strong>JPG</strong>, <strong>JPEG</strong> o{' '}
          <strong>PNG</strong>
        </Alert>
      )}

      <div
        className={
          'col-lg-9 col-md-12 col-sm-12 col-xs-12 container ' +
          styles.stepperMobile
        }
      >
        <h5 style={{ textAlign: 'left', marginBottom: '0px' }}>
          {'Paso ' + actualFormStep + ' de ' + stepsForStepper.length}
        </h5>
      </div>
      <div
        className={
          'col-lg-9 col-md-12 col-sm-12 col-xs-12 container ' +
          styles.stepperWeb
        }
      >
        <Animated animationIn="fadeIn">
          <Stepper actualStep={actualFormStep} steps={stepsForStepper} navigateSteps={navigateSteps}/>
        </Animated>
      </div>
        <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 container'>
      <Animated >
          <FormContainer title={tituloActual} clicked={handleSubmit}>
            {actualStep}
          </FormContainer>
      </Animated>
        </div>
    </div>
  );
};

export default CreacionPaciente;
