import React from 'react'
// import styles from './viewsList.module.css'
import ReactionListItem from './reactionListItem'

const UsersReactionList = (props) => {

    return (
        <>
            {props.reactions.map((reaction, key) => (
                <ReactionListItem key={key} user={reaction.user} reaction={reaction.reaccion} />
            ))}
        </>
    )
}

export default UsersReactionList