import React from 'react'
import Avatar from '../avatar/avatar'

const UserListItem = (props) => {

    return (
        <div>
            <div className="d-inline-block mr-3"><Avatar rutaAvatar={props.user.avatar ? props.user.avatar : 's\images\avatarVacio.jfif'} /></div>
            <p className="d-inline-block m-0"> {props.user.nombreCompleto}</p>
        </div>
    )
}

export default UserListItem