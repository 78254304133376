import React from 'react';
import styles from './formSelect.module.css';

const FormSelect = props => {
  let inputSelectStyles = 'form-control select';
  let labelErrorClass = styles.inputLabel + ' ' + styles.labelError;
  let inputErrorClass = inputSelectStyles + ' ' + styles.inputError;

  return (
    <>
      <div className='form-group form-group-lg'>
        <label
          className={
            !props.hasErrors ? styles.inputLabel : ' ' + labelErrorClass
          }
          htmlFor={props.labelHtmlFor}
        >
          {props.labelText}
        </label>
        <select
          className={!props.hasErrors ? inputSelectStyles : inputErrorClass}
          id={props.atrId}
          name={props.atrName}
          value={props.atrValue}
          onChange={props.changed}
        >
          <option defaultValue value={null}></option>
          {props.list.map((item, index) => (
            <option key={index} value={item._id ? item._id : item}>
              {item.titulo ? item.titulo : item}
            </option>
          ))}
        </select>
        {props.children}
      </div>
    </>
  );
};

export default FormSelect;
