import React from 'react';
import styles from './buttonFeedPaciente.module.css';

const ButtonFeedPaciente = props => {
  let btnParticularStyle;

  switch (props.buttonState) {
    case 'Activo':
      btnParticularStyle = 'btn btn-outline-primary';
      break;
    case 'Ninguno':
      btnParticularStyle = 'btn btn-outline-danger';
      break;
    case 'Pendiente':
      btnParticularStyle = 'btn btn-light';
      break;
    default:
      btnParticularStyle = 'btn btn-outline-primary';
      break;
  }

  let cursorDefaultClass;

  if (props.buttonState === 'Pendiente') {
    cursorDefaultClass = styles.cursorDefaultClass;
  }

  return (
    <>
      <button
        type='button'
        name={props.atrName}
        value={props.buttonValue || 'props.value'}
        onClick={props.clicked}
        className={
          styles.btnGeneralStyle +
          ' ' +
          btnParticularStyle +
          ' ' +
          cursorDefaultClass
        }
        disabled={props.atrDisabled}
      >
        {props.text || 'props.text'}
      </button>
    </>
  );
};
export default ButtonFeedPaciente;
