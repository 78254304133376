import React from 'react'
import styles from './notification.module.css'
import Avatar from '../Globales/avatar/avatar'
import TimeAgo from 'react-timeago'
import spanishStrings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import parse from 'html-react-parser';

const formatter = buildFormatter(spanishStrings)

const Notification = React.forwardRef((props, ref) => {

    return (
        <div className={styles.notificationContainer} ref={ref} onClick={props.handleClickNotification}>
            <Avatar size="L" rutaAvatar={props.data.avatar} />
            <div style={{ width: '100%' }}>
                <p style={{ whiteSpace: 'normal', fontSize: '15px', margin: '0 0 4px 8px' }}>{parse(props.data.message)}</p>
                <div style={{ display: 'flex', width: '100%' }}>
                    <TimeAgo style={{ fontSize: '13px', margin: '0 auto 0 8px' }} date={props.data.createdAt} formatter={formatter} />
                </div>
            </div>
            {!props.data.read && <div style={{ height: '12px', width: '12px', borderRadius: '50%', alignSelf: 'center', backgroundColor: '#007BFF' }}></div>}
        </div>
    )
})

export default Notification