import React from "react";
import PropTypes from "prop-types";
import styles from "../itemsStyles.module.css";
import Badge from "../../badge/Badge";

const ActiveTherapiesItem = (props) => {
  return (
    <div className={"row " + styles.rowStyle}>
      <div className={"col-12 " + styles.dataBox}>
        <p className={"col-xl-3 col-lg-6 " + styles.textConcept}>
          <strong>Terapias activas:</strong>
        </p>
        <div className="col-xl-9 col-lg-6" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          {props.terapiasActivas.length === 0 ? (
            <p className={styles.textValue}>No tiene terapias activas</p>
          ) : (
            <>
              {props.terapiasActivas.map((terapia, index) => (
                <div key={index} className="mr-2 d-inline-block">
                  <Badge nombreTerapia={terapia.titulo} colorHex={terapia.colorHex} badgeStyle="secondary" />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

ActiveTherapiesItem.propTypes = {};

export default ActiveTherapiesItem;
