import React from 'react'
import PropTypes from 'prop-types'
import styles from './userEditor.module.css'
import Avatar from '../avatar/avatar';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const UserEditor = (props) => {
    return (
    <Container className={styles.userEditorContainer} fluid>
        <Row style={{alignItems: 'center', height: '72px'}}>
          <Col xs={2} md={1}>
            <Avatar src={props.avatarSource} size={props.avatarSize} />
          </Col>
          <Col xs={10} md={11}>
            <div className={props.comment ? styles.commentTextEditor : styles.postTextEditor} onClick={props.onClick}>
              {props.placeholder}
            </div>
          </Col>
        </Row>
      </Container>
    )
}

UserEditor.propTypes = {
    avatarSource: PropTypes.string,
    avatarSize: PropTypes.string,
    placeholder: PropTypes.string,
    onClick: PropTypes.func,
    comment: PropTypes.bool
}

export default UserEditor
