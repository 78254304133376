import React, { useContext, useState } from "react";
import "./sidebar.css";
import { NavLink } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { UserContext } from "../context/context";
import SupportButton from "../Globales/supportButton/SupportButton";
import FeedbackButton from "../feedbackButton/FeedbackButton";
import FeedbackForm from "../feedBackForm/feedbackForm";
import ModalPropio from "../Globales/modal/modalPropio";
import { enviarFeedback } from "../../utils/serverRequest";
import Spinner from "../Globales/spinner/spinner.jsx";
import Alert from "../Globales/alertPropia/alertPropia";

const Sidebar = (props) => {
  // Context
  const [user, setUser] = useContext(UserContext);
  const rolUsuario = user.rol;
  const [isOpenModalFeedback, setModalFeedback] = useState(false);
  const [isLoading, setSpinner] = useState(false);
  const [feedbackContent, setFeedbackContent] = useState({
    tipo: "",
    contenido: "",
  });
  const [showAlert, setShowAlert] = useState(false);

  const showModal = () => {
    setFeedbackContent({
      tipo: "",
      contenido: "",
    });
    props.sidebarToggler(false);
    setModalFeedback(true);
  };
  const notShowModal = () => {
    setModalFeedback(false);
  };
  const handleChange = (event) => {
    event.preventDefault();
    setFeedbackContent({
      ...feedbackContent,
      contenido: event.target.value,
    });
  };

  const handleClick = (feedback) => {
    setFeedbackContent({
      ...feedbackContent,
      tipo: feedback,
    });
  };

  const sendFeedback = async (feedback) => {
    if (!feedbackContent.tipo || !feedbackContent.contenido) {
      alert("Elija la opción deseada y complete el mensaje con sus palabras");
    } else {
      setSpinner(true);
      let data = await enviarFeedback(feedback);
      if (data.ok) {
        notShowModal();
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 4000);
        setFeedbackContent({
          tipo: "",
          contenido: "",
        });
      } else {
        notShowModal();
      }
      setSpinner(false);
    }
  };

  return (
    <div className="d-flex flex-column" style={{ height: "100%", marginLeft: "-15px", marginRight: "-15px" }}>
      {showAlert && (
        <Alert show={showAlert} variant="success">
          Gracias por tu feedback, nos ayudará a mejorar la app =)
        </Alert>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          maxHeight: "40px",
          width: "100%",
        }}
      >
        <button className="d-md-none btn" onClick={() => props.sidebarToggler(false)}>
          <MdClose />
        </button>
      </div>
      <div className="logoContainer d-flex justify-start">
        <a href="/panel/pacientes" style={{ display: "flex", alignItems: "center" }}>
          <img alt="logo" className="logo" src="/images/logoCic.png" />
        </a>
      </div>
      <div style={{ width: "100%" }}>
        <ul className="linkList colorFuentePrincipal">
          {rolUsuario !== "Terapeuta" && (
            <li className="linkItem" onClick={() => props.sidebarToggler(false)}>
              <NavLink exact to="/panel/administradores" activeClassName="linkItemSelected">
                Administradores
              </NavLink>
            </li>
          )}

          {rolUsuario !== "Terapeuta" && (
            <li className="linkItem" onClick={() => props.sidebarToggler(false)}>
              <NavLink exact to="/panel/aprobaciones" activeClassName="linkItemSelected">
                Aprobaciones
              </NavLink>
            </li>
          )}

          <li className="linkItem" onClick={() => props.sidebarToggler(false)}>
            <NavLink to="/panel/pacientes" activeClassName="linkItemSelected">
              Pacientes
            </NavLink>
          </li>
          {rolUsuario !== "Terapeuta" && (
            <li className="linkItem" onClick={() => props.sidebarToggler(false)}>
              <NavLink exact to="/panel/terapeutas" activeClassName="linkItemSelected">
                Terapeutas
              </NavLink>
            </li>
          )}
          <li className="linkItem" onClick={() => props.sidebarToggler(false)}>
            <NavLink to="/panel/terapias" activeClassName="linkItemSelected">
              Terapias
            </NavLink>
          </li>
        </ul>
      </div>
      {isOpenModalFeedback ? (
        <ModalPropio
          title="¡Nos encantaría escucharte!"
          isOpen={showModal}
          closeModal={notShowModal}
          okHandler={() => sendFeedback(feedbackContent)}
          botonPrimario="Enviar"
          botonSecundario="Cancelar"
        >
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner />
            </div>
          ) : (
            <FeedbackForm tipo={feedbackContent.tipo} contenido={feedbackContent.contenido} handleClick={handleClick} handleChange={handleChange} />
          )}
        </ModalPropio>
      ) : (
        <div className="row mt-auto" style={{ display: "flex", justifyContent: "space-around" }}>
          <div className="col-2">
            <FeedbackButton handleClick={showModal} />
          </div>
          <div className="col-9">
            <SupportButton text="¿Necesitas ayuda?" atrName="soporte" linkSoporte="https://wa.me/+34686281368" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
