import React from 'react';
import styles from './reaccionesPosibles.module.css';
import { Twemoji } from 'react-emoji-render';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Reacciones = props => {
  return (
    <div>
      {props.reacciones.map((reaccion, key) => (
        <OverlayTrigger placement="top" key={key} overlay={<Tooltip placement="top" className={styles.tooltipItem}>{reaccion.titulo}</Tooltip>}>
          <div key={key} className={styles.reaccionContainer} onClick={props.dioReaccion ? () => '' : () => props.handleClick(reaccion)}>
            <Twemoji text={reaccion.emojiCode} onlyEmojiClassName={styles.reaccionEmoji}/>
          </div>
        </OverlayTrigger>
      ))}
    </div>
  );
};

export default Reacciones;
