import React from 'react';
import styles from './formContainer.module.css';

const FormContainer = props => {
  return (
    // <div className='col-lg-6 col-md-6 col-sm-9 col-xs-12 container'>
    <form
      className={styles.formBox}
      method={props.formMethod}
      action={props.formAction}
      onSubmit={props.clicked}
    >
      <h4 className={styles.boxTitle}>{props.title}</h4>
      {props.children}
    </form>
    // </div>
  );
};

export default FormContainer;
