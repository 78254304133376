import React from "react";
import PropTypes from "prop-types";

const InfoSectionContainer = (props) => {
  return <div style={{ paddingLeft: "8px", marginTop: "8px", marginBottom: "48px" }}>{props.children}</div>;
};

InfoSectionContainer.propTypes = {};

export default InfoSectionContainer;
