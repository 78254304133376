import React from 'react';
import FormButton from '../formButton/FormButton';

const FormStepButtons = props => {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }}
    >
      {!props.first ? (
        <div>
          <FormButton
            buttonStyle='secundario-outlined'
            clicked={() => {
              props.handler(-1);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            text='ATRÁS'
          />
        </div>
      ) : (
        <></>
      )}
      {!props.last ? (
        <div style={{ marginLeft: '8px' }}>
          <FormButton
            buttonStyle='primario'
            clicked={() => {
              props.handler(1);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            text='SIGUIENTE'
          />
        </div>
      ) : (
        <div style={{ marginLeft: '8px' }}>
          <FormButton
            buttonType='submit'
            buttonStyle='primario'
            changed={() => props.handler(1)}
            text={props.textSubmitButton}
            atrDisabled={props.isSubmitting}
            isSubmitting={props.isSubmitting}
            spinnerType='white'
          />
        </div>
      )}
    </div>
  );
};

export default FormStepButtons;
