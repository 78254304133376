import React from 'react';
import styles from './formStepSubtitle.module.css';

const FormStepSubtitle = props => {
  return (
    <div>
      <h5 className={styles.formSubtitle}>{props.title}</h5>
    </div>
  );
};

export default FormStepSubtitle;
