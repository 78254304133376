import React, { useState, useEffect, useContext } from 'react';
import {UserContext} from '../context/context';
import { obtenerPostTerapia, borrarPost } from '../../utils/serverRequest';

// Components
import DashboardTitle from '../dashboardTitle/dashboardTitle';
import BoxInfoFeedTerapia from '../Globales/boxInfoFeedTerapia/BoxInfoFeedTerapia';
import TerapiaMoreInformation from '../Globales/terapiaMoreInformation/terapiaMoreInformation';
import Spinner from '../Globales/spinner/spinner';
import Post from '../Globales/post/Post';
import ModalPropio from '../Globales/modal/modalPropio';
import Alert from '../Globales/alertPropia/alertPropia';
import BackButton from '../Globales/backButton/BackButton';
import EmptyState from '../Globales/emptyState/emptyState';

const PostPageTerapia = props => {
  const [user, setUser] = useContext(UserContext);
  const [isLoading, setSpinner] = useState(true);
  const [dataPost, setDataPost] = useState({});
  const [pageSeleccionada, setPageSeleccionada] = useState('foro');
  const [dataTerapia, setDataTerapia] = useState({
    terapeutasActivos: [],
    pacientesActivos: [],
    terapiaId: '',
    terapiaDescripcion: '',
    terapiaTitulo: ''
  });
  const [isOpenModalEliminarPost, setModalEliminarPost] = useState(false);
  const [postSeleccionadoId, setPostSeleccionadoId] = useState('');
  const [isSubmittingDeletePost, setSubmitDeletePost] = useState(false);
  const [isOpenAlertEliminar, setAlertEliminar] = useState(false);
  const [isOpenAlertFail, setAlertFail] = useState(false);
  const [tieneAcceso, setTieneAcceso] = useState();
  const [statusError, setStatusError] = useState(0)
  const postId = props.match.params.postId;
  const terapiaId = props.match.params.terapiaId;
  
  useEffect(() => {
    const getPostTerapia = async () => {
      let res = await obtenerPostTerapia(postId, terapiaId);
      if (res.status === 200) {
        setTieneAcceso(res.data.terapia.tieneAcceso);
        let nuevaTerapia = {
          terapeutasActivos: res.data.terapia.terapeutas,
          pacientesActivos: res.data.terapia.pacientes,
          terapiaId: res.data.terapia._id,
          terapiaDescripcion: res.data.terapia.descripcion,
          terapiaTitulo: res.data.terapia.titulo
        };
        setDataTerapia(nuevaTerapia);
        if (res.data.post === undefined) {
          setDataPost({});
        } else {
          setDataPost(res.data.post);
        }
      } else if (res.status === 404){
        setStatusError(404)
      }
      else {
        alert('Error al cargar el post');
      }
      setSpinner(false);
    };
    getPostTerapia();
  }, [props.match.params]);

  const handlePage = id_page => {
    let idPage = id_page;
    setPageSeleccionada(idPage);
  };

  const handleModalEliminarPost = postId => {
    setModalEliminarPost(true);
    setPostSeleccionadoId(postId);
  };

  const deletePost = async postId => {
    setSubmitDeletePost(true);
    let data = await borrarPost(postId);
    if (data.ok) {
      setModalEliminarPost(false);
      setDataPost({});
      setAlertEliminar(true);
      setTimeout(() => {
        setAlertEliminar(false);
      }, 3000);
    } else {
      setModalEliminarPost(false);
      setAlertFail(true);
      setTimeout(() => {
        setAlertFail(false);
      }, 3000);
    }
    setSubmitDeletePost(false);
  };

  const handleRedirectToForoTerapia = () => {
    if (!tieneAcceso) {
      props.history.push({
        pathname: `/panel/terapias`
      });
    } else {
      props.history.push({
        pathname: `/panel/terapias/perfil/${props.match.params.terapiaId}`
      });
    }
  };

  return (
    <div className='dashboardContainer'>
      {isOpenAlertEliminar && (
        <Alert variant='success' show={isOpenAlertEliminar}>
          <strong>Eliminación exitosa.</strong> Se ha eliminado el post de forma
          correcta.
        </Alert>
      )}
      {isOpenAlertFail && (
        <Alert variant='danger' show={isOpenAlertFail}>
          <strong>Eliminación fallida.</strong> No se ha podido eliminar el
          post. Intente nuevamente.
        </Alert>
      )}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <BackButton onClick={handleRedirectToForoTerapia} />
        <DashboardTitle title='Información del post' />
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
          <>
            {!tieneAcceso ? (
              <div style={{ marginTop: '56px' }}>
                {
                  statusError === 404 ? <EmptyState ruta='/images/vacio.svg' subtitulo={`Este post fue borrado`}/> 
                  : <EmptyState ruta='/images/acceso_denegado.svg' subtitulo={`No tenés acceso a ver el perfil de la terapia ${dataTerapia.terapiaTitulo}`}/> 
                }
              </div>
            ) : (
                <>
                  <div
                    className='col-xl-9 col-12 container'
                    style={{ padding: '0px 0px 0px 0px' }}
                  >
                    <BoxInfoFeedTerapia
                      tituloTerapia={dataTerapia.terapiaTitulo}
                      descripcionTerapia={dataTerapia.terapiaDescripcion}
                      clicked={handlePage}
                      paginaSeleccionada={pageSeleccionada}
                    />
                  </div>
                  {pageSeleccionada === 'foro' ? (
                    <div
                      className='col-xl-9 col-12 container'
                      style={{ padding: '0px 0px 0px 0px' }}
                    >
                      {dataPost.hasOwnProperty('creador') === false ? (
                        <div style={{ marginTop: '56px' }}>
                          <EmptyState
                            ruta='/images/vacio.svg'
                            subtitulo='El post al que estas intentando ingresar no existe o se ha eliminado.'
                          />
                        </div>
                      ) : (
                          <Post
                            feed='terapia'
                            idTerapia={terapiaId}
                            avatarTerapeuta={dataPost.creador.avatar}
                            idCreador={dataPost.creador._id}
                            nombreTerapeuta={dataPost.creador.nombreCompleto}
                            antiguedadPost={dataPost.fechaCreacion}
                            colorTerapia={dataPost.colorHex}
                            textoPost={dataPost.contenido}
                            rawContent={dataPost.rawContent}
                            imagenesPost={dataPost.imagenes || []}
                            videosPost={dataPost.videos || []}
                            archivosPost={dataPost.archivos || []}
                            idPost={dataPost._id}
                            modalEliminar={handleModalEliminarPost}
                            tieneComentarios={dataPost.cantidadComentarios > 0 ? true : false}
                            cantidadComentarios={dataPost.cantidadComentarios}
                            possibleMentions={dataPost.possibleMentions}
                            views={dataPost.views}
                            isViewed={dataPost.views && dataPost.views.some(v => v.viewer.id === user.id)}
                            reacciones={dataPost.reacciones || []} // Las que tiene el post [[':smile:', [PostReaccion]], [':open_mouth', [PostReaccion], ...n]
                            reaccionesPosibles={dataPost.reaccionesPosibles || []} // [Reaccion] --> Las reacciones 'puras' (Con titulo, emoji code)
                            dioReaccion={dataPost.dioReaccion}
                            reaccionDada={dataPost.reaccionDada}
                            postReaccion={dataPost.postReaccion}
                            defaultReaction={dataPost.defaultReaction}
                            cantidadReacciones={dataPost.cantidadReacciones}
                          />
                        )}
                    </div>
                  ) : (
                      <div
                        className='col-xl-9 col-12 container'
                        style={{ padding: '0px 0px 0px 0px' }}
                      >
                        <TerapiaMoreInformation data={dataTerapia} />
                      </div>
                    )}
                  {/* Aca termina pageSeleccionada === foro */}
                </>
              )}
          </>
        )}
      {isOpenModalEliminarPost && (
        <ModalPropio
          title='Eliminar post'
          content={
            '¿Está seguro que quiere eliminar el post? También se borraran los comentarios asociados al mismo.'
          }
          isOpen={isOpenModalEliminarPost}
          danger={true}
          closeModal={setModalEliminarPost}
          okHandler={() => deletePost(postSeleccionadoId)}
          isSubmitting={isSubmittingDeletePost}
          botonPrimario='Eliminar'
          botonSecundario='Cancelar'
        />
      )}
    </div>
  );
};

export default PostPageTerapia;
