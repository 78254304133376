import React, { useEffect, useState, useRef, useCallback } from 'react'
import NotificationDropdown from '../notificationDropdown/notificationDropdown'
import { getNotifications, markNotificationRead, markNotificationsRead } from '../../utils/serverRequest'
import { useHistory } from 'react-router-dom';

const NotificationsWeb = (props) => {

    let history = useHistory()
    const [dropdownOpened, setDropdownOpened] = useState(false);
    const [actualPage, setActualPage] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    // Infinite Scroll
    const observer = useRef()
    const lastElementRef = useCallback(node => {
        if (isLoading) {
            return
        }
        if (observer.current) {
            observer.current.disconnect()
        }
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && actualPage + 1 < pageCount) {
                setActualPage(actualPage + 1)
            }
        })
        if (node) {
            observer.current.observe(node)
        }
    }, [pageCount, isLoading])

    // Busca las 1ras 20 notificaciones
    useEffect(() => {

        let getNotifs = async () => {
            setIsLoading(true)
            let res = await getNotifications(actualPage)
            if (res.status === 200) {
                let notifsToAdd = res.data.notifications
                let count = res.data.count
                props.setDropdownNotifications([...props.dropdownNotifications, ...notifsToAdd])
                setPageCount(count)
            }
            else {
                console.log('Error al buscar las nuevas notificaciones')
            }
            setIsLoading(false)
        }

        getNotifs()

    }, [actualPage])

    const handleClickDropdownNotification = async (notification) => {
        setDropdownOpened(false)
        history.push(notification.link)
        window.location.reload()
        if (!notification.read) {
            let res = await markNotificationRead(notification.id)

            if (res.status === 200) {
                let newNotifications = props.dropdownNotifications.map(n => {
                    return {
                        ...n,
                        read: n.id === notification.id ? true : n.read
                    }
                })
                props.setDropdownNotifications(newNotifications)
            }
            else {
                alert('Error al marcar como leida la notif')
            }
        }
    }

    const markAllAsRead = async (e) => {
        e.preventDefault()
        if (props.dropdownNotifications.some(n => !n.read)) {


            let actualNotifications = [...props.dropdownNotifications]
            let readNotifications = actualNotifications.map(n => {
                return {
                    ...n,
                    read: true
                }
            })

            props.setDropdownNotifications(readNotifications)

            let res = await markNotificationsRead()

            if (res.status !== 200) {
                props.setDropdownNotifications(actualNotifications)
                alert('Error al marcar las notificaciones como leïdas')
            }
        }
    }


    return (
        <>
            <NotificationDropdown
                notifications={props.dropdownNotifications}
                handleClickNotification={handleClickDropdownNotification}
                lastElementRef={lastElementRef}
                dropdownOpened={dropdownOpened}
                setDropdownOpened={setDropdownOpened}
                markAllAsRead={markAllAsRead}
            />
        </>
    )
}

export default NotificationsWeb