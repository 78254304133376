import React from 'react';
import OptionLabel from '../optionLabel/optionLabel';
import { MdClose } from 'react-icons/md';
import styles from './dropdownTerapia.module.css';

const DropdownTerapia = props => {
  return (
    <div className='row'>
      <div className='col-12'>
        <div className='form-group form-group-lg'>
          <label
            className='control-label label-label'
            style={{ float: 'left' }}
            htmlFor='list'
          >
            {props.titulo}
          </label>
          <select
            className=' select form-control'
            id='list'
            value={props.terapiaActual}
            onChange={props.selectItem}
          >
            <option selected='selected' value='' />
            {props.list.map((item, index) => (
              <OptionLabel
                // key={index}
                text={item.titulo || item.nombreCompleto}
                value={item._id}
              />
            ))}
          </select>
        </div>
      </div>

      <div className={'col-xl-4 col-lg-6 col-12 ' + styles.containerButton}>
        <button
          type='button'
          className={
            'btn btn-outline-primary btn-block ' + styles.btnGeneralStyle
          }
          onClick={
            props.complex ? () => props.addItems(props.util) : props.addItems
          }
        >
          {props.buttonTitle}
        </button>
      </div>

      <div className='col-12'>
        <ul className={styles.selectedList + ' col-12'}>
          {props.selectedItems.length > 0 ? (
            props.selectedItems.map((item, key) => (
              <li key={key} className={styles.selectedItem}>
                <MdClose
                  className={styles.deleteSelectedItem}
                  color='red'
                  onClick={
                    props.complex
                      ? () => props.removeItem(item)
                      : () => props.removeItem(item._id)
                  }
                />
                <p className={styles.terapeutaName}>
                  {item.titulo || item.terapeuta.nombreCompleto}
                </p>
              </li>
            ))
          ) : (
            <li>{props.emptyState}</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default DropdownTerapia;
