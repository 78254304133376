import React from "react";
import styles from "./formInput.module.css";
import Form from "react-bootstrap/Form";
import "../../../index.css";

const FormInput = (props) => {
  let formcontrol = "form-control ";
  let labelErrorClass = styles.inputLabel + " " + styles.labelError;
  let inputErrorClass = formcontrol + styles.inputError;

  return (
    <div className={`form-group form-group-lg`}>
      <label className={!props.hasErrors ? styles.inputLabel : " " + labelErrorClass} htmlFor={props.labelHtmlFor}>
        {props.labelText}
      </label>
      <input
        className={!props.hasErrors ? "form-control" : inputErrorClass}
        type={props.atrType}
        placeholder={props.atrPlaceholder}
        id={props.atrId}
        name={props.atrName}
        onChange={props.changed}
        value={props.atrValue}
        disabled={props.disabled || false}
      />
      {props.children}
    </div>
  );
};

export default FormInput;
