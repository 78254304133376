import React from "react";
import "./PublicRoute.css";
import { Route, Redirect } from "react-router-dom";
import { checkToken } from "../../../utils/utils";
import ScrollToTop from "../scrollToTop/ScrollToTop";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <div className="AppPublica container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="logoContainer d-flex justify-start align-items-center">
            <img alt="logo" className="logo" src="/images/logoCic.png" />
          </div>
          <div className="row rowClass">
            <div className="col-12">
              <Route
                {...rest}
                render={(props) =>
                  checkToken() ? (
                    <ScrollToTop>
                      <Redirect to="/panel/pacientes" />
                    </ScrollToTop>
                  ) : (
                    <Component {...props} />
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicRoute;
