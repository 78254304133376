import React from 'react';
import styles from './feedbackButton.module.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'



const FeedbackButton = (props) => {

    // const feedbackTooltip = (props) => (
    //     <Tooltip {...props} placement="top-end">
    //       Queremos tu opinión!
    //     </Tooltip>
    //   )

      const feedbackTooltip = (
        <Tooltip placement="top-end">
        Queremos tu opinión!
      </Tooltip>

      )

    return(
        <div>
            <OverlayTrigger delay={{show: 50, hide: 100}} placement='top' overlay={feedbackTooltip}>
                <button className = {styles.icon} onClick = {props.handleClick}> <img alt="" className ={styles.iconImg} src= "\images\novolabsicon.png" /> </button>
            </OverlayTrigger> 
        </div>
    )
}
export default FeedbackButton;