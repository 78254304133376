//Packages & Utils
import React, { useState, useEffect, useContext } from 'react';
import {UserContext} from '../context/context';
import styles from './dashboardPacientes.module.css';
import {
  eliminarPaciente,
  obtenerPacientes,
  solicitarAccesos
} from '../../utils/serverRequest';
import { useHistory } from 'react-router-dom';

// Components
import DashboardTitle from '../dashboardTitle/dashboardTitle';
import Buscador from '../Globales/buscador/buscador.jsx';
import Grilla from '../Globales/grilla/grilla.jsx';
import Avatar from '../Globales/avatar/avatar.jsx';
import Paginacion from '../Globales/paginacion/paginacion.jsx';
import Spinner from '../Globales/spinner/spinner.jsx';
import { MdEdit } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';
import ModalPropio from '../Globales/modal/modalPropio';
import Alert from '../Globales/alertPropia/alertPropia';
import { Link } from 'react-router-dom';
import EmptyState from '../Globales/emptyState/emptyState.jsx';
import FormButton from '../Globales/formButton/FormButton';
import ButtonFeedPaciente from '../Globales/ButtonFeedPaciente/ButtonFeedPaciente';
import DropdownTerapeutaModal from '../Globales/dropdownTerapiaModal/DropdownTerapiaModal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {Animated} from 'react-animated-css'

const cantidadPorPagina = 20;

const DashboardPacientesFront = props => {
  //State

  const [pacientesIniciales, setPacientesIniciales] = useState([]);
  const [valorBusqueda, setValorBusqueda] = useState('');
  const [paginaActual, setPaginaActual] = useState(1);
  const [isLoading, setSpinner] = useState(true);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [pacienteSeleccionado, setPacienteSeleccionado] = useState('');
  const [alertSuccess, setAlertSucces] = useState(false);
  const [alertFail, setAlertFail] = useState(false);
  const [alertAccesoSuccess, setAlertAccesoSuccess] = useState(false);
  const [alertAccesoFail, setAlertAccesoFail] = useState(false);
  const [mensajeAlertSuccess, setMensajeAlertSuccess] = useState('');
  const [tituloAlertSuccess, setTituloAlertSuccess] = useState('');
  const [isOpenModalAcceso, setModalAcceso] = useState(false);
  const [terapiasSeleccionadas, setTerapiasSeleccionadas] = useState([]);
  const [isSubmittingDelete, setSubmitDelete] = useState(false);
  const [isSubmittingAccess, setSubmitAccess] = useState(false);
  const [disabledModalButton, setDisabledModalButton] = useState(false);
  let history = useHistory();
  // Context
  const [user, setUser] = useContext(UserContext);
  const idUsuario = user.id;
  const rolUsuario = user.rol;
  const emailUsuario = user.email;

  useEffect(() => {
    let traerPacientes = async () => {
      setSpinner(true);
      let data = await obtenerPacientes();
      if (data.ok) {
        setSpinner(false);
        setPacientesIniciales(data.pacientes);
      } else {
        setSpinner(false);
        alert('Error al importar los pacientes');
      }
    };
    traerPacientes();
    if (props.location && props.location.state) {
      let locationState = props.location.state;
      setTituloAlertSuccess(locationState.titulo);
      setMensajeAlertSuccess(locationState.descripcion);
      setAlertSucces(true);
      setTimeout(() => {
        setAlertSucces(false);
      }, 2000);
      props.history.replace({ state: null });
    } else {
      setAlertSucces(false);
    }
  }, []);

  // Vars

  const cantidadTotalPacientes = pacientesIniciales.filter(
    paciente =>
      paciente.nombreCompleto
        .toLowerCase()
        .indexOf(valorBusqueda.toLocaleLowerCase()) !== -1
  ).length;
  const cantidadPaginas = Math.ceil(cantidadTotalPacientes / cantidadPorPagina);
  let arrayFilas = pacientesIniciales
    .filter(
      paciente =>
        paciente.nombreCompleto
          .toLowerCase()
          .indexOf(valorBusqueda.toLowerCase()) !== -1
    )
    .slice(
      (paginaActual - 1) * cantidadPorPagina,
      paginaActual * cantidadPorPagina
    )
    .map((paciente, key) => (
      <tr key={key}>
        <th scope='row'>
          <Avatar rutaAvatar={paciente.avatar} />
        </th>
        <td>{paciente.nombreCompleto}</td>
        <td>{paciente.obraSocial}</td>
        <td>{paciente.fechaNacimiento}</td>
        <td>
          <div className={styles.containerActions}>
            {rolUsuario !== 'Terapeuta' || emailUsuario === 'giselabisetto@yahoo.com.ar' ? (
              <ButtonFeedPaciente
                atrName={paciente.acceso}
                buttonValue={paciente.acceso}
                buttonState='Activos'
                text='IR AL FORO'
                atrDisabled={false}
                clicked={() => handleRedirectToForo(paciente._id)}
              />
            ) : (
                <>
                  {paciente.acceso === 'Activo' && (
                    <ButtonFeedPaciente
                      atrName={paciente.acceso}
                      buttonValue={paciente.acceso}
                      buttonState={paciente.acceso}
                      text='IR AL FORO'
                      atrDisabled={false}
                      clicked={() => handleRedirectToForo(paciente._id)}
                    />
                  )}
                  {paciente.acceso === 'Ninguno' && (
                    <ButtonFeedPaciente
                      atrName={paciente.acceso}
                      buttonValue={paciente.acceso}
                      buttonState={paciente.acceso}
                      text='SOLICITAR ACCESO'
                      atrDisabled={false}
                      clicked={() => handleModalAcceso(paciente._id)}
                    />
                  )}
                  {paciente.acceso === 'Pendiente' && (
                    <ButtonFeedPaciente
                      atrName={paciente.acceso}
                      buttonValue={paciente.acceso}
                      buttonState={paciente.acceso}
                      atrDisabled={true}
                      text='ACCESO PENDIENTE'
                    />
                  )}
                </>
              )}

            {/* Acciones de Admins */}
            {rolUsuario !== 'Terapeuta' && (
              <div className={styles.containerActionsIcons}>
                <Link
                  to={{
                    pathname: '/panel/pacientes/modificacion',
                    state: {
                      pacienteId: paciente._id
                    }
                  }}
                >
                  <button
                    className={styles.customButtonLeft}
                    style={{ height: '40px' }}
                  >
                    <MdEdit
                      color='#7f7f7f'
                      size={20}
                      className={styles.customSvg + ' ' + styles.customSvgEdit}
                    />
                  </button>
                </Link>
                {rolUsuario === 'Admin' ? (
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip>
                        El paciente podrá ser eliminada únicamente por un
                        usuario con rol Superadmin
                      </Tooltip>
                    }
                  >
                    <button className={styles.customButtonRight} disabled>
                      <IoMdTrash
                        color='#dbdbdb'
                        size={20}
                        className={styles.customSvg}
                      />
                    </button>
                  </OverlayTrigger>
                ) : (
                    <button
                      className={styles.customButtonRight}
                      onClick={() => handleEliminar(paciente)}
                    >
                      <IoMdTrash
                        color='#7f7f7f'
                        size={20}
                        className={
                          styles.customSvg + ' ' + styles.customSvgDelete
                        }
                      />
                    </button>
                  )}
              </div>
            )}
          </div>
        </td>
      </tr>
    ));

  // Functions

  const actualizarValorBusqueda = e => {
    setValorBusqueda(e.target.value);
    setPaginaActual(1);
  };

  const handleEliminar = paciente => {
    setPacienteSeleccionado(paciente);
    setModalEliminar(true);
  };

  const borrarPaciente = async () => {
    setSubmitDelete(true);
    let data = await eliminarPaciente(pacienteSeleccionado._id);
    setModalEliminar(false);

    if (data.ok) {
      setTituloAlertSuccess('Eliminación exitosa.');
      setMensajeAlertSuccess(
        `Se ha eliminado correctamente a ${pacienteSeleccionado.nombreCompleto}`
      );
      setSubmitDelete(false);
      setAlertSucces(true);
      setTimeout(() => {
        setAlertSucces(false);
      }, 3000);
      setPacientesIniciales(
        pacientesIniciales.filter(p => p._id !== pacienteSeleccionado._id)
      );
    } else {
      setSubmitDelete(false);
      setAlertFail(true);
      setTimeout(() => {
        setAlertFail(false);
      }, 3000);
    }
  };

  const handleModalAcceso = pacienteId => {
    setPacienteSeleccionado(pacienteId);
    setModalAcceso(true);
  };

  const handleRedirectToForo = idPaciente => {
    history.push(`/panel/pacientes/perfil/${idPaciente}`);
  };

  const changeButtonAcceso = pacienteId => {
    let copiaPacientesIniciales = [...pacientesIniciales];
    for (let i = 0; i < copiaPacientesIniciales.length; i++) {
      if (copiaPacientesIniciales[i]._id === pacienteId) {
        copiaPacientesIniciales[i].acceso = 'Pendiente';
      }
    }
    setPacientesIniciales(copiaPacientesIniciales);
  };

  const sendAccesos = async accesos => {
    setSubmitAccess(true);
    let data = await solicitarAccesos(accesos);
    setModalAcceso(false);
    if (data.ok) {
      let pacienteId = accesos[0].pacienteId;
      changeButtonAcceso(pacienteId);
      setSubmitAccess(false);
      setAlertAccesoSuccess(true);
      setTerapiasSeleccionadas([]);
      setTimeout(() => {
        setAlertAccesoSuccess(false);
      }, 3000);
    } else {
      setSubmitAccess(false);
      setAlertAccesoFail(true);
      setTerapiasSeleccionadas([]);
      setTimeout(() => {
        setAlertAccesoFail(false);
      }, 3000);
    }
  };

  const desactivarBotonModal = decision => {
    setDisabledModalButton(decision);
  };

  const cancelarModalSolicitudAcceso = respuesta => {
    setTerapiasSeleccionadas([]);
    setModalAcceso(respuesta);
  };

  return (
    <div className='dashboardContainer'>
      <DashboardTitle title='Pacientes' />

      {pacientesIniciales.length > 0 && (
        <Animated animationIn="fadeIn">
        <div className={styles.searchContainer + ' row'}>
          <div className={'col-md-7 col-sm-12 ' + styles.searchBoxCon}>
            <Buscador
              placeholder='Buscar pacientes por nombre o apellido...'
              handler={actualizarValorBusqueda}
            />
          </div>
          {rolUsuario !== 'Terapeuta' && (
            <div className={'col-lg-3 col-md-4 col-sm-12 ' + styles.btnCon}>
              <Link to='/panel/pacientes/creacion'>
                <FormButton
                  text='AGREGAR PACIENTE'
                  buttonType='button'
                  buttonSize='medium'
                  buttonStyle='primario'
                  atrName='agregar'
                />
              </Link>
            </div>
          )}
        </div>
          </Animated>
      )}

      {alertSuccess && (
        <Alert variant='success' show={alertSuccess}>
          <strong>{tituloAlertSuccess}</strong> {mensajeAlertSuccess}
        </Alert>
      )}
      {alertFail && (
        <Alert variant='danger' show={alertFail}>
          <strong>Eliminación fallida.</strong> No se ha podido eliminar a{' '}
          {pacienteSeleccionado.nombreCompleto}{' '}
        </Alert>
      )}
      {alertAccesoSuccess && (
        <Alert variant='success' show={alertAccesoSuccess}>
          <strong>Solicitud de acceso exitosa. </strong>El administrador deberá
          aprobar tu solicitud.
        </Alert>
      )}
      {alertAccesoFail && (
        <Alert variant='danger' show={alertAccesoFail}>
          <strong>Solicitud de acceso fallida. </strong>Por favor, intentelo
          nuevamente.
        </Alert>
      )}

      {isLoading ? (
        <Spinner />
      ) : pacientesIniciales.length === 0 ? (
        <EmptyState
          ruta='/images/undraw.svg'
          titulo='No hay pacientes creados.'
          leyenda='Si querés crear un nuevo paciente, podes hacerlo haciendo click en el siguiente botón.'
          leyenda2='Recuerda que al crear un paciente, se generará un foro de debate con los terapeutas asociados.'
        >
          <div
            className={
              'col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 ' +
              styles.buttonEmptyState
            }
          >
            <Link to='/panel/pacientes/creacion'>
              <FormButton
                text='AGREGAR PACIENTE'
                buttonType='button'
                buttonSize='medium'
                buttonStyle='primario'
                atrName='agregar'
              />
            </Link>
          </div>
        </EmptyState>
      ) : arrayFilas.length === 0 ? (
        <div style={{ marginTop: '98px' }}>
          <EmptyState
            ruta='/images/vacio.svg'
            subtitulo={`No se encontraron pacientes con "${valorBusqueda}"`}
          />
        </div>
      ) : (
              <Grilla
                titulos={[
                  'Avatar',
                  'Nombre Completo',
                  'Obra Social',
                  'Fecha de nacimiento',
                  'Acciones'
                ]}
                filas={arrayFilas}
              />
            )}

      {arrayFilas.length !== 0 ? (
        <Paginacion
          cantidad={cantidadPaginas}
          paginaActual={paginaActual}
          handler={setPaginaActual}
        />
      ) : (
          <></>
        )}
      {modalEliminar && (
        <ModalPropio
          title='Eliminar Paciente'
          content={`Está seguro que quiere eliminar al paciente ${pacienteSeleccionado.nombreCompleto}? Se podrían perder datos guardados`}
          isOpen={modalEliminar}
          closeModal={setModalEliminar}
          okHandler={borrarPaciente}
          isSubmitting={isSubmittingDelete}
          danger={true}
          botonPrimario='Eliminar'
          botonSecundario='Cancelar'
        />
      )}
      {isOpenModalAcceso && (
        <ModalPropio
          title='Solicitar acceso'
          content='A continuación deberá seleccionar todas las terapias que desea tener acceso y el tipo de acceso para cada una.'
          isOpen={isOpenModalAcceso}
          closeModal={cancelarModalSolicitudAcceso}
          isSubmitting={isSubmittingAccess}
          disabledButton={disabledModalButton}
          okHandler={() => sendAccesos(terapiasSeleccionadas)}
          botonPrimario='Solicitar'
          botonSecundario='Cancelar'
        >
          <DropdownTerapeutaModal
            id_terapeuta={idUsuario}
            id_paciente={pacienteSeleccionado}
            buttonTitle='AGREGAR TERAPIA'
            emptyState={`Aún no has seleccionado ninguna terapia`}
            terapiasSeleccionadas={terapiasSeleccionadas}
            setTerapiasSeleccionadas={setTerapiasSeleccionadas}
            disableModalButton={desactivarBotonModal}
          />
        </ModalPropio>
      )}
    </div>
  );
};

export default DashboardPacientesFront;
