import React, { useState, useEffect } from 'react';
import OptionLabel from '../optionLabel/optionLabel';
import { MdClose } from 'react-icons/md';
import styles from './dropdownTerapiaModal.module.css';
import Spinner from '../spinner/spinner';
import { obtenerTerapiasTerapeutaPaciente } from '../../../utils/serverRequest';

const DropdownTerapiaModal = props => {
  const [terapiasList, setTerapiasList] = useState([]);
  const [terapiaSeleccionada, setTerapiaSeleccionada] = useState({
    terapiaId: '',
    titulo: ''
  });
  const [tipoAccesoSeleccionado, setTipoAccesosSeleccionado] = useState('');
  const [isLoading, setSpinner] = useState(true);

  // Service call
  useEffect(() => {
    const traerTerapias = async () => {
      let res = await obtenerTerapiasTerapeutaPaciente(
        props.id_terapeuta,
        props.id_paciente
      );
      if (res.data.ok) {
        setTerapiasList(res.data.terapias);
        setSpinner(false);
      } else {
        setSpinner(false);
        alert('Error al importar las terapias');
      }
    };
    traerTerapias();
  }, []);

  const handleTipoAcceso = e => {
    e.preventDefault();
    setTipoAccesosSeleccionado(e.target.value);
  };

  const handleTerapiaSeleccionada = e => {
    e.preventDefault();
    setTerapiaSeleccionada({
      terapiaId: e.target.value,
      titulo: e.target.options[e.target.selectedIndex].text
    });
  };

  const handleAgregar = e => {
    if (!terapiaSeleccionada.terapiaId || !tipoAccesoSeleccionado) {
      alert('Deberá seleccionar una terapia y el tipo de acceso');
    } else {
      let nuevoAcceso = {
        terapeutaId: props.id_terapeuta,
        pacienteId: props.id_paciente,
        terapia: terapiaSeleccionada,
        tipoAcceso: tipoAccesoSeleccionado
      };
      let nuevasTerapiasSeleccionadas = [
        ...props.terapiasSeleccionadas,
        nuevoAcceso
      ];
      props.setTerapiasSeleccionadas(nuevasTerapiasSeleccionadas);
      let nuevoDropdown = [
        ...terapiasList.filter(
          terapia => terapia._id !== terapiaSeleccionada.terapiaId
        )
      ];
      setTerapiasList(nuevoDropdown);
      setTerapiaSeleccionada({ terapiaId: '', titulo: '' });
      setTipoAccesosSeleccionado('');
    }
  };

  const handleRemover = terapiaId => {
    let nuevoElemento = props.terapiasSeleccionadas.filter(
      tt => tt.terapia.terapiaId == terapiaId
    );

    let pushToDropdown = {
      _id: nuevoElemento[0].terapia.terapiaId,
      titulo: nuevoElemento[0].terapia.titulo
    };

    setTerapiasList([...terapiasList, pushToDropdown]);

    let nuevasTerapiasSeleccionadas = props.terapiasSeleccionadas.filter(
      tt => tt.terapia.terapiaId !== terapiaId
    );

    props.setTerapiasSeleccionadas(nuevasTerapiasSeleccionadas);
  };

  return (
    <div className='row'>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {terapiasList.length > 0 || props.terapiasSeleccionadas.length > 0 ? (
            <>
              {props.disableModalButton(true)}
              <div className='col-8'>
                <div className='form-group form-group-lg'>
                  <label
                    className='control-label label-label'
                    style={{ float: 'left' }}
                    htmlFor='list'
                  >
                    Terapia
                  </label>
                  <select
                    className='select form-control'
                    id='list'
                    onChange={handleTerapiaSeleccionada}
                    value={terapiaSeleccionada.terapiaId}
                  >
                    <option selected value=''></option>
                    {terapiasList.length > 0 ? (
                      terapiasList.map((item, key) => (
                        <OptionLabel
                          key={key}
                          text={item.titulo}
                          value={item._id}
                        />
                      ))
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>
              <div className='col-4'>
                <label
                  className='control-label label-label'
                  style={{ float: 'left' }}
                  htmlFor='accesos'
                >
                  Tipo de acceso
                </label>
                <select
                  className='select form-control'
                  id='accesos'
                  value={tipoAccesoSeleccionado}
                  onChange={handleTipoAcceso}
                >
                  <option selected value=''></option>
                  <option value='Escritura'>Escritura</option>
                  <option value='Lectura'>Lectura</option>
                </select>
              </div>
              <div className={'col-lg-4 col-12 ' + styles.containerButton}>
                <button
                  type='button'
                  className={
                    'btn btn-outline-primary btn-block ' +
                    styles.btnGeneralStyle
                  }
                  onClick={handleAgregar}
                >
                  {props.buttonTitle}
                </button>
              </div>
              <div className='col-12'>
                <ul className={styles.selectedList + ' col-12'}>
                  {props.terapiasSeleccionadas.length > 0 ? (
                    <>
                      {props.disableModalButton(false)}
                      {props.terapiasSeleccionadas.map((item, key) => (
                        <li key={key} className={styles.selectedItem}>
                          <MdClose
                            className={styles.deleteSelectedItem}
                            color='red'
                            onClick={() =>
                              handleRemover(item.terapia.terapiaId)
                            }
                          />
                          <p className={styles.terapeutaName}>
                            {item.terapia.titulo} - {item.tipoAcceso}
                          </p>
                        </li>
                      ))}
                    </>
                  ) : (
                    <>
                      {props.disableModalButton(true)}
                      <li style={{ whiteSpace: 'nowrap' }}>
                        {props.emptyState}
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </>
          ) : (
            <div className='col-12'>
              {props.disableModalButton(true)}
              <p>
                <strong>No tenés terapias en común con el paciente. </strong>
                Si deseas tener acceso para una terapia en particular, primero
                debés solicitarle al administrador que agregue dicha terapia en
                el perfil del paciente.
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default DropdownTerapiaModal;
