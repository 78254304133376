import React from 'react';
import styles from './avatar.module.css';
import PropTypes from 'prop-types'
import Image from 'react-bootstrap/Image'

const Avatar = (props) => {
  let avatarSize;

  switch (props.size) {
    case 'xs':
      avatarSize = styles.avatarContainerXS;
      break;
    case 's':
      avatarSize = styles.avatarContainerS;
      break;
    case 'm':
      avatarSize = styles.avatarContainerM;
      break;
    case 'l':
      avatarSize = styles.avatarContainerL;
      break;
    case 'xl':
      avatarSize = styles.avatarContainerXL;
      break;
    default:
      avatarSize = styles.avatarContainerM;
      break;
  }

  return (
    <div style={{display: 'inline-block'}}>  
      <img
        className={`${styles.avatar} + ${avatarSize}`}
        src={props.rutaAvatar || '/images/avatar.png'}
        alt=''
      />
    </div>
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.string
}

export default Avatar;
