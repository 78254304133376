//Packages & Utils
import React from 'react';
import styles from './buttonsTab.module.css';

const ButtonsTab = props => {
  
  let buttonActiveClasses =
    'btn ' + styles.active + ' ' + styles.particularBtnClass;
  let buttonNotActiveClasses =
    'btn ' + styles.notActive + ' ' + styles.particularBtnClass;

  

  return (
    <div className={styles.buttonsContainer}>
      <button
        className={
          props.tseleccionada === ''
            ? buttonActiveClasses
            : buttonNotActiveClasses
        }
        onClick={() => props.clicked('')}
      >
        Mostrar todas
      </button>
      {props.data.map((item, index) => (
        <button
          className={
            props.tseleccionada === item._id
              ? buttonActiveClasses
              : buttonNotActiveClasses
          }
          onClick={() => props.clicked(item._id)}
          key={index}
        >
          {item.titulo}
        </button>
      ))}
    </div>
  );
};

export default ButtonsTab;
