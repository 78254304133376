import React from "react";
import PropTypes from "prop-types";
import styles from "../itemsStyles.module.css";
import PostFilePreview from "../../postInfo/postFilePreview";

const FilesItem = (props) => {
  return (
    <div className={"row " + styles.rowStyle}>
      <div className={"col-12 " + styles.dataBox}>
        {props.archivos.length === 0 ? (
          <p className={"col-xl-9 col-lg-6 " + styles.textValue}>El paciente no tiene archivos cargados</p>
        ) : (
          <ul style={{ padding: "0px 0px 0px 0px" }}>
            {props.archivos.map((file, index) => (
              <PostFilePreview originalLink={file.originalLink} presignedUrl={file.presignedUrl} key={index} />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

FilesItem.propTypes = {
  archivos: PropTypes.array,
};

export default FilesItem;
