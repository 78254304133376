import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Overlay from 'react-bootstrap/Overlay'

const CustomOverlay = (props) => {

    return (
        <OverlayTrigger key={props.customKey} placement="top" overlay={<Tooltip id={`tooltip-${props.key}`}>{props.autores.map((autor, key) => (autor))}</Tooltip>}>
            {props.children}
        </OverlayTrigger>

    )
}

export default CustomOverlay