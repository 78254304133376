import React, { useState, useEffect } from 'react';
import {Carousel} from 'react-bootstrap';
import styles from './imagesCarousel.module.css';
import ReactPlayer from 'react-player'
import {FaArrowLeft, FaArrowRight} from 'react-icons/fa'

const ImagesCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(props.index || 0);
  const [videoPlaying, setVideoPlaying] = useState(false)

  useEffect(() => {
    setVideoPlaying(false)
  }, [activeIndex])

  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };

  return (
        <Carousel tabIndex="1" slide={false} keyboard={true} nextIcon={<FaArrowRight size='72px'/>} prevIcon={<FaArrowLeft size='72px'/>} className={styles.centerScreen} activeIndex={activeIndex} onSelect={handleSelect} interval={null} onSlide={() => {setVideoPlaying(false)}}>
        
          {props.videos.map((video, key) => (
            <Carousel.Item key={key}>
              <ReactPlayer height='500px' width='100%' url={video} controls={true} playing={videoPlaying && key === activeIndex} onPlay={() => setVideoPlaying(true)} onPause={() => setVideoPlaying(false)}/>
            </Carousel.Item>
          ))}

          {props.imagenes.map((imagen, key) => (
            <Carousel.Item key={key} style={{maxHeight: '500px', objectFit: 'cover'}}>
              <img alt="" className={`${styles.carouselImg}`}
              src={imagen}
              />
            </Carousel.Item>
          ))}
        </Carousel>
  );
}

export default ImagesCarousel;