import React from 'react'
import UserListItem from '../userListItem/userListItem'
import {Twemoji} from 'react-emoji-render';


const ReactionListItem = (props) => {

    return (
        <div className="d-flex justify-content-between align-items-center mb-2">
            <UserListItem user={props.user}/>
            <Twemoji text={props.reaction.emojiCode}/>
        </div>
    )
}

export default ReactionListItem