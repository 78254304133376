//Packages & Utils
import React, { useState, useEffect, useContext } from "react";
import styles from "./groupedComments.module.css";
import { UserContext } from "../../context/context";

// Components
import PostComment from "../postComment/PostComment";
import Spinner from "../spinner/spinner";
import { obtenerComentarios, comentarPostPaciente, comentarPostTerapia, borrarComentario } from "../../../utils/serverRequest";
import ModalPropio from "../modal/modalPropio";
import ModalCommentEditor from "../modalCommentEditor/modalCommentEditor";
import UserEditor from "../userEditor/userEditor";

const GroupedComments = (props) => {
  const [user, setUser] = useContext(UserContext);
  const [isLoading, setSpinner] = useState(false); // change to true when integrate with backend
  const [isLoadingMoreComments, setSpinnerMoreComments] = useState(false);
  const [dataComentario, setDataComentario] = useState({
    contenido: "",
    rawContent: "",
    plainText: "",
    mentions: [],
  });
  const [shouldBlockNavigation, setshouldBlockNavigation] = useState(false);
  const [comentarioSeleccionado, setComentarioSeleccionado] = useState("");
  const [comments, setComments] = useState([]);
  const [totalPaginas, setTotalPaginas] = useState();
  const [isOpenModalBorrarComentario, setModalBorrarComentario] = useState(false);
  const [isSubmittingDeleteComment, setSubmitDeleteComment] = useState(false);
  const [isSubmittingCommentPost, setSubmitCommentPost] = useState(false);
  const [actualPage, setActualPage] = useState(1);
  const [totalElementos, setTotalElementos] = useState("");
  const [modalCommentEditor, setmodalCommentEditor] = useState(false);

  useEffect(() => {
    if (props.tieneComentarios) {
      setSpinner(true);
      let traerComentarios = async () => {
        let data = await obtenerComentarios(props.idPost, actualPage);
        if (data.ok) {
          setComments(data.comments);
          setTotalPaginas(data.totalPaginas);
          setTotalElementos(data.totalElementos);
        } else {
          console.log("Error al obtener comentarios");
        }
        setSpinner(false);
      };
      traerComentarios();
    }
  }, []);

  //Funcion para hacer un comentario

  const handleCommentPost = async () => {
    if (!isEmpty()) {
      let nuevoComentario = {
        contenido: dataComentario.contenido,
        rawContent: dataComentario.rawContent,
        mentions: dataComentario.mentions,
        creador: user.id,
        post: props.idPost,
      };

      setSubmitCommentPost(true);
      let data;

      if (props.idPaciente) {
        data = await comentarPostPaciente(nuevoComentario, props.idPaciente);
      } else if (props.idTerapia) {
        data = await comentarPostTerapia(nuevoComentario, props.idTerapia);
      }

      if (data && data.ok) {
        setComments([...comments, data.comentario]);
        setTotalElementos(totalElementos + 1);
        props.handleCantidadComentarios(1);
        setDataComentario({
          contenido: "",
          rawContent: "",
          mentions: [],
        });
        setSpinner(false);
        setmodalCommentEditor(false);
      } else {
        alert("error al publicar comentario");
      }
      setSubmitCommentPost(false);
    }
  };

  const isEmpty = () => {
    return dataComentario.contenido === "" || dataComentario.contenido === "<p><br></p>";
  };

  const handleChange = (commentText, mentions, plainText) => {
    setDataComentario({
      ...dataComentario,
      contenido: commentText,
      mentions,
      plainText,
    });

    if (commentText !== "" && commentText !== "<p><br></p>") {
      setshouldBlockNavigation(true);
    } else {
      setshouldBlockNavigation(false);
    }
  };

  const handleModalBorrarComentario = (comentarioId) => {
    setModalBorrarComentario(true);
    setComentarioSeleccionado(comentarioId);
  };

  const deleteComentario = async (comentarioId) => {
    setSubmitDeleteComment(true);
    let data = await borrarComentario(comentarioId);
    if (data.ok) {
      setModalBorrarComentario(false);
      setTotalElementos(totalElementos - 1);
      props.handleCantidadComentarios(-1);
      setComments(
        comments.filter((comment) => {
          return comment._id !== comentarioId;
        })
      );
    } else {
      alert("Hubo un error al eliminar el comentario");
    }
    setSubmitDeleteComment(false);
  };

  const handleLoadMoreComments = async () => {
    setSpinnerMoreComments(true);
    let data = await obtenerComentarios(props.idPost, actualPage + 1);
    if (data.ok) {
      let newComments = [...data.comments, ...comments];
      setComments(newComments);
    } else {
      console.log("Error al obtener mas comentarios");
    }
    setSpinnerMoreComments(false);
    setActualPage(actualPage + 1);
  };

  const handleCloseComments = () => {
    let comentariosFinales = [...comments].splice(-3, 3);
    setActualPage(1);
    setComments(comentariosFinales);
  };

  const clearEditor = () => {
    setDataComentario({
      contenido: "",
      mentions: [],
      plainText: "",
      rawContent: "",
    });
  };

  return (
    <>
      {isOpenModalBorrarComentario && (
        <ModalPropio
          title="Eliminar comentario"
          content={"¿Está seguro que quiere eliminar el comentario?"}
          isOpen={isOpenModalBorrarComentario}
          danger={true}
          closeModal={setModalBorrarComentario}
          isSubmitting={isSubmittingDeleteComment}
          okHandler={() => deleteComentario(comentarioSeleccionado)}
          botonPrimario="Eliminar"
          botonSecundario="Cancelar"
        />
      )}

      {modalCommentEditor && (
        <ModalCommentEditor
          feed={props.feed}
          nombre={props.nombre}
          terapiasTerapeutaEscritura={props.terapiasActivas}
          idTerapia={props.idTerapia}
          handleSubmitComment={handleCommentPost}
          shouldBlockNavigation={shouldBlockNavigation}
          idPaciente={props.idPaciente}
          isSubmitting={isSubmittingCommentPost}
          possibleMentions={props.possibleMentions}
          modalPostEditor={modalCommentEditor}
          closeModal={() => setmodalCommentEditor(false)}
          title="Crear comentario"
          placeholder="Ingresá acá un comentario"
          handleChange={handleChange}
          isEmpty={isEmpty}
          dataComentario={dataComentario}
          clearEditor={clearEditor}
        />
      )}

      {isLoading ? (
        <div className={styles.spinnerContainer}>
          <Spinner spinnerType="small" />
        </div>
      ) : (
        <div style={{ padding: totalPaginas > 1 ? "16px 0 0 0" : "0", margin: props.tieneComentarios ? "0 0 0 0" : "0" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {actualPage < totalPaginas && (
              <div className={`${styles.loadMoreContainer} d-inline-block`}>
                <a className={styles.linkLoadMore} onClick={handleLoadMoreComments} disabled>
                  Ver comentarios anteriores ({totalElementos - actualPage * 3})
                </a>
                {isLoadingMoreComments && (
                  <div className="d-inline-block ml-1">
                    <Spinner spinnerType="extrasmall" />
                  </div>
                )}
              </div>
            )}
            {/* Para cerrar comentarios sin llegar al último*/}
            {actualPage !== 1 ? (
              <a className={`${styles.linkLoadMore} ml-auto mr-4 pt-1 pt-md-0`} onClick={handleCloseComments}>
                Cerrar comentarios
              </a>
            ) : (
              <></>
            )}
          </div>
          <div id="postCommentContainer">
            {comments.length > 0 &&
              comments.map((comment, index) => (
                <PostComment
                  key={comment._id}
                  avatarTerapeuta={comment.creador.avatar}
                  nombreTerapeuta={comment.creador.nombreCompleto}
                  idCreador={comment.creador._id}
                  antiguedadComentario={comment.fechaCreacion}
                  textoComentario={comment.contenido}
                  rawContent={comment.rawContent || ""}
                  modalBorrarComentario={handleModalBorrarComentario}
                  idComentario={comment._id}
                  idUsuario={props.idUsuario}
                  rolUsuario={props.rolUsuario}
                  views={comment.views}
                  isViewed={comment.isViewed}
                  reaccionesPosibles={props.reaccionesPosibles}
                  defaultReaction={props.defaultReaction}
                  reaccionesDadas={comment.reacciones}
                  dioReaccion={comment.dioReaccion}
                  cantidadReacciones={comment.cantidadReacciones}
                  comentarioReaccion={comment.comentarioReaccion}
                  reaccionDada={comment.reaccionDada}
                />
              ))}
          </div>
        </div>
      )}
      <UserEditor
        avatarSource={user.avatar}
        avatarSize="md"
        placeholder={
          dataComentario.plainText && dataComentario.plainText !== "\n" && !modalCommentEditor
            ? dataComentario.plainText
            : "Ingresá acá un comentario"
        }
        onClick={() => setmodalCommentEditor(true)}
        comment={true}
      />
    </>
  );
};

export default GroupedComments;
