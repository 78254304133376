import React, { useState, useRef } from 'react';
import styles from './comentarioReaccionButton.module.css';
import Overlay from 'react-bootstrap/Overlay';
import { Twemoji } from 'react-emoji-render';
import ReaccionesPosibles from '../reaccionesPosibles/reaccionesPosibles';

const ComentarioReaccionButton = props => {
  const [openTooltipTrigger, setOpenTooltipTrigger] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const target = useRef(null);

  const handleNewReaction = (reaction) => {
    setOpenTooltip(false)
    setOpenTooltipTrigger(false)
    props.handleNuevaReaccion(reaction)
  }

  let popoverItem = (
    <div
      placement='top'
      className={styles.tooltipContainer}
      onMouseOver={() => setOpenTooltip(true)}
      onMouseEnter={() => setOpenTooltip(true)}
      onMouseLeave={() => setOpenTooltip(false)}
    >
      <ReaccionesPosibles
        reacciones={props.reaccionesPosibles}
        handleClick={handleNewReaction}
        dioReaccion={props.dioReaccion}
      />
    </div>
  );

  let classReaccionesButtonContainer;
  if (props.dioReaccion) {
    classReaccionesButtonContainer = styles.reaccionesButtonContainerConReaccion;
  } else {
    classReaccionesButtonContainer = styles.reaccionesButtonContainerSinReaccion;
  }

  return (
    <div>
      <Overlay target={target.current} show={openTooltip || openTooltipTrigger}>
        {popoverItem}
      </Overlay>
        <a
          unselectable="on"
          onContextMenu={(e) => {
            e.preventDefault()
            return false}}
          className={styles.noselect}
          href="#"
          style={{fontSize: '14px', fontWeight: props.dioReaccion ? 'bold' : '400'}}
          ref={target}
          onMouseEnter={() => setOpenTooltipTrigger(true)}
          onMouseLeave={() => setOpenTooltipTrigger(false)}
          onTouchEnd={() => !props.dioReaccion ? setOpenTooltipTrigger(true) : ''}
          onClick={
            props.isLoading ? (e) => e.preventDefault() :
            props.dioReaccion && props.comentarioReaccion
              ? (e) => props.removeReaccion(e, props.comentarioReaccion)
              : (e) => {
                e.preventDefault()
                handleNewReaction(props.defaultReaction)
                // setOpenTooltipTrigger(true)
              }
          }          
        >
          {props.dioReaccion ? props.reaccionDada.titulo || 'Me gusta' : 'Me gusta'}
        </a>
    </div>
  );
};

export default ComentarioReaccionButton;
