import React from 'react';
import UserNavbar from '../userNavbar/userNavbar';
import './navbar.css';
import { MdMenu } from 'react-icons/md';

const Navbar = props => {
  return (
    <div id='navbar' className='row position-fixed navbarDonadio'>
      <button className='d-md-none btn' onClick={props.sidebarToggler}>
        <i>
          <MdMenu />
        </i>
      </button>
      <UserNavbar />
    </div>
  );
};

export default Navbar;
