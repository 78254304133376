//Packages & Utils
import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import styles from "./post.module.css";
import draftStyles from "../draftEditors/common.module.css";
import { UserContext } from "../../context/context";
import { IoMdTrash } from "react-icons/io";
import { EditorState, convertFromRaw } from "draft-js";
import Editor from "draft-js-plugins-editor";
import createMentionPlugin from "draft-js-mention-plugin";
import "draft-js/dist/Draft.css";
import "draft-js-mention-plugin/lib/plugin.css";
import { updatePost, crearPostReaccion, eliminarPostReaccion } from "../../../utils/serverRequest";

//Components
import Avatar from "../avatar/avatar.jsx";
import Badge from "../badge/Badge";
import GroupedComments from "../groupedComments/GroupedComments";
import ImagePost from "../../imagePost/imagePost";
import Spinner from "../spinner/spinner";
import ReaccionesButton from "../reaccionesButton/reaccionesButton";
import PostInfo from "../postInfo/postInfo";
import ReaccionesDadas from "../reaccionesDadas/reaccionesDadas";
import Modal from "../modalWithoutFooter/modalWithoutFooter";
import ReactionsTab from "../reactionTabs/reactionTabs";
import ReactQuill from "react-quill";

const mentionPlugin = createMentionPlugin();
const plugins = [mentionPlugin];
const textMaxHeight = 128; // Tiene que ser igual que en ../draftEditors/common.module.css

const Post = React.forwardRef((props, ref) => {
  // Context
  const [user, setUser] = useContext(UserContext);
  const rolUsuario = user.rol;
  const idUsuario = user.id;
  const postTextRef = useRef(null);
  // State
  const [viewed, setViewed] = useState(props.isViewed);
  const [editorState, setEditorState] = useState(props.rawContent ? EditorState.createWithContent(convertFromRaw(props.rawContent)) : {});
  const [dioReaccion, setDioReaccion] = useState(false);
  const [reaccionDada, setReaccionDada] = useState("");
  const [postReaccion, setPostReaccion] = useState("");
  const [reaccionesDadas, setReaccionesDadas] = useState([]);
  const [cantidadReacciones, setCantidadReacciones] = useState(0);
  const [spinnerReaction, setspinnerReaccion] = useState(false);
  const [cantidadComentarios, setcantidadComentarios] = useState(0);
  const [modalReactionsOpen, setModalReactionsOpen] = useState(false);
  const [expanded, setexpanded] = useState(true);

  useEffect(() => {
    if (postTextRef.current.clientHeight > textMaxHeight) {
      setexpanded(false);
    }

    setViewed(props.isViewed);
    // setEditorState(props.rawContent ? EditorState.createWithContent(convertFromRaw(props.rawContent)) : '')
    setDioReaccion(props.dioReaccion || false);
    setReaccionDada(props.reaccionDada);
    setPostReaccion(props.postReaccion);
    setReaccionesDadas(props.reacciones);
    setCantidadReacciones(props.cantidadReacciones);
    setcantidadComentarios(props.cantidadComentarios);
  }, [props.rawContent]);

  // Views Tracker
  const observer = useRef();
  var onePostRef = useCallback((node) => {
    if (props.views && props.views.some((v) => v.viewer.id === user.id)) {
      return;
    }

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(async (entries) => {
      if (entries[0].isIntersecting && !viewed && user.nombreCompleto !== "Superadmin") {
        setViewed(true);
        let res = await updatePost(props.idPost, { $push: { views: { viewer: user, readAt: new Date() } } });
        if (!res.data.ok) {
          console.log("Error");
          setViewed(false);
        }
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  });

  const handleReaccion = async (reaccion) => {
    setspinnerReaccion(true);
    let reaccionesBackUp = [...reaccionesDadas];
    setDioReaccion(true);
    setReaccionDada(reaccion);
    setCantidadReacciones((cantidadReacciones) => cantidadReacciones + 1);
    let data = await crearPostReaccion(props.idPost, reaccion);
    addReaccionFront(data.reaccion);
    setPostReaccion(data.reaccion);

    if (!data.ok) {
      setDioReaccion(false);
      setReaccionDada("");
      setCantidadReacciones((cantidadReacciones) => cantidadReacciones - 1);
      setReaccionesDadas(reaccionesBackUp);
      setPostReaccion("");
      alert("Error al guardar la reacción");
    }
    setspinnerReaccion(false);
  };

  // Agrega la reaccón al grupo correspondiente
  const addReaccionFront = (reaccion) => {
    let newReacciones;
    if (reaccionesDadas.length === 0) {
      newReacciones = [[reaccion.reaccion.emojiCode, [reaccion]]];
      setReaccionesDadas(newReacciones);
    } else {
      if (reaccionesDadas.some((re) => re[0] === reaccion.reaccion.emojiCode)) {
        newReacciones = reaccionesDadas.map((r) => {
          if (r[0] === reaccion.reaccion.emojiCode) {
            return [r[0], [...r[1], reaccion]];
          } else {
            return r;
          }
        });
        setReaccionesDadas(newReacciones);
      } else {
        newReacciones = [...reaccionesDadas, [reaccion.reaccion.emojiCode, [reaccion]]];
        setReaccionesDadas(newReacciones);
      }
    }
  };

  const removeReaccion = async (postReaccion) => {
    setspinnerReaccion(true);
    let reaccionesBackUp = [...reaccionesDadas];
    setDioReaccion(false);
    removeReaccionFront(postReaccion);
    setCantidadReacciones((cantidadReacciones) => cantidadReacciones - 1);
    setPostReaccion("");
    let data = await eliminarPostReaccion(postReaccion._id, props.idPost);
    if (!data.ok) {
      setDioReaccion(true);
      setReaccionesDadas(reaccionesBackUp);
      setCantidadReacciones((cantidadReacciones) => cantidadReacciones + 1);
      alert("Error al sacar la reacción");
    }
    setspinnerReaccion(false);
  };

  // TODO: Ver la estructura de lo que mando desde el server para entender esto (Mando reacciones agrupadas por emojiCode)
  const removeReaccionFront = (postReaccion) => {
    let newReacciones = reaccionesDadas.map((pr) => {
      if (pr[0] === postReaccion.reaccion.emojiCode) {
        return pr[1].length === 1 ? [] : [pr[0], pr[1].filter((pr) => pr._id !== postReaccion._id)];
      } else {
        return pr;
      }
    });

    setReaccionesDadas(newReacciones.filter((r) => r.length > 0));
  };

  const handleCantidadComentarios = (qty) => {
    setcantidadComentarios(cantidadComentarios + qty);
  };

  const expandHandler = (e) => {
    e.preventDefault(setexpanded(true));
  };

  const handleReactionsModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setModalReactionsOpen(!modalReactionsOpen);
  };

  return (
    <div className={styles.box} ref={ref}>
      <div
        style={{
          height: "6px",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
          background: `linear-gradient(to right, rgba(255,255,255,1) -25%, ${props.colorTerapia} 38%, ${props.colorTerapia} 100%)`,
        }}
      ></div>
      <div className={styles.firstRowClass + " row"}>
        <div className={"col-md-1 d-none d-md-block " + styles.avatarColumn}>
          <Avatar rutaAvatar={props.avatarTerapeuta} size="m" />
        </div>
        <div className={styles.inputContainerClass + " col-12 col-md-11"}>
          <div className={styles.containerPersonalData}>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <div className="d-sm-inline-block d-md-none mr-3" style={{ width: "32px", marginBottom: "8px" }}>
                  <Avatar rutaAvatar={props.avatarTerapeuta} size="s" />
                </div>

                <div>
                  <p className={`${styles.nombrePacienteClass} selectableText`}>{props.nombreTerapeuta}</p>
                  <small className={styles.smallAntiguedad}>{props.antiguedadPost}</small>
                  {props.feed === "paciente" && (
                    <div className="d-sm-block d-md-none">
                      <Badge colorHex={props.colorTerapia} nombreTerapia={props.nombreTerapia} />
                    </div>
                  )}
                </div>
              </div>
              {(rolUsuario === "Superadmin" || idUsuario === props.idCreador) && (
                <button className={styles.customButton}>
                  <IoMdTrash color="#7f7f7f" size={24} className={styles.customSvgDelete} onClick={() => props.modalEliminar(props.idPost)} />
                </button>
              )}
            </div>
            {props.feed === "paciente" && (
              <div className="d-none d-md-block">
                <Badge colorHex={props.colorTerapia} nombreTerapia={props.nombreTerapia} />
              </div>
            )}
          </div>
          <div ref={onePostRef} style={{ paddingRight: "16px" }}>
            <div ref={postTextRef} className={expanded ? "" : `${draftStyles.paginatedText}`}>
              {props.rawContent ? (
                <Editor
                  blockStyleFn={() => `${draftStyles.customEditorStyles}`}
                  editorState={editorState}
                  onChange={(newState) => setEditorState(newState)}
                  readOnly
                  plugins={plugins}
                />
              ) : (
                <ReactQuill
                  readOnly
                  value={props.textoPost}
                  modules={{ toolbar: false }}
                  style={{ margin: "-12px -15px" }}
                  className="selectableText"
                />
              )}
            </div>
            {!expanded && (
              <a className="mr-auto" style={{ fontSize: "14px", cursor: "pointer" }} onClick={(e) => expandHandler(e)}>
                Ver más
              </a>
            )}
            {props.areLoadingFiles ? (
              <div style={{ display: "flex", marginTop: "24px" }}>
                <p>Cargando archivos... </p>
                <Spinner spinnerType="extrasmall" />
              </div>
            ) : (
              <>
                <ImagePost imagenes={props.imagenesPost || []} videos={props.videosPost || []} />
              </>
            )}
          </div>
          <PostInfo
            archivosPost={props.archivosPost}
            views={props.views}
            commentsLength={cantidadComentarios}
            cantidadReacciones={cantidadReacciones}
            reaccionesDadas={reaccionesDadas}
            setModalReactionsOpen={() => setModalReactionsOpen(true)}
          />

          {props.archivosPost.length > 0 && cantidadReacciones > 0 && (
            <ReaccionesDadas
              className="mr-auto"
              cantidadReacciones={cantidadReacciones}
              reacciones={reaccionesDadas}
              handleReactionModal={() => setModalReactionsOpen(true)}
            />
          )}

          <div className={styles.postActions}>
            <ReaccionesButton
              reaccionesPosibles={props.reaccionesPosibles}
              handleNuevaReaccion={handleReaccion}
              dioReaccion={dioReaccion}
              reaccionDada={reaccionDada}
              postReaccion={postReaccion}
              removeReaccion={removeReaccion}
              defaultReaction={props.defaultReaction}
              isLoading={spinnerReaction}
            />
          </div>
        </div>
      </div>
      <GroupedComments
        idPost={props.idPost}
        tieneComentarios={props.tieneComentarios}
        modalBorrarComentario={props.modalBorrarComentario}
        comentarioABorrar={props.comentarioABorrar}
        idUsuario={idUsuario}
        rolUsuario={rolUsuario}
        idPaciente={props.idPaciente || ""}
        idTerapia={props.idTerapia || ""}
        possibleMentions={props.possibleMentions}
        handleCantidadComentarios={handleCantidadComentarios}
        reaccionesPosibles={props.reaccionesPosibles}
        defaultReaction={props.defaultReaction}
      />

      {modalReactionsOpen && (
        <Modal title="Reacciones" reactions={reaccionesDadas} isOpen={modalReactionsOpen} closeModal={handleReactionsModal}>
          <ReactionsTab reactions={reaccionesDadas} />
        </Modal>
      )}
    </div>
  );
});

export default Post;
