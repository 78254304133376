import React from 'react'
import {Alert} from 'react-bootstrap'
import styles from './alertPropia.module.css'

const AlertPropia = (props) => {


    return (
        <div className={styles.alertContainer}>
            <Alert variant={props.variant} show={props.show}>{props.children}</Alert>
        </div>
    )
}

export default AlertPropia