import React from 'react'
import styles from './viewsList.module.css'
import ViewListItem from './viewsListItem'

const ViewersList = (props) => {

    return (
        <>
            {props.views.map((view, key) => (
                <ViewListItem key={key} view={view} />
            ))}
        </>
    )
}

export default ViewersList