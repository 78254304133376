import React from 'react'
import UserListItem from '../userListItem/userListItem'
import TimeAgo from 'react-timeago'
import spanishStrings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

const formatter = buildFormatter(spanishStrings)

const ViewListItem = (props) => {

    return (
        <div className="d-flex justify-content-between align-items-center mb-2">
            <UserListItem user={props.view.viewer}/>
            <TimeAgo style={{ fontSize: '13px', margin: '0' }} date={props.view.readAt} formatter={formatter} />
        </div>
    )
}

export default ViewListItem